export function convertResponseToValueLabelForYear(arrayToBeMapped) {
    // debugger
    if(arrayToBeMapped && arrayToBeMapped["codeValues"] !=undefined)
    {var mappedArray = arrayToBeMapped["codeValues"].map((item) => {
        return {
          value: parseInt(item.code_value_type),
          label: item.code_value_type
        };
      });
    return mappedArray
    }
      else{
          return []
      }
}

export function convertResponseToValueLabelForTitle(arrayToBeMapped) {
  
  if(arrayToBeMapped && arrayToBeMapped["codeValues"] !=undefined)
  {var mappedArray = arrayToBeMapped["codeValues"].map((item) => {
      return {
        value: parseInt(item.code_value_description),
        label: item.code_value_type
      };
    });
  return mappedArray
  }
    else{
        return []
    }
}