import {documentService} from './document.service';
import {usCodeService} from './us-code.service';
import {CFRService} from './cfr.service';

export class DownloadService {
    static titles = {
        package: 'Download DOCX',
        uscode: 'Download US Code',
        cfr: 'Download CFR'
    };

    static getFileName(type, data) {
        let fileName = null;

        if (type === 'package') {
            const {comparedPackageId, packageId} = data;

            fileName = comparedPackageId ? packageId + '-' + comparedPackageId + '.docx' : packageId + '.docx';
        } else if (type === 'uscode') {
            const {title, section, year} = data;

            fileName = `uscode-${title}-${section}-${year}.pdf`;
        } else {
            const {title, section, year, part} = data;

            if (section) {
                fileName = `CFR-${year}-title${title}-part${part}-section${section}.pdf`;
            } else {
                fileName = `CFR-${year}-title${title}-part${part}.pdf`;
            }
        }

        return fileName
    }

    static getTitle(type) {
        return DownloadService.titles[type];
    };

    static getFileRequest(type, data) {

        let request = null;

        if (type === 'package') {
            const {comparedPackageId, packageId, viewingSessionId} = data;

            request = comparedPackageId
                ? documentService.getAccusoftRedlineDocx(packageId, comparedPackageId, viewingSessionId)
                : documentService.getDocumentDocx(packageId);
        } else if (type === 'uscode') {
            const {title, year, section} = data;

            request = usCodeService.downloadUsCode(title, year, section);
        } else {
            const {title, year, section, part} = data;

            request = CFRService.downloadCFR(title, year, section, part);
        }

        return request;
    };
}
