import React, {Component} from 'react';
import {ContextMenu, MenuItem, ContextMenuTrigger} from 'react-contextmenu';

import './DocumentViewerCopy.css';
import PDFViewer from '../PdfViewer/PdfViewer';
import Select from 'react-select';
import {connectCFRSelectStyles} from '../../CFRSearchPage/select-config';
import {getVersionTitle} from '../../_helpers/documents';
import {getIssuedDate} from "../../_helpers/date";
import EmptyResults from '../EmptyResults/EmptyResults';
import PlaceholderShimmer from "../PlaceholderShimmer/PlaceholderShimmer";
import {cfgService} from '../../_services/config.service';
import {convertResponseToValueLabelForYear, convertResponseToValueLabelForTitle} from '../../_helpers/handleConfigResponse';
var Constant = require('../../_constants');
class DocumentViewerCopy extends Component {

    usCodesYears = [];

    constructor(props) {
        super(props);


        this.state = {
            searchUsCodeYear: null,
            // properties to hold data for us code search
            searchUsCodeTitle: null,
            searchUsCodeSection: '',
            // properties to hold data for connected us code search
            connectUsCodeTitle: null,
            connectUsCodeSection: null,
            // this property is needed to nullify section select when new us code title is selected
            connectedUsCodeSectionValueForSelect: null,
            // flags to control state of buttons (search cfr, search mapped cfr, .etc)
            shouldUsCodeSearchButtonBeDisabled: true,
            shouldUsCodeConnectButtonBeDisabled: true,
            usCodeSectionSelectDisabled: true,
            // this is us code sections that should be displayed when particular us code title is selected
            usCodeSectionsToDisplayInSelect: [],
            titleListUsCode : [],
            titleListCFR : [],
            yearListUsCode : [],
            yearListCFR : []
        };
    }
    async componentDidMount() {
        var CFRTitles = await cfgService.getTitles(Constant.CodeType_CFR_Title_String);
        this.setState({titleListCFR:(convertResponseToValueLabelForTitle(CFRTitles))})  

        var USCodeTitles = await cfgService.getTitles(Constant.CodeType_USCode_Title_String);
        this.setState({titleListUsCode:(convertResponseToValueLabelForTitle(USCodeTitles))})  
        
        var CFRYears = await cfgService.getYears(Constant.CodeType_USCode_Year_String);
        this.setState({yearListUsCode:(convertResponseToValueLabelForYear(CFRYears)).reverse()})  

        // var USCodeYears = await cfgService.getYears(Constant.CodeType_USCode_Year_String);
        // this.setState({yearListCFR:(convertResponseToValueLabelForYear(USCodeYears)).reverse()})  

    }
    // I have to nullify everything except mode when mode is changed
    nullifyState = () => {
        this.setState({
            searchUsCodeYear: null,
            searchUsCodeTitle: null,
            searchUsCodeSection: '',
            connectUsCodeTitle: null,
            connectUsCodeSection: null,
            connectedUsCodeSectionValueForSelect: null,
            shouldUsCodeSearchButtonBeDisabled: true,
            usCodeSectionSelectDisabled: true,
            shouldUsCodeConnectButtonBeDisabled: true
        });
    };

    isViewBillMode = () => {
        const {document} = this.props;

        return document.mode === 'view-bill';
    };

    isSearchUsCodeMode = () => {
        const {document} = this.props;

        return document.mode === 'search-us-code';
    };

    isConnectedUsCodeMode = () => {
        const {document} = this.props;

        return document.mode === 'connected-us-code';
    };

    isViewDocumentMode = () => {
        const {document} = this.props;

        return document.mode === 'view-document'
    };

    getCfrTitleLabelByTitleNumber = (titleNumber) => {
        debugger
        if (this.state.titleListCFR != undefined){
            if (this.state.titleListCFR != 0) {
            const cfrTitles = this.state.titleListCFR
    
            return cfrTitles.find(title => title.value === titleNumber).label;
    }}
    };

    getUsCodeTitleLabelByTitleNumber = (titleNumber) => {
        if (this.state.titleListUsCode != undefined){
            if (this.state.titleListUsCode != 0)
            {const usCodeTitles = this.state.titleListUsCode
    
            return usCodeTitles.find(title => title.value === titleNumber).label;}
    }
    };

    getTitle = (documentLink, titleNumber) => {
        return documentLink.includes('uscode') ? this.getShortTitle(this.getUsCodeTitleLabelByTitleNumber(titleNumber)) : this.getShortTitle(this.getCfrTitleLabelByTitleNumber(titleNumber));
    };

    onUsCodeSearch = () => {
        const {searchUsCodeTitle, searchUsCodeSection, searchUsCodeYear} = this.state;
        const {document: {id}} = this.props;

        let yearValue = searchUsCodeYear === 'Most recent' ? 2018 : searchUsCodeYear;

        this.props.onUsCodeSearch(id, yearValue, searchUsCodeTitle, searchUsCodeSection);
    };

    onUsCodeConnect = () => {
        const {connectUsCodeTitle, connectUsCodeSection} = this.state;
        const {document: {id}} = this.props;

        this.props.onUsCodeConnect(id, connectUsCodeTitle, connectUsCodeSection);
    };

    shouldUsCodeSearchButtonBeDisabled = () => {
        const {searchUsCodeTitle, searchUsCodeSection, searchUsCodeYear} = this.state;

        return searchUsCodeSection === '' || searchUsCodeTitle === null || searchUsCodeYear === null;
    };

    shouldUsCodeConnectButtonBeDisabled = () => {
        const {connectUsCodeTitle, connectUsCodeSection} = this.state;

        return connectUsCodeTitle === null || connectUsCodeSection === null;
    };

    shouldSelectUsCodeSectionBeDisabled = () => {
        const {connectUsCodeTitle} = this.state;

        return !connectUsCodeTitle;
    };

    onValueEntered = async (controlName, event) => {
        let value = null;
        let usCodeSectionsToDisplayInSelect = this.state.usCodeSectionsToDisplayInSelect;
        let connectUsCodeSection;
        let connectedUsCodeSectionValueForSelect;

        if (controlName === 'searchUsCodeTitle') {
            value = event.value;
        } else if(controlName === 'connectUsCodeTitle') {
            value = event.value;
            // only if we selected us code title number then it makes sense to get related us code sections
            usCodeSectionsToDisplayInSelect = this.geUsCodeSectionsBySelectedUsCodeTitle(value);
            // nullify section for newly selected title
            connectedUsCodeSectionValueForSelect = null;
            // we need it to nullify it when new connect us code title is selected I know that it is a bullshit, but I don't have time to implement it the right way
            connectUsCodeSection = null;
        } else if(controlName === 'connectUsCodeSection') {
            value = event.value;
            connectUsCodeSection = value;
            connectedUsCodeSectionValueForSelect = usCodeSectionsToDisplayInSelect.find(usCodeSection => usCodeSection.value === value);
        } else if(controlName === 'searchUsCodeYear') {
            value = event.value;
        } else {
            const sectionNumber = parseInt(event.target.value, 10);
            if (!Number.isNaN(sectionNumber) && sectionNumber >= 0) {
                value = sectionNumber
            }
        }

        await this.setState({
            [controlName]: value,
            usCodeSectionsToDisplayInSelect,
            connectedUsCodeSectionValueForSelect,
            connectUsCodeSection
        });

        const shouldUsCodeSearchButtonBeDisabled = this.shouldUsCodeSearchButtonBeDisabled();
        const shouldUsCodeConnectButtonBeDisabled = this.shouldUsCodeConnectButtonBeDisabled();
        const usCodeSectionSelectDisabled = this.shouldSelectUsCodeSectionBeDisabled();

        this.setState({
            shouldUsCodeSearchButtonBeDisabled,
            shouldUsCodeConnectButtonBeDisabled,
            usCodeSectionSelectDisabled
        });
    };

    getShortTitle = (title) => {
        let shortTitle;
            if(title != undefined)
        {if(title.length >= 50) {
            shortTitle = title.substr(0, 50) + '...';
        } else {
            shortTitle = title;
        }return shortTitle;}

        
    };

    // get related us code sections by given us code title
    geUsCodeSectionsBySelectedUsCodeTitle = (usCodeTitle) => {
        const {connectedUsCodeSections} = this.props;
        const target = connectedUsCodeSections.find(usCodeSection => usCodeSection.usCodeTitle === usCodeTitle);

        return target.usCodeSections;
    };

    isViewDocumentDataPresent = () => {
        const {document} = this.props;

        // to display document correctly all data should be present (section is optional, that is why I omit it, part is not required as well)
        // bill is optional as well
        return !Object
            .keys(document)
            .filter(key => !(key === 'section' || key === 'part' || key === 'bill' || key === 'isLoading'))
            .some(key => !document[key]);
    };

    changeMode = (mode) => {
        const {document} = this.props;

        this.nullifyState();

        this.props.onChangeMode(document.id, mode);
    };

    downloadDocument = (type) => {
        let data = null;

        if(type === 'package') {
            // if we know that type is package then we should go to bill property and grab package id
            data = { packageId: this.props.document.bill.packageId };
        } else {
            data = this.props.document;
        }

        this.props.onDownload(type, data);
    };

    onBillSearch = () => {
        const {document} = this.props;

        this.props.onBillSearch(document.id);
    };

    render() {
        const {document, usCodeTitles, connectedUsCodeTitles} = this.props;
        const {
            shouldUsCodeSearchButtonBeDisabled,
            shouldUsCodeConnectButtonBeDisabled,
            searchUsCodeSection,
            usCodeSectionSelectDisabled,
            usCodeSectionsToDisplayInSelect,
            connectedUsCodeSectionValueForSelect
        } = this.state;

        const bill = document && document.bill;

        return (
            <>
                {
                    this.isSearchUsCodeMode() && (
                        <div className="document-container">
                            <div className="document-description-container">
                                <div className="name-container">
                                    <span>{document.name}</span>
                                    <ContextMenuTrigger
                                        id={document.id.toString()}
                                        holdToDisplay={0}
                                    >
                                        <img alt="3 dots icon" src="/static/media/3-dots.svg"/>
                                    </ContextMenuTrigger>

                                    <ContextMenu id={document.id.toString()}>
                                        <MenuItem onClick={() => this.changeMode('search-us-code')}>
                                            US Code Search
                                        </MenuItem>
                                        <MenuItem onClick={() => this.changeMode('connected-us-code')}>
                                            Connected US Code
                                        </MenuItem>
                                        <MenuItem onClick={this.onBillSearch}>
                                            Bill Search
                                        </MenuItem>
                                        <MenuItem className="disabled">
                                            Download
                                        </MenuItem>
                                    </ContextMenu>
                                </div>
                            </div>
                            <div className="connected-cfr-search-container">
                                <div className="description-container">
                                    <span>US Code search</span><br/>
                                    <span>(Please select Title & Section to view)</span>
                                </div>
                                <div className="selects-container">
                                    <div className="year-select">
                                        <Select
                                            placeholder={'Year'}
                                            onChange={(event) => this.onValueEntered('searchUsCodeYear', event)}
                                            styles={connectCFRSelectStyles}
                                            options={this.state.yearListUsCode}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                        />
                                    </div>
                                    <div className="title-select">
                                        <Select
                                            placeholder={'Title Number'}
                                            onChange={(event) => this.onValueEntered('searchUsCodeTitle', event)}
                                            styles={connectCFRSelectStyles}
                                            options={usCodeTitles}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                        />
                                    </div>
                                    <div className="section-number">
                                        <input
                                            type="text"
                                            pattern="[0-9]*"
                                            value={searchUsCodeSection || ''}
                                            placeholder="Section number"
                                            onChange={(event) => this.onValueEntered('searchUsCodeSection', event)}
                                            autoComplete="off"
                                            className="text-field text-field-input"/>
                                    </div>
                                    <div>
                                        <button
                                            disabled={shouldUsCodeSearchButtonBeDisabled}
                                            className={`button button-blue w-100 ${shouldUsCodeSearchButtonBeDisabled ? 'not-allowed' : ' '}`}
                                            onClick={this.onUsCodeSearch}
                                        >View
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    this.isViewDocumentMode() && this.isViewDocumentDataPresent() && !document.isLoading && (
                        <div className="document-container">
                            <div className="document-description-container">
                                <div className="name-container">
                                    <span>{document.name}</span>
                                    <ContextMenuTrigger
                                        id={document.id.toString()}
                                        holdToDisplay={0}
                                    >
                                        <img alt="3 dots icon" src="/static/media/3-dots.svg"/>
                                    </ContextMenuTrigger>

                                    <ContextMenu id={document.id.toString()}>
                                        {
                                            document.id !== 1 && (
                                                <>
                                                    <MenuItem onClick={() => this.changeMode('search-us-code')}>
                                                        US Code Search
                                                    </MenuItem>
                                                    <MenuItem onClick={() => this.changeMode('connected-us-code')}>
                                                        Connected US Code
                                                    </MenuItem>
                                                    <MenuItem onClick={this.onBillSearch}>
                                                        Bill Search
                                                    </MenuItem>
                                                </>
                                            )
                                        }
                                        <MenuItem
                                            onClick={() => this.downloadDocument(document.id !== 1 ? 'uscode' : 'cfr')}>
                                            Download
                                        </MenuItem>
                                    </ContextMenu>
                                </div>
                                <div className="title-container">
                                    <span>{this.getTitle(document.documentLink, document.title)}</span>
                                    <div className="chips-container">
                                        <span className="chips">{document.year} Edition</span>
                                        {
                                            document.part && (
                                                <span className="chips">Part {document.part}</span>
                                            )
                                        }
                                        {
                                            document.section && (
                                                <span className="chips">Section {document.section}</span>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="pdf-viewer-container">
                                <PDFViewer file={document.documentLink}/>
                            </div>
                        </div>
                    )
                }
                {
                    this.isViewDocumentMode() && !this.isViewDocumentDataPresent() && !document.isLoading &&(
                        <div className="document-container">
                            <div className="document-description-container">
                                <div className="name-container">
                                    <span>{document.name}</span>
                                    <ContextMenuTrigger
                                        id={document.id.toString()}
                                        holdToDisplay={0}
                                    >
                                        <img alt="3 dots icon" src="/static/media/3-dots.svg"/>
                                    </ContextMenuTrigger>

                                    <ContextMenu id={document.id.toString()}>
                                        <MenuItem onClick={() => this.changeMode('search-us-code')}>
                                            US Code Search
                                        </MenuItem>
                                        <MenuItem onClick={() => this.changeMode('connected-us-code')}>
                                            Connected US Code
                                        </MenuItem>
                                        <MenuItem onClick={this.onBillSearch}>
                                            Bill Search
                                        </MenuItem>
                                        <MenuItem className="disabled">
                                            Download
                                        </MenuItem>
                                    </ContextMenu>
                                </div>
                            </div>
                            <EmptyResults text={'Markup Redline™ only provides document and information made available by the Government Publishing Office as updated daily. This document has not been published by the GPO for the year you searched.'}/>
                        </div>
                    )
                }
                {
                    this.isViewBillMode() && bill && (
                        <div className="document-container">
                            <div className="document-description-container">
                                <div className="name-container">
                                    <span>{document.name}</span>
                                    <ContextMenuTrigger
                                        id={document.id.toString()}
                                        holdToDisplay={0}
                                    >
                                        <img alt="3 dots icon" src="/static/media/3-dots.svg"/>
                                    </ContextMenuTrigger>

                                    <ContextMenu id={document.id.toString()}>
                                        {
                                            document.id !== 1 && (
                                                <>
                                                    <MenuItem onClick={() => this.changeMode('search-us-code')}>
                                                        US Code Search
                                                    </MenuItem>
                                                    <MenuItem onClick={() => this.changeMode('connected-us-code')}>
                                                        Connected US Code
                                                    </MenuItem>
                                                    <MenuItem onClick={this.onBillSearch}>
                                                        Bill Search
                                                    </MenuItem>
                                                </>
                                            )
                                        }
                                        <MenuItem onClick={() => this.downloadDocument('package')} >Download</MenuItem>
                                    </ContextMenu>
                                </div>
                                <div className="title-container">
                                    <span>{this.getShortTitle(bill.title)}</span>
                                    <div className="chips-container connected-cfr">
                                        <span className="chips">{bill.type}.{bill.number}</span>
                                        <span className="chips">{getVersionTitle(bill.version)}</span>
                                        <span className="chips">{getIssuedDate(bill.issuedAt)}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="pdf-viewer-container">
                                <PDFViewer file={bill.documentLink}/>
                            </div>
                        </div>
                    )
                }
                {
                    this.isViewDocumentMode() && document.isLoading && (
                        <div className="document-container">
                            <PlaceholderShimmer />
                            <div className="lds-dual-ring-container">
                                <div className="lds-dual-ring"></div>
                            </div>
                        </div>
                    )
                }
                {
                    this.isConnectedUsCodeMode() && (
                        <div className="document-container">
                            <div className="document-description-container">
                                <div className="name-container">
                                    <span>{document.name}</span>
                                    <ContextMenuTrigger
                                        id={document.id.toString()}
                                        holdToDisplay={0}
                                    >
                                        <img alt="3 dots icon" src="/static/media/3-dots.svg"/>
                                    </ContextMenuTrigger>

                                    <ContextMenu id={document.id.toString()}>
                                        <MenuItem onClick={() => this.changeMode('search-us-code')}>
                                            US Code Search
                                        </MenuItem>
                                        <MenuItem onClick={() => this.changeMode('connected-us-code')}>
                                            Connected US Code
                                        </MenuItem>
                                        <MenuItem onClick={this.onBillSearch}>
                                            Bill Search
                                        </MenuItem>
                                        <MenuItem className="disabled">
                                            Download
                                        </MenuItem>
                                    </ContextMenu>
                                </div>
                            </div>
                            <div className="connected-cfr-search-container">
                                <div className="description-container">
                                    <span>Connected US Codes</span><br/>
                                    <span>(Parallel Table is revised as of Jan 1, 2019)</span>
                                </div>
                                <div className="selects-container">
                                    <div className="title-select">
                                        <Select
                                            onChange={(event) => this.onValueEntered('connectUsCodeTitle', event)}
                                            placeholder={'Title Number'}
                                            options={connectedUsCodeTitles}
                                            styles={connectCFRSelectStyles}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                        />
                                    </div>
                                    <div className="part-select">
                                        <Select
                                            onChange={(event) => this.onValueEntered('connectUsCodeSection', event)}
                                            placeholder={'Part Number'}
                                            isDisabled={usCodeSectionSelectDisabled}
                                            options={usCodeSectionsToDisplayInSelect}
                                            value={connectedUsCodeSectionValueForSelect}
                                            styles={connectCFRSelectStyles}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <button
                                            disabled={shouldUsCodeConnectButtonBeDisabled}
                                            className={`button button-blue w-100 ${shouldUsCodeConnectButtonBeDisabled ? 'not-allowed' : ' '}`}
                                            onClick={this.onUsCodeConnect}
                                        >View
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </>
        );
    }
}

export default DocumentViewerCopy;
