import { authHeader, handleResponse } from "../_helpers";

export const documentService = {
  //used API
  getDocuments(keywords, page = 1) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${process.env.REACT_APP_API_URL}/documents?keywords=${keywords}&page=${page}`, requestOptions).then(handleResponse);
  },

  getDocument(packageId) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${process.env.REACT_APP_API_URL}/documents/${packageId}`, requestOptions).then(handleResponse);
  },
  
  getDocumentMetadata(packageId) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${process.env.REACT_APP_API_URL}/documents/${packageId}/metadata`, requestOptions).then(handleResponse);
  },


  getDocumentDocx(packageId) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${process.env.REACT_APP_API_URL}/documents/${packageId}/docx`, requestOptions);
  },
  
  getDocumentVersions(packageId) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${process.env.REACT_APP_API_URL}/documents/${packageId}/versions`, requestOptions).then(handleResponse);
  },
  getRedlineViewingSession(originalPackageId, revisedPackageId) {
    
    const data = {originalPackageId, revisedPackageId};
    const requestOptions = { method: 'POST', headers: { 'Content-Type': 'application/json', ...authHeader() }, body: JSON.stringify(data) };
    return fetch(`${process.env.REACT_APP_API_URL}/accusoft/init-redline-viewing-session`, requestOptions).then(handleResponse);
  },

  getAccusoftRedlineDocx(packageId, comparedPackageId, viewingSession) {
    console.log(viewingSession);
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${process.env.REACT_APP_API_URL}/accusoft/redline-viewing-sessions/${viewingSession}/get-docx?originalPackageId=${packageId}&revisedPackageId=${comparedPackageId}`, requestOptions);
  },



  //unused APIs
  getRedlineDocumentLink(packageId, comparedPackageId) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${process.env.REACT_APP_API_URL}/documents/${packageId}/differences/${comparedPackageId}`, requestOptions).then(handleResponse);
  },
  getDocumentLink(packageId) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${process.env.REACT_APP_API_URL}/documents/${packageId}/pdf/link`, requestOptions).then(handleResponse);
  },
  getRedlineDocx(packageId, comparedPackageId) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${process.env.REACT_APP_API_URL}/documents/${packageId}/redline/${comparedPackageId}`, requestOptions);
  }
};
