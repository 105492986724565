import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Modal from 'react-responsive-modal';

import './ProfilePage.css'
import { userService, authenticationService } from '../_services';
import Header from '../_components/Header/Header';

class ProfilePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isRunningInsideMicrosoftTeams: false,
      isDisabledButton: true,
      firstName: '',
      lastName: '',
      emailAddress: '',
      password: '',
      confirmPassword: '',
      // customers said to hide and stop using these fields
      // affiliation: '',
      // affiliationMemberName: '',
      profileErrorText: '',
      isChangePasswordDisplayed: false,
      modalOpen: false,
      userProfile: {},
      deleteEmailAddressConfirmation: '',
      isDeleteButtonDisabled: true
    };
  }

  async componentDidMount () {
    const isRunningInsideMicrosoftTeamsFromLocalStorage = localStorage.getItem('isRunningInsideMicrosoftTeams');
    const isRunningInsideMicrosoftTeams = isRunningInsideMicrosoftTeamsFromLocalStorage === 'true';

    this.setState({
        isRunningInsideMicrosoftTeams
    });

    const userProfile = await userService.getProfile();
    if (!userProfile.emailAddress) {
      this.props.history.push('/');
    }
    this.setState({
      userProfile: userProfile,
      firstName: userProfile.firstName,
      lastName: userProfile.lastName,
      emailAddress: userProfile.emailAddress
      // customers said to hide and stop using these fields
      // affiliation: userProfile.affiliation,
      // affiliationMemberName: userProfile.affiliationMemberName
    })
  }

  handleInputChange = async (event) => {
    this.setState({isDisabledButton: false});
    const target = event.target;
    const value = target.value;
    const name = target.name;
    await this.setState({ [name]: value });
    this.toggleSubmitButton();
  }

  // customers said to hide and stop using these fields
  // handleAffiliationChange = async (event) => {
  //   const target = event.target;
  //   const value = target.value;
  //   await this.setState({ affiliation: value });
  //   this.toggleSubmitButton();
  // }

  handleCheckboxChange = async (event) => {
    await this.setState({isTermOfServiceAgreed: !this.state.isTermOfServiceAgreed});
    this.toggleSubmitButton();
  }

  toggleSubmitButton = () => {
    if (!this.isAllFieldsFilled()) {
      !this.state.isDisabledButton && this.setState({isDisabledButton: true});
    } else {
      this.state.isDisabledButton && this.setState({isDisabledButton: false});
    }
  }

  isAllFieldsFilled = () => {
    // customers said to hide and stop using these fields
    // const { firstName, lastName, emailAddress, affiliationMemberName, affiliation} = this.state;
    const { firstName, lastName, emailAddress } = this.state;
    // return firstName && lastName && emailAddress && affiliationMemberName && affiliation;
    return firstName && lastName && emailAddress;
  }

  hidePasswordForm = () => {
    this.setState({isChangePasswordDisplayed: false, profileErrorText: ''});
  }

  showPasswordForm = () => {
    this.setState({isChangePasswordDisplayed: true , profileErrorText: ''});
  }

  handlePasswordSubmit = async (event) => {
    event.preventDefault();
    try {
      const passwordPattern = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,32}$/;
      const { password, confirmPassword } = this.state;

      if (password.length < 6 || password.length > 32) {
        throw new Error('Password must be between 6-32 characters long');
      }
      else if (!passwordPattern.test(password)){
        throw new Error('Password should contains a combination of uppercase and lowercase letter, special character and number are required');
    }
      else if (password !== confirmPassword) {
        throw new Error('Password and Confirm password must be identical');
      }

      this.setState({profileErrorText: ''});

      const data = {password };
      const response = await userService.updateUserPassword(data);
      if (response && response.result === 'success') {
        toast.info('Changes were saved successfully!');
        this.setState({ isChangePasswordDisplayed: false, isDisabledButton: true });
      }
    } catch (error) {
      this.setState({profileErrorText: error.message ? error.message : error});
      return null;
    }
  }

  handleSubmit = async (event) => {
    this.setState({isDisabledSignupButton: true});
    event.preventDefault();
    try {
      // customers said to hide and stop using these fields
      // const { firstName, lastName, emailAddress, affiliationMemberName, affiliation } = this.state;
      const namePattern = /^[a-zA-Z]+$/;
      const { firstName, lastName, emailAddress } = this.state;

      if (!namePattern.test(firstName)){
        throw new Error('No whitespace allowed in First Name')
      }
      if (firstName.length < 3 || firstName.length > 32) {
        throw new Error('First name must be 3-32 characters long');
      }
      if (!namePattern.test(lastName)){
        throw new Error('No whitespace allowed in Last Name')
      }
      else if (lastName.length < 3 || lastName.length > 32) {
        throw new Error('Last name must be 3-32 characters long');
      }
      else if (emailAddress.length < 3 || emailAddress.length > 256) {
        throw new Error('Invalid e-mail address');
      }
      // customers said to hide and stop using these fields
      // else if ((affiliationMemberName.length < 3 || affiliationMemberName.length > 64)) {
      //   throw new Error('Committee affiliation name must be between 3-64 characters long');
      // }

      this.setState({profileErrorText: ''});

      if (firstName !== this.state.userProfile.firstName || lastName !== this.state.userProfile.lastName) {
        const updateUser = authenticationService.updateAuthenticatedUser({firstName, lastName});
        console.log('Updated user in memory', updateUser);
      }

      // customers said to hide and stop using these fields
      // const data = {firstName, lastName, emailAddress, affiliationMemberName, affiliation };
      const data = {firstName, lastName, emailAddress };
      const response = await userService.updateUser(data);
      if (response && response.result === 'success') {
        toast.info('Changes were saved successfully!');
        this.setState({isDisabledButton: true});
      }
    } catch (error) {
      this.setState({profileErrorText: error.message ? error.message : error});
      return null;
    }
  }

  handleDeleteAccount = async () => {
    this.setState({isDeleteButtonDisabled: true});
    try {
      if (this.state.emailAddress === this.state.deleteEmailAddressConfirmation) {
        await userService.deleteUser();
        await this.setState({modalOpen: false});
        toast.info('Account was deleted successfully!');
        authenticationService.logout();
        this.props.history.push('/');
      } else {
        toast.warning('Please confirm proper email');
      }

    } catch (error) {
      console.log(error);
      toast.error('Invalid operation!');
      return null;
    }
  }

  onOpenModal = () => {
    this.setState({ modalOpen: true });
  };

  onCloseModal = () => {
    this.setState({ modalOpen: false });
  };

  handleDeleteAccountChange = async (event) => {
    const target = event.target;
    const value = target.value;
    await this.setState({ deleteEmailAddressConfirmation: value });
    if (value === this.state.emailAddress) {
      this.setState({isDeleteButtonDisabled: false});
    } else {
      !this.state.isDeleteButtonDisabled && this.setState({isDeleteButtonDisabled: true});
    }
  }


  onLogout = () => {
    authenticationService.logout();
    this.props.history.push('/');
  };

  render() {
    const {isRunningInsideMicrosoftTeams} = this.state;

    return (
      <>
          {!isRunningInsideMicrosoftTeams && <Header isLogoutDisplayed shouldLogoBeLight={false} />}
        <div className="registration-container">
          <div className="left-section"></div>
          <div className="registration-section profile-section">
            <div className="form-section">
              <div>
                <center>
                  <h3>{this.state.firstName} {this.state.lastName}</h3>
                  <div className="user-email">{this.state.emailAddress}</div>
                </center>
              </div>
              { !this.state.isChangePasswordDisplayed &&
                <form onSubmit={this.handleSubmit}>
                  <div className="form-container">
                    { this.state.profileErrorText &&
                      <div className="error-text registration-error">{this.state.profileErrorText}</div>
                    }
                    <div className="username-section">
                      <div className={"form-input mr-1" + (this.state.firstnameError ? ' error' : '')} >
                        <label>
                          <input  type="text" name="firstName" autoComplete="off" onChange={this.handleInputChange} value={this.state.firstName} required />
                          <span className="placeholder">First Name</span>
                        </label>
                      </div>
                      <div className={"form-input ml-1" + (this.state.lastnameError ? ' error' : '')} >
                        <label>
                          <input  type="text" name="lastName" autoComplete="off" onChange={this.handleInputChange} value={this.state.lastName} required />
                          <span className="placeholder">Last Name</span>
                        </label>
                      </div>
                    </div>
                    <div className="form-input" >
                      <label>
                        <input  type="email" name="emailAddress" autoComplete="off" onChange={this.handleInputChange} value={this.state.emailAddress} required />
                        <span className="placeholder">Email address</span>
                      </label>
                    </div>
                    {/*<div className="affiliation-field">*/}
                      {/*<div className="label">Party Affiliation</div>*/}
                      {/*<div className="radio-options">*/}
                        {/*<div className="radio-container option">*/}
                          {/*<label className="checkbox-switch">*/}
                            {/*<input type="radio" name="affiliation" onChange={this.handleAffiliationChange} value="democrat" checked={this.state.affiliation === 'democrat'} />*/}
                            {/*<div className="checkbox-slider"></div>*/}
                          {/*</label>*/}
                          {/*<div className="checkbox-label">Democrat</div>*/}
                        {/*</div>*/}
                        {/*<div className="radio-container option">*/}
                          {/*<label className="checkbox-switch">*/}
                            {/*<input type="radio" name="affiliation" onChange={this.handleAffiliationChange} value="republican" checked={this.state.affiliation === 'republican'} />*/}
                            {/*<div className="checkbox-slider"></div>*/}
                          {/*</label>*/}
                          {/*<div className="checkbox-label">Republican</div>*/}
                        {/*</div>*/}
                      {/*</div>*/}
                    {/*</div>*/}
                    {/*<div className="form-input" >*/}
                      {/*<label>*/}
                        {/*<input  type="text" name="affiliationMemberName" autoComplete="off" onChange={this.handleInputChange} value={this.state.affiliationMemberName} required />*/}
                        {/*<span className="placeholder">Member or Committee Affiliation</span>*/}
                      {/*</label>*/}
                    {/*</div>*/}
                    <div className="form-actions">
                      <button type="submit" className={"button blue-gradient w-100" + (this.state.isDisabledButton ? ' disabled' : '')} disabled={ this.state.isDisabledButton ? 'disabled' : ''}>Save</button>
                    </div>
                      {
                          isRunningInsideMicrosoftTeams && (
                              <div>
                                  <button
                                      className="button blue-gradient w-100"
                                      style={{marginTop: '25px'}}
                                      onClick={this.onLogout}>Logout</button>
                              </div>
                          )
                      }
                  </div>
                </form>
              }
              { this.state.isChangePasswordDisplayed &&
                <form onSubmit={this.handlePasswordSubmit}>
                  <div className="form-container">
                    { this.state.profileErrorText &&
                      <div className="error-text registration-error">{this.state.profileErrorText}</div>
                    }
                    <div className="form-input">
                      <label>
                        <input  type="password" name="password" autoComplete="off" onChange={this.handleInputChange} required />
                        <span className="placeholder">Password</span>
                      </label>
                    </div>
                    <div className="form-input">
                      <label>
                        <input  type="password" name="confirmPassword" autoComplete="off" onChange={this.handleInputChange} required />
                        <span className="placeholder">Confirm Password</span>
                      </label>
                    </div>
                    <div className="action-buttons">
                      <div style={ {width: "50%"} }>
                        <button type="button" className="button button-transparent w-100" style={{marginRight: '5px'}} onClick={this.hidePasswordForm}>Cancel</button>
                      </div>
                      <div style={ {width: "50%"} }>
                        <button type="submit" className="button button-blue blue-gradient w-100" style={{marginLeft: '5px'}}>Save</button>
                      </div>
                    </div>
                  </div>
                </form>
              }
              { !this.state.isChangePasswordDisplayed &&
                <>
                  <div className="action-buttons">
                    <div style={ {width: "50%"} }>
                      <button type="button" className="button button-transparent w-100" style={{marginRight: '5px'}} onClick={this.showPasswordForm}>Change Password</button>
                    </div>
                    <div style={ {width: "50%"} }>
                      <button type="button" className="button button-red w-100" style={{marginLeft: '5px'}} onClick={this.onOpenModal}>Delete Account</button>
                    </div>
                  </div>
                </>
              }
            </div>
          </div>
          <div className="right-section"></div>
        </div>
        <Modal
          open={this.state.modalOpen}
          onClose={this.onCloseModal}
          center
          showCloseIcon={false}
          classNames={{
            overlay: 'custom-modal-overlay',
            modal: 'custom-modal',
          }} >
            <div className="delete-modal-content">
              <div className="modal-title">Are you sure you want to </div>
              <div className="modal-title">delete your account?</div>
              <p className="remove-account-note-text">This action will erase any associated data with your account and cannot be reversed. Enter your email address to confirm this action.</p>
              <div className="form-input" style={{marginTop: '48px', marginBottom: '48px'}}>
                <label>
                  <input  type="email" name="deleteEmailAddressConfirmation" autoComplete="off" onChange={this.handleDeleteAccountChange} value={this.state.deleteEmailAddressConfirmation} required />
                  <span className="placeholder">Your email address</span>
                </label>
              </div>
              <div className="action-buttons">
                <div style={ {width: "50%"} }>
                  <button type="button" className="button button-transparent w-100" style={{marginRight: '5px'}} onClick={this.onCloseModal}>Cancel</button>
                </div>
                <div style={ {width: "50%"} }>
                  <button type="button" className={"button button-red w-100" + (this.state.isDeleteButtonDisabled ? ' disabled' : '')} disabled={ this.state.isDeleteButtonDisabled ? 'disabled' : ''} style={{marginLeft: '5px'}} onClick={this.handleDeleteAccount}>Delete Account</button>
                </div>
              </div>
            </div>
        </Modal>
      </>
    );
  }
}

export default ProfilePage;
