import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import './HelpPage.css';
import {isEmailValid} from '../_helpers';
import Header from '../_components/Header/Header';
import Footer from '../_components/Footer/Footer';
import Logo from '../_components/Logo/Logo';
import {helpService} from '../_services';

class HelpPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // flag to handle spinner
            isLoading: false,
            // flag to capture state
            type: 'Feedback',
            emailAddress: '',
            name: '',
            message: '',
            subject: '',
            // flag to control state of submit button
            shouldSubmitButtonBeDisabled: true,
            // flag to control view
            isEmailSent: false,
            // flag to control error view
            isError: false
        }
    }

    isLoading = () => {
        const {isLoading} = this.state;

        return isLoading;
    };

    isError = () => {
        const {isError} = this.state;

        return isError;
    };

    isEmailValid = () => {
        const {emailAddress} = this.state;

        return isEmailValid(emailAddress);
    };

    isMessageValid = () => {
        const {message} = this.state;

        return message !== '';
    };

    isNameValid = () => {
        const {name} = this.state;

        return name !== '';
    };

    isSubjectValid = () => {
        const {subject} = this.state;

        return subject !== '';
    };

    isEmailSent = () => {
        const {isEmailSent} = this.state;

        return isEmailSent;
    };

    handleChange = async (event) => {
        const controlName = event.target.name;
        const value = event.target.value;

        await this.setState({
            [controlName]: value
        });

        const shouldSubmitButtonBeDisabled = this.shouldDisableSubmitButton() || !this.isEmailValid();

        this.setState({
            shouldSubmitButtonBeDisabled
        });
    };

    shouldDisableSubmitButton = () => {
        const {emailAddress, name, message, subject} = this.state;

        return !emailAddress || !name || !message || !subject;
    };

    onSubmit = async () => {
        this.setState({
            isLoading: true
        });

        const {emailAddress, name, message, subject, type} = this.state;
        try {
            await helpService.requestHelp(emailAddress, type, name, subject, message);

            this.setState({
                isLoading: false,
                isEmailSent: true
            });
        } catch (err) {
            console.error(err);

            this.setState({
                isLoading: false,
                isError: true
            });
        }
    };

    onCancel = () => {
        this.props.history.push('/');
    };

    render() {
        const {
            emailAddress,
            name,
            message,
            subject,
            shouldSubmitButtonBeDisabled
        } = this.state;

        return (
            <div>
                <Header isSectionsButtonsHidden/>
                <div className="help-form-container">
                    <div className="help-form-header">
                        <Link to="/">
                            <div className="logo-wrapper">
                                <Logo/>
                            </div>
                        </Link>
                    </div>
                    {
                        this.isError() && (
                            <div className="error-container">
                                <p>Error has occurred, please, try again later.</p>
                            </div>
                        )
                    }
                    {
                        this.isEmailSent() && (
                            <div className="email-sent-info">
                                <p>Your support inquiry was submitted and a confirmation email has been sent
                                    to <span>{emailAddress}</span>.</p>
                                <p>A member from Markup Redline™ will reach out to you as soon as possible.</p>
                            </div>
                        )
                    }
                    {
                        !this.isEmailSent() && this.isLoading() && (
                            <div className="help-loader-container">
                                <div className="lds-dual-ring-container">
                                    <div className="lds-dual-ring"></div>
                                </div>
                            </div>
                        )
                    }
                    {
                        !this.isEmailSent() && !this.isLoading() && (
                            <div className="help-form">
                                <div className="type-of-support">
                                    <div className="label">Type of Support</div>
                                    <div className="radio-options">
                                        <div className="radio-container option">
                                            <label className="checkbox-switch">
                                                <input type="radio" name="type"
                                                       checked={true}
                                                       onChange={this.handleChange} value="Feedback"/>
                                                <div className="checkbox-slider"></div>
                                            </label>
                                            <div className="checkbox-label">Feedback</div>
                                        </div>
                                        <div className="radio-container option">
                                            <label className="checkbox-switch">
                                                <input type="radio" name="type"
                                                       onChange={this.handleChange} value="Technical"/>
                                                <div className="checkbox-slider"></div>
                                            </label>
                                            <div className="checkbox-label">Technical</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`form-input ${!this.isNameValid() ? 'error' : ''}`}>
                                    <label>
                                        <input
                                            type="text"
                                            name="name"
                                            onChange={this.handleChange}
                                            value={name}
                                            required
                                        />
                                        <span className="placeholder">Name</span>
                                    </label>
                                </div>
                                <div className={`form-input ${!this.isEmailValid() ? 'error' : ''}`}>
                                    <label>
                                        <input
                                            type="email"
                                            name="emailAddress"
                                            onChange={this.handleChange}
                                            value={emailAddress}
                                            required
                                        />
                                        <span className="placeholder">Email address</span>
                                    </label>
                                </div>
                                <div className={`form-input ${!this.isSubjectValid() ? 'error' : ''}`}>
                                    <label>
                                        <input
                                            type="text"
                                            name="subject"
                                            onChange={this.handleChange}
                                            value={subject}
                                            required
                                        />
                                        <span className="placeholder">Subject</span>
                                    </label>
                                </div>
                                <div className={`form-input ${!this.isMessageValid() ? 'error' : ''}`}>
                                    <label>
                                            <textarea
                                                name="message"
                                                onChange={this.handleChange}
                                                value={message}
                                                required
                                            />
                                        <span className="placeholder">Message</span>
                                    </label>
                                </div>
                                <div className="help-form-footer">
                                    <button
                                        className={`button blue-gradient ${shouldSubmitButtonBeDisabled ? 'disabled' : ''}`}
                                        disabled={shouldSubmitButtonBeDisabled}
                                        onClick={this.onSubmit}
                                    >Submit
                                    </button>
                                    <button
                                        className="button cancel-button"
                                        onClick={this.onCancel}
                                    >Cancel
                                    </button>
                                </div>
                            </div>
                        )
                    }
                </div>
                <Footer/>
            </div>
        );
    }

}

export default HelpPage;
