import React, {Component} from 'react';

class Logo extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {shouldLogoBeLight} = this.props;
        const logoPath = `/static/media/markup_logo_${shouldLogoBeLight ? 'white' : 'black'}.svg`;

        return (
            <img
                style={{width: '100%', height: '100%'}}
                src={logoPath}
            />
        );
    }
}

export default Logo;
