import React from 'react';
import {Route} from 'react-router-dom';
import {withRouter} from 'react-router';
import {PrivateRoute} from './_components/private-route';
import './App.css';
import * as microsoftTeams from "@microsoft/teams-js";

import HelpPage from './HelpPage/HelpPage';
import ReportsPage from './ReportsPage/ReportsPage';
import ResetPasswordPage from './ResetPasswordPage/ResetPasswordPage';
import CFRViewPage from './CFRViewPage/CFRViewPage'
import CFRSearchPage from './CFRSearchPage/CFRSearchPage';
import USCodeViewPage from './USCodeViewPage/USCodeViewPage';
import USCodePage from './USCodePage/USCodePage';
import HomePage from './HomePage/HomePage';
import LoginPage from './LoginPage/LoginPage';
import RegistrationPage from './RegistrationPage/RegistrationPage';
import AboutPage from './AboutPage/AboutPage';
import InvitationsPage from './InvitationsPage/InvitationsPage';
import SearchPage from './SearchPage/SearchPage';
import ViewerPage from './ViewerPage/ViewerPage';
import SideBySidePage from './SideBySidePage/SideBySidePage';
import RedlinePage from './RedlinePage/RedlinePage';
import TermsPage from './TermsPage/TermsPage';
import PrivacyPolicyPage from './PrivacyPolicyPage/PrivacyPolicyPage';
import CookiePolicyPage from './CookiePolicyPage/CookiePolicyPage';
import {authenticationService, userService, trackingsService} from './_services';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProfilePage from './ProfilePage/ProfilePage';
import LinkedInRedirectPage from './LinkedInRedirectPage/LinkedInRedirectPage';
import { Worker } from '@react-pdf-viewer/core';
require('dotenv').config({ path: '/.env' });

class App extends React.Component {

    unlisten;

    constructor(props) {
        super(props);

        this.state = {
            isRunningInsideMicrosoftTeams: false
        };
    }

    componentWillMount() {
        microsoftTeams.initialize(async () => {
            await this.setState({
                isRunningInsideMicrosoftTeams: true
            });

            localStorage.setItem('isRunningInsideMicrosoftTeams', this.state.isRunningInsideMicrosoftTeams.toString());
        });

        this.unlisten = this.props.history.listen(async (location, action) => {
            try {
                const userJson = localStorage.getItem('currentUser');

                // we track redirects only for auth users
                if (userJson) {
                    const parsedUser = JSON.parse(userJson);
                    const isRunningInsideMicrosoftTeamsFromLocalStorage = localStorage.getItem('isRunningInsideMicrosoftTeams');
                    const isRunningInsideMicrosoftTeams = isRunningInsideMicrosoftTeamsFromLocalStorage === 'true';
                    const route = location.pathname;
                    const actionDate = trackingsService.getActionDateStringWithTimezone();

                    await trackingsService.trackRedirectEvent(isRunningInsideMicrosoftTeams, parsedUser.emailAddress, route, actionDate);
                }
            } catch (err) {
                console.error(`Tracking error - `, err);
            }
        });
    }

    componentDidMount() {
        authenticationService.currentUser.subscribe(user => {
            if (user) {
                userService.getProfile();
            }
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    render() {
        return (
            <>
                <ToastContainer position="top-center" draggable={false}/>
                <div className="main-container">
                    <Route exact path="/" component={HomePage}/>
                    <Route path="/login" component={LoginPage}/>
                    <Route path="/about-us" component={AboutPage}/>
                    <Route path="/registration" component={RegistrationPage}/>
                    <Route path="/invitations" component={InvitationsPage}/>
                    <Route path="/terms-of-service" component={TermsPage}/>
                    <Route path="/privacy-policy" component={PrivacyPolicyPage}/>
                    <Route path="/cookie-policy" component={CookiePolicyPage}/>
                    <Route path="/linkedin-redirect" component={LinkedInRedirectPage}/>
                    <Route path="/reset-password" component={ResetPasswordPage}/>
                    <Route path="/help" component={HelpPage}/>
                    <PrivateRoute exact path="/search" component={SearchPage}/>
                    <PrivateRoute exact path="/us-code-search" component={USCodePage}/>
                    <PrivateRoute exact path="/cfr-search-page" component={CFRSearchPage}/>
                    <PrivateRoute exact path="/cfr-view-page" component={CFRViewPage}/>
                    <PrivateRoute exact path="/us-code-view" component={USCodeViewPage}/>
                    <PrivateRoute exact path="/user/profile" component={ProfilePage}/>
                    <PrivateRoute exact path="/view/:packageId/side-by-side" component={SideBySidePage}/>
                    <PrivateRoute exact path="/view/:packageId/redline/:comparedPackageId" component={RedlinePage}/>
                    <PrivateRoute exact path="/view/:packageId/redline" component={RedlinePage}/>
                    <PrivateRoute exact path="/view/:packageId/view" component={ViewerPage}/>
                    <PrivateRoute exact path="/reports" component={ReportsPage}/>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js"> </Worker>
                </div>
            </>
        );
    }
}

export default withRouter(App);
