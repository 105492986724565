import React, {Component} from 'react';

import {queryToObject} from '../_helpers/linkedin';
import PlaceholderShimmer from '../_components/PlaceholderShimmer/PlaceholderShimmer';

const {REACT_APP_LINKEDIN_STATE} = process.env;

// this component is used purely for fetching code from url and passing it back to the component that invoked sign in with linkedin
class LinkedInRedirectPage extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (window.location.search) {
            const params = queryToObject(window.location.search);
            if (params.state === REACT_APP_LINKEDIN_STATE && window.opener) {
                window.opener.postMessage(params, window.origin)
            }
        }
    }

    render() {
        return (
            <div>
                <PlaceholderShimmer />
                <div className="lds-dual-ring-container">
                    <div className="lds-dual-ring"></div>
                </div>
            </div>
        )
    }
}

export default LinkedInRedirectPage;
