import React, {Component} from 'react';

import './EmptyResults.css';

class EmptyResults extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {text} = this.props;

        return (
            <div className="empty-results-section">
                <div className="no-data-image">
                    <img alt="No data icon" src="/static/media/file.svg" className="file"></img>
                </div>
                <div className="no-data-text">
                    {text}
                </div>
            </div>
        );
    }
}

export default EmptyResults;
