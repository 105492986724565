import {handleResponse} from '../_helpers';

export const helpService = {

    requestHelp(emailAddress, type, name, subject, message) {
        const body = {
            emailAddress,
            type,
            name,
            subject,
            message
        };

        const requestOptions = {
            method: 'POST',
            headers: Object.assign({'Content-Type': 'application/json'}),
            body: JSON.stringify(body)
        };

        return fetch(`${process.env.REACT_APP_API_URL}/request-help`, requestOptions).then(handleResponse);
    }
};
