import React, {Component} from 'react';
import Select from 'react-select';

import './CFRSearchPage.css';
import {CFRService} from '../_services/cfr.service';
import {mappingsService} from '../_services/mappings.service';
import Header from '../_components/Header/Header';
import PlaceholderShimmer from '../_components/PlaceholderShimmer/PlaceholderShimmer';
import EmptyResults from '../_components/EmptyResults/EmptyResults';
import Footer from '../_components/Footer/Footer';
import {connectCFRSelectStyles, searchContainerSelectStyles} from './select-config';
import queryString from "query-string";
import {cfgService} from '../_services/config.service';
import {convertResponseToValueLabelForYear, convertResponseToValueLabelForTitle} from '../_helpers/handleConfigResponse';
var Constant = require('../_constants');
// TODO: if gonna have third similar component I should think of HOC here to avoid code duplication
class CFRSearchPage extends Component {
    years = [];
    titles = [];

    constructor(props) {
        super(props);
       
        this.state = {
            isRunningInsideMicrosoftTeams: false,
            title: null,
            year: null,
            yearList: [],
            titleList: [],
            titlesListUSCode:[],
            section: null,
            part: null,
            isFetching: false,
            searchButtonDisabled: true,
            cfrData: null,
            usCodeTitles: [],
            usCodeSections: [],
            // this is us code sections that should be displayed when particular us code title is selected
            usCodeSectionsToDisplayInSelect: [],
            // props to hold data about selected connected us code
            connectedUSCodeTitleNumber: null,
            connectedUSCodeSectionNumber: null,
            // this property is needed to nullify section select when new us code title is selected
            connectedUsCodeSectionValueForSelect: null,
            // flags to manage button disabled/enabled states
            connectButtonDisabled: true,
            usCodeSectionSelectDisabled: true
        };
    }

    async componentDidMount() {
        const isRunningInsideMicrosoftTeamsFromLocalStorage = localStorage.getItem('isRunningInsideMicrosoftTeams');
        const isRunningInsideMicrosoftTeams = isRunningInsideMicrosoftTeamsFromLocalStorage === 'true';

        const values = queryString.parse(this.props.location.search);
        var yearList = await cfgService.getYears(Constant.CodeType_CFR_Year_String);
        var titlesList = await cfgService.getTitles(Constant.CodeType_CFR_Title_String);
        this.setState({yearList:(convertResponseToValueLabelForYear(yearList)).reverse()})
        this.setState({titleList:(convertResponseToValueLabelForTitle(titlesList))})  

        //this API Call is just to send data to mappingsService.getuscodeTitles and not used anywhere else
        var titlesListUSCode = await cfgService.getTitles(Constant.CodeType_USCode_Title_String);
        this.setState({titlesListUSCode:(convertResponseToValueLabelForTitle(titlesListUSCode))}) 

        
        await this.setState({
            isRunningInsideMicrosoftTeams,
            year: values.cfrYear ? +values.cfrYear : this.state.yearList[0].value,
            title: values.cfrTitle ? +values.cfrTitle : this.state.titleList[0].value,
            part: values.cfrPart ? +values.cfrPart : null,
            section: values.usCodeSection ? values.usCodeSection : null
        });

        const searchButtonDisabled = this.shouldDisableSearchButton();

        this.setState({
            searchButtonDisabled
        });
    }

    onValueEntered = async (controlName, event) => {
        let value = null;
        // set default value for usCodeSectionsToDisplayInSelect (it can be either current one, or if we selected connectedUSCodeTitleNumber then a new one)
        let usCodeSectionsToDisplayInSelect = this.state.usCodeSectionsToDisplayInSelect;
        let connectedUSCodeSectionNumber;
        let connectedUsCodeSectionValueForSelect;

        if (controlName === 'section') {
            // section should be string
            value = event.target.value;
        } else if (controlName === 'part') {
            // part should be string
            value = event.target.value;
        } else if (controlName === 'year') {
            value = event.value;
        } else if (controlName === 'title') {
            value = event.value;
        } else if (controlName === 'connectedUSCodeTitleNumber') {
            value = event.value;
            // only if we selected us code title number then it makes sense to get related us code sections
            usCodeSectionsToDisplayInSelect = this.geUsCodeSectionsBySelectedUsCodeTitle(value);
            // nullify part for newly selected title
            connectedUsCodeSectionValueForSelect = null;
            // we need it to nullify it when new connect us code title is selected I know that it is a bullshit, but I don't have time to implement it the right way
            connectedUSCodeSectionNumber = null;
        } else if (controlName === 'connectedUSCodeSectionNumber') {
            value = event.value;
            connectedUSCodeSectionNumber = value;
            connectedUsCodeSectionValueForSelect = usCodeSectionsToDisplayInSelect.find(usCodeSection => usCodeSection.value === value);
        }

        // we need always to to it before shouldDisableSearchButton, shouldDisableConnectButton, shouldSelectUsCodeBeDisabled
        await this.setState({
            [controlName]: value,
            usCodeSectionsToDisplayInSelect,
            connectedUsCodeSectionValueForSelect,
            connectedUSCodeSectionNumber
        });

        const searchButtonDisabled = this.shouldDisableSearchButton();
        const connectButtonDisabled = this.shouldDisableConnectButton();
        const usCodeSectionSelectDisabled = this.shouldSelectUsCodeBeDisabled();

        this.setState({
            searchButtonDisabled,
            connectButtonDisabled,
            usCodeSectionSelectDisabled
        });
    };

    shouldDisableConnectButton = () => {
        const {connectedUSCodeTitleNumber, connectedUSCodeSectionNumber} = this.state;

        return !connectedUSCodeTitleNumber || !connectedUSCodeSectionNumber;
    };

    goToView = () => {
        // TODO: section is not required?
        const {title, section, year, part} = this.state;

        this.props.history.push(`/cfr-view-page?title=${title}&section=${section}&year=${year}&part=${part}&isView=true`);
    };

    shouldSelectUsCodeBeDisabled = () => {
        const {connectedUSCodeTitleNumber} = this.state;

        return !connectedUSCodeTitleNumber;
    };

    search = async () => {
        try {
            const {year, section, title, part} = this.state;
            let usCodeTitles = [];
            let usCodeSections = [];

            this.setState({
                isFetching: true
            });

            const cfrData = await CFRService.getCFR(title, year, section, part);

            if (cfrData.isPresent) {
                const response = await mappingsService.getUsCodeMappings(title, part);
                usCodeTitles = this.getUsCodeTitles(response.usCodeTitles);
                usCodeSections = this.getUsCodeSections(response.groupedUsCodeSections);
            }

            this.setState({
                isFetching: false,
                usCodeTitles,
                usCodeSections,
                cfrData: {...cfrData, title, year, section, part}
            });
        } catch (err) {
            console.error(err);

            this.setState({
                isFetching: false
            });
        }
    };

    // get related us code sections by given us code title
    geUsCodeSectionsBySelectedUsCodeTitle = (usCodeTitle) => {
        const {usCodeSections} = this.state;
        const target = usCodeSections.find(usCodeSection => usCodeSection.usCodeTitle === usCodeTitle);

        return target.usCodeSections;
    };

    isUsCodeMappingPresent = () => {
        const {usCodeTitles, usCodeSections} = this.state;

        return usCodeTitles.length && usCodeSections.length;
    };

    getUsCodeSections = (usCodeSections = []) => {
        return mappingsService.getUsCodeSections(usCodeSections);
    };

    getUsCodeTitles = (usCodeTitles = []) => {
        return mappingsService.getUsCodeTitles(usCodeTitles, this.state.titlesListUSCode);
    };

    shouldDisableSearchButton = () => {
        const {title, year, part} = this.state;

        return !(title && year && part);
    };

    getSelectedTitleLabel = (titleValue) => {
        const target = this.state.titleList.find(title => title.value === titleValue);

        return target ? target.label : 'Default label';

    };

    goToConnect = () => {
        // section is purely for display purposes, it's not used in mappings table
        const {year, section, title, part, connectedUSCodeTitleNumber, connectedUSCodeSectionNumber} = this.state;
        const connectedUSCodeYear = 2018;
        let url = null;

        if (section) {
            url = `/cfr-view-page?isConnect=true&cfrTitle=${title}&cfrSection=${section}&cfrYear=${year}&cfrPart=${part}&connectedUSCodeTitleNumber=${connectedUSCodeTitleNumber}&connectedUSCodeSectionNumber=${connectedUSCodeSectionNumber}&connectedUSCodeYear=${connectedUSCodeYear}`;
        } else {
            url = `/cfr-view-page?isConnect=true&cfrTitle=${title}&cfrYear=${year}&cfrPart=${part}&connectedUSCodeTitleNumber=${connectedUSCodeTitleNumber}&connectedUSCodeSectionNumber=${connectedUSCodeSectionNumber}&connectedUSCodeYear=${connectedUSCodeYear}`;
        }

        this.props.history.push(url);
    };

    render() {
        const {
            isFetching,
            cfrData,
            searchButtonDisabled,
            year,
            title,
            part,
            section,
            usCodeTitles,
            connectButtonDisabled,
            usCodeSectionSelectDisabled,
            usCodeSectionsToDisplayInSelect,
            connectedUsCodeSectionValueForSelect,
            isRunningInsideMicrosoftTeams
        } = this.state;

        const currentRoute = this.props.location.pathname;

        return (
            <div>
                {!isRunningInsideMicrosoftTeams && <Header currentRoute={currentRoute} shouldLogoBeLight={false}/>}
                <div className="search-section blue-gradient">
                    <div className="year-select">
                        <Select
                            name="year"
                            menuPortalTarget={document.body}
                            onChange={(event) => this.onValueEntered('year', event)}
                            options={this.state.yearList}
                            value={this.state.yearList.find(currentYear => currentYear.value === year)}
                            placeholder={'Year'}
                            defaultValue={this.state.yearList[0]}
                            styles={searchContainerSelectStyles}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                        />
                    </div>
                    <div className="title-select">
                        <Select
                            menuPortalTarget={document.body}
                            onChange={(event) => this.onValueEntered('title', event)}
                            options={this.state.titleList}
                            value={this.state.titleList.find(currentTitle => currentTitle.value === title)}
                            placeholder={'Title Number'}
                            defaultValue={this.state.titleList[0]}
                            styles={searchContainerSelectStyles}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                        />
                    </div>
                    <div className="search-input section">
                        <input
                            type="text"
                            value={part || ''}
                            placeholder="Part number"
                            autoComplete="off"
                            onChange={(event) => this.onValueEntered('part', event)}
                            className="text-field text-field-input"/>
                    </div>
                    <div className="search-input section">
                        <input
                            type="text"
                            value={section || ''}
                            placeholder="Section number"
                            autoComplete="off"
                            onChange={(event) => this.onValueEntered('section', event)}
                            className="text-field text-field-input"/>
                    </div>
                    <div className="search-button">
                        <button className={`button ${searchButtonDisabled ? 'not-allowed' : ''}`} onClick={this.search}
                                disabled={searchButtonDisabled}>
                            <span className="material-icons">search</span>Search
                        </button>
                    </div>
                </div>
                <div>
                    {
                        isFetching && (
                            <div>
                                <PlaceholderShimmer/>
                                <div className="lds-dual-ring-container">
                                    <div className="lds-dual-ring"></div>
                                </div>
                            </div>
                        )
                    }
                    {
                        (!isFetching && cfrData && cfrData.isPresent) &&
                        <>
                            <div className="us-code-container">
                                <div className="description-container">
                                    <div>
                                    <span
                                        className="description">Code of Federal Regulations, {this.getSelectedTitleLabel(cfrData.title)}</span>
                                    </div>
                                    <div>
                                        <span className="chips">{cfrData.year} Edition</span>
                                        <span className="chips">Part {cfrData.part}</span>
                                        {cfrData.section && <span className="chips">Section {cfrData.section}</span>}
                                    </div>
                                </div>
                                <div className="button-container">
                                    <button className="button button-blue view-button" onClick={this.goToView}>View
                                    </button>
                                </div>
                            </div>
                            {
                                this.isUsCodeMappingPresent() ? (
                                    <div className="connected-us-code-container">
                                        <div className="description">
                                            <span>Connected US Codes (Parallel Table is revised as of Jan 1, 2019)</span>
                                        </div>
                                        <div className="controls-container">
                                            <div className="us-code-title-number">
                                                <Select
                                                    placeholder={'Title Number'}
                                                    onChange={(event) => this.onValueEntered('connectedUSCodeTitleNumber', event)}
                                                    options={usCodeTitles}
                                                    styles={connectCFRSelectStyles}
                                                    components={{
                                                        IndicatorSeparator: () => null
                                                    }}
                                                />
                                            </div>
                                            <div className="us-code-section-number">
                                                <Select
                                                    placeholder={'Section Number'}
                                                    onChange={(event) => this.onValueEntered('connectedUSCodeSectionNumber', event)}
                                                    options={usCodeSectionsToDisplayInSelect}
                                                    value={connectedUsCodeSectionValueForSelect}
                                                    styles={connectCFRSelectStyles}
                                                    isDisabled={usCodeSectionSelectDisabled}
                                                    components={{
                                                        IndicatorSeparator: () => null
                                                    }}
                                                />
                                            </div>
                                            <div className="connect-button">
                                                <button
                                                    className={`button button-blue ${connectButtonDisabled ? 'not-allowed' : ' '}`}
                                                    disabled={connectButtonDisabled}
                                                    onClick={this.goToConnect}
                                                >Connect
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ) : null
                            }
                        </>
                    }
                    {
                        !isFetching && cfrData && !cfrData.isPresent &&
                        <EmptyResults
                            text={'Markup Redline™ only provides document and information made available by the Government Publishing Office as updated daily. This document has not been published by the GPO for the year you searched.'}/>
                    }
                    {
                        !isFetching && !cfrData &&
                        <EmptyResults text={'Select year, title number, part number and section number.'}/>
                    }
                </div>
                {!isRunningInsideMicrosoftTeams && <Footer/>}
            </div>
        );
    }
}

export default CFRSearchPage;
