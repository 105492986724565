import {authHeader, handleResponse} from "../_helpers";

export const invitationService = {
    getInvitations(page = 1) {
        const requestOptions = {method: 'GET', headers: authHeader()};

        return fetch(`${process.env.REACT_APP_API_URL}/invitations?page=${page}`, requestOptions).then(handleResponse);
    },

    createInvitation(emailAddress) {
        const requestOptions = {
            method: 'POST',
            headers: Object.assign(authHeader(), {'Content-Type': 'application/json'}),
            body: JSON.stringify({emailAddress})
        };

        return fetch(`${process.env.REACT_APP_API_URL}/invitations`, requestOptions).then(handleResponse);
    },

    sendReminder(invitation) {
        const requestOptions = {method: 'POST', headers: authHeader()};

        return fetch(`${process.env.REACT_APP_API_URL}/invitations/${invitation.id}/send-reminder`, requestOptions).then(handleResponse);
    },

    processInvitationsFromResponse(invitations = []) {
        return invitations.map(invitation => Object.assign({...invitation}, {
            isReminderSent: false,
            lastInvitationSentAt: new Date(invitation.lastInvitationSentAt).toDateString()
        }));
    }
};
