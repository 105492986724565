import React from 'react';

import './InvitationsList.css';
import InvitationItem from './InvitationItem';

export default function InvitationList(props) {

    const {invitations, invitationsCount} = props;
    const headerCells = [
        {label: 'E-mail Address', className: 'email'},
        {label: 'Status', className: 'status'},
        {label: 'Invitations Date', className: 'date'},
        {label: '', className: 'icon'}
    ];

    const header = headerCells.map(headerCell => {
        return (
            <div className={`header-cell ${headerCell.className}`}>
                <span>{headerCell.label}</span>
            </div>
        )
    });

    const body = invitations.map((invitation, index) => {
        return (
            <InvitationItem
                key={invitation.id}
                shouldDisplayGreyBackground={index % 2 !== 0}
                invitation={invitation}
                resendInvitation={props.resendInvitation}
            />
        )
    });

    return (
        <div>
            <div className="invitation-list-header">
                {header}
            </div>
            {
                invitationsCount === 0
                    ? (
                        <div className="no-invitations-container">
                            <span>There are no invitations</span>
                        </div>
                    )
                    : (
                        <div className="invitation-list">
                            {body}
                        </div>
                    )
            }
        </div>
    );
}
