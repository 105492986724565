import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../_components/Header/Header';
import Footer from '../_components/Footer/Footer';

class CookiePolicyPage extends Component {
  state = {};

  render() { 
    return (
      <div>
        <Header isSectionsButtonsHidden />
        <div className="content">
          <h1>Cookie Policy</h1>
          <p>To enrich and perfect your online experience, Markup LLC uses "Cookies", similar technologies and services provided by others to display personalized content, appropriate advertising and store your preferences on your computer.</p>
          <p>A cookie is a string of information that a website stores on a visitor's computer, and that the visitor's browser provides to the website each time the visitor returns. Markup LLC uses cookies to help Markup LLC identify and track visitors, and their website access preferences. Markup LLC visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using Markup LLC's websites, with the drawback that certain features of Markup LLC's websites may not function properly without the aid of cookies.</p>
          <p>By continuing to navigate our website without changing your cookie settings, you hereby acknowledge and agree to Markup LLC's use of cookies.</p>
        </div>
        <Footer />
      </div>
    );
  }
}
 
export default CookiePolicyPage;