import React, { Component } from 'react';
import Header from '../_components/Header/Header';
import Footer from '../_components/Footer/Footer';

class PrivacyPolicyPage extends Component {
  state = {};

  render() {
    return (
      <div>
        <Header isSectionsButtonsHidden shouldLogoBeLight={false} />
        <div className="content">
          <h1>Privacy Policy</h1>
          <p>It is the policy of Markup LLC (“us”, “we”, “our”) to respect your privacy regarding any information we may collect while operating Markup LLC services. This Privacy Policy applies to any and all services provided by Markup LLC.  We respect your privacy and are committed to protecting personally identifiable information you may provide us through Markup LLC applications. We have adopted this privacy policy ("Privacy Policy") to explain what information may be collected by Markup LLC, how we use this information, and under what circumstances we may disclose the information to third parties.</p>
          <p>This Privacy Policy, together with the Terms and conditions posted on our website, set forth the general rules and policies governing your use of Markup LLC services. Depending on your activities when using Markup LLC services, you may be required to agree to additional terms and conditions.</p>
          <h2>Website Visitors</h2>
          <p>Like most digital services, Markup LLC collects non-personally-identifying information ofthe sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. Markup LLC's purpose in collecting non-personally identifying information is to better understand how Markup LLC's visitors use our services. From time to time, Markup LLC may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of Markup LLC services</p>
          <p>Markup LLC also collects potentially personally-identifying information like Internet Protocol (IP) addresses for logged in users. Markup LLC only discloses logged-in user and commenter IP addresses under the same circumstances that it uses and discloses personally-identifying information as described below.</p>
          <h2>Gathering of Personally-Identifying Information</h2>
          <p>Certain visitors to Markup LLC's services choose to interact with Markup LLC in ways that require Markup LLC to gather personally-identifying information. The amount and type of information that Markup LLC gathers depends on the nature of the interaction. For example, we ask visitors who sign up for our services to provide a username and email address.</p>
          <h2>Security</h2>
          <p>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>
          <p>All data is stored in the Microsoft Azure Cloud.</p>
          <h2>Links To External Sites</h2>
          <p>Our Service may contain links to external sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy and terms and conditions of every site you visit.</p>
          <p>We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party sites, products or services.</p>
          <h2>Aggregated Statistics</h2>
          <p>Markup LLC may collect statistics about the behavior of users of its services. Markup LLC may display this information publicly or provide it to others. However, Markup LLC does not disclose your personally-identifying information.</p>
          <h2>Privacy Policy Changes</h2>
          <p>Although most changes are likely to be minor, Markup LLC may change its Privacy Policy from time to time, and in Markup LLC's sole discretion. Markup LLC encourages visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.</p>
          <h2>Contact Information</h2>
          <a href="https://www.markuplabs.com/">https://www.markuplabs.com/</a>
          <p>700 Pennsylvania Ave, SE<br />Washington, DC 20003</p>
        </div>
        <Footer />
      </div>
    );
  }
}

export default PrivacyPolicyPage;
