import React from 'react';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import {v4 as uuidv4} from 'uuid';
import * as microsoftTeams from '@microsoft/teams-js';


import './SearchPage.css';
import EmptyResults from '../_components/EmptyResults/EmptyResults';
import Header from '../_components/Header/Header';
import Footer from '../_components/Footer/Footer';
import {parse} from '../_helpers/query'
import {documentService} from '../_services/document.service';
import DocumentList from './DocumentsList';
import PlaceholderShimmer from '../_components/PlaceholderShimmer/PlaceholderShimmer';

class SearchPage extends React.Component {

    constructor(props) {
        super(props);
        const queryObj = parse(this.props.location.search);

        this.state = {
            searchQuery: queryObj.query,
            loading: false,
            value: '',
            documents: [],
            documentsCount: 0,
            currentPage: 1,
            // props for
            isRunningInsideMicrosoftTeams: false,
            selectedDocumentPackageId: null,
            isMicrosoftTeamsConfigurationTab: false,
            isPresent: 0
        };
    }

    handleChangeSearch = (event) => {
        this.setState({value: event.target.value});
    };

    handleSearchKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (this.state.value) {
                this.getDocuments();
            }
        }
    };

    searchClick = async () => {
        await this.setState({currentPage: 1});
        this.getDocuments();
    };

    async getDocuments() {
        if (this.state.value) {
            this.setState({loading: true});
            this.props.history.push('/search?query=' + this.state.value);
            const result = await documentService.getDocuments(this.state.value, this.state.currentPage);
            this.setState({loading: false});
            if (result.documentsCount > 0){
                this.setState({
                    documents: result.documents,
                    documentsCount: result.documentsCount,
                    isPresent : 0
                })
            }else{
                this.setState({
                    documents: [],
                    documentsCount: 0,
                    isPresent : 1
                 })
            }
        }
    };

    async onPageChange(page) {
        await this.setState({currentPage: page});
        this.getDocuments();
    };

    // async handleSearchQuery(query) {
    //   await this.setState({value: query});
    //   await this.setState({currentPage: 1});
    //   this.getDocuments();
    //   this.props.history.push('/search?query='+query);
    // }

    async componentDidMount() {
        const isRunningInsideMicrosoftTeamsFromLocalStorage = localStorage.getItem('isRunningInsideMicrosoftTeams');
        const isRunningInsideMicrosoftTeams = isRunningInsideMicrosoftTeamsFromLocalStorage === 'true';

        if (isRunningInsideMicrosoftTeams) {
            microsoftTeams.getContext((context) => {
                if (context.frameContext === 'settings') {
                    this.setState({
                        isMicrosoftTeamsConfigurationTab: true
                    });
                }
            });
        }

        await this.setState({value: this.state.searchQuery, isRunningInsideMicrosoftTeams});
        this.getDocuments();
    };

    setUpCustomTabSaveHandler = (document) => {
        microsoftTeams.getContext((context) => {

            // frameContext: "content"
            // frameContext: "settings"
            if (context.frameContext === 'settings') {
                microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {

                    microsoftTeams.settings.setSettings({
                        entityId: uuidv4(),
                        contentUrl: `${window.location.origin}/view/${document.packageId}/view?query=${this.state.value}`,
                        suggestedDisplayName: `${document.packageId} ${document.title}`,
                        websiteUrl: window.location.origin
                    });

                    saveEvent.notifySuccess();
                });

                microsoftTeams.settings.setValidityState(true);
            }
        });
    };

    onBillClicked = (packageId) => {
        const {documents, isMicrosoftTeamsConfigurationTab} = this.state;
        const selectedDocument = documents.find(document => document.packageId === packageId);

        this.setState({
            selectedDocumentPackageId: selectedDocument.packageId
        });

        if (isMicrosoftTeamsConfigurationTab) {
            this.setUpCustomTabSaveHandler(selectedDocument);
        }
    };

    render() {
        const currentRoute = this.props.location.pathname;

        const {isRunningInsideMicrosoftTeams, isMicrosoftTeamsConfigurationTab, selectedDocumentPackageId} = this.state;

        return (
            <div>
                {!isRunningInsideMicrosoftTeams && <Header currentRoute={currentRoute} shouldLogoBeLight={false}/>}
                <div className="search-container">
                    <div className="search-section blue-gradient">
                        <div className="search-input">
                            <input type="text" placeholder="Search for U.S. Bills and Resolutions" autoComplete="off"
                                   name="value"
                                   className="text-field text-field-input" onChange={this.handleChangeSearch}
                                   onKeyDown={this.handleSearchKeyDown} value={this.state.value}/>
                        </div>
                        <div className="search-button">
                            <button className="button" onClick={this.searchClick}><span
                                className="material-icons">search</span>Search
                            </button>
                        </div>
                    </div>
                    {
                        this.state.documentsCount > 0 && !this.state.loading && isMicrosoftTeamsConfigurationTab && (
                            <div className="documents-found-wrapper">
                                <div className="choose-document">Choose a Document</div>
                                <div>{this.state.documentsCount} Documents Found</div>
                            </div>
                        )
                    }
                    {this.state.documentsCount > 0 && !this.state.loading && !isMicrosoftTeamsConfigurationTab &&
                    <div className='documents-count-title'>{this.state.documentsCount} Documents Found</div>
                    }
                    {this.state.loading &&
                    <>
                        <PlaceholderShimmer/>
                        <PlaceholderShimmer/>
                        <PlaceholderShimmer/>
                        <PlaceholderShimmer/>
                        <PlaceholderShimmer/>
                    </>
                    }
                    {
                        !this.state.loading && this.state.isPresent === 1 && this.state.documents.length === 0 &&
                        <EmptyResults 
                            text={"Oops..We have no matches for the keyword you've searched for. Please modify your search and try again."}/>
                    }
                    {
                        !this.state.loading && this.state.documents.length === 0 && this.state.isPresent !== 1 &&
                        <EmptyResults 
                            text={'Enter a bill number, version, or keywords of its description.'}/>
                    }
                    
                    {this.state.documents && this.state.documents.length > 0 && !this.state.loading &&
                    <div>
                        <DocumentList
                            onlBillClicked={this.onBillClicked}
                            documents={this.state.documents}
                            searchQuery={this.state.value}
                            isMicrosoftTeamsConfigurationTab={isMicrosoftTeamsConfigurationTab}
                            isRunningInsideMicrosoftTeams={isRunningInsideMicrosoftTeams}
                            selectedDocumentPackageId={selectedDocumentPackageId}
                        />
                        <div className="pagination-section">
                            <Pagination
                                className="rc-pagination"
                                current={this.state.currentPage}
                                total={this.state.documentsCount}
                                locale="en_EN"
                                onChange={(page) => this.onPageChange(page)}/>
                        </div>
                    </div>
                    }
                </div>
                {!isRunningInsideMicrosoftTeams && <Footer/>}
            </div>
        );
    }
}

export default SearchPage;
