import React from 'react';
import DocumentItem from './DocumentItem';

export default function DocumentList(props) {

  return (
    <>
      { props.documents.map((document) => {
        return <DocumentItem
            key={document.packageId}
            document={document}
            isRunningInsideMicrosoftTeams={props.isRunningInsideMicrosoftTeams}
            searchQuery={props.searchQuery}
            isUsedForCFRAndUsCodes={props.isUsedForCFRAndUsCodes}
            onlBillClicked={props.onlBillClicked}
            isMicrosoftTeamsConfigurationTab={props.isMicrosoftTeamsConfigurationTab}
            selectedDocumentPackageId={props.selectedDocumentPackageId}
        />
      })
      }

    </>
  );
}
