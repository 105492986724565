import React, {Component} from 'react';
import * as moment from 'moment-timezone';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import './ReportsPage.css';
import EventsList from './EventsList';
import {trackingsService} from '../_services';
import Header from '../_components/Header/Header';
import Footer from '../_components/Footer/Footer';
import {searchContainerSelectStyles} from '../CFRSearchPage/select-config';
import Pagination from "rc-pagination";

class ReportsPage extends Component {

    eventTypes = [];
    format = 'YYYY-MM-DD HH:mm:ss';

    constructor(props) {
        super(props);

        this.eventTypes = trackingsService.getEventTypes();

        this.state = {
            // flags to hold search state
            from: Date.now(),
            to: Date.now(),
            event: null,
            // manage search button state
            searchButtonDisabled: true,
            // props to hold events & manage loader
            isFetching: false,
            events: [],
            eventsCount: 0,
            currentPage: 1
        };
    }

    formatActionDateForEvents = (events, format) => {
        const tz = moment.tz.guess();

        return events.map(event => {
            return Object.assign(event, {actionDate: moment(event.actionDate).tz(tz).format(format)})
        });
    };

    shouldDisableSearchButton = () => {
        const {to, from, event} = this.state;

        return !to || !from || !event;
    };

    getEventList = async (from, to, event, page = 1) => {
        try {
            this.setState({
                isFetching: true
            });

            const tz = moment.tz.guess();

            // set minutes, hours & seconds to 0, 'cause we do not need it for search
            const fromDateStr = moment(from).hours(0).minutes(0).seconds(0).tz(tz).format();
            const toDateStr = moment(to).hours(23).minutes(59).seconds(59).tz(tz).format();

            let request = null;

            if (event === 'Registration') {
                request = trackingsService.getRegistrationEvents(fromDateStr, toDateStr, page);
            } else if (event === 'Login') {
                request = trackingsService.getLoginEvents(fromDateStr, toDateStr, page);
            } else {
                request = trackingsService.getRedirectEvents(fromDateStr, toDateStr, page);
            }

            const response = await request;

            let events = [], eventsCount = 0;

            if (event === 'Registration') {
                events = response.registrationEvents;
                eventsCount = response.registrationEventsCount;
            } else if (event === 'Login') {
                events = response.loginEvents;
                eventsCount = response.loginEventsCount;
            } else {
                events = response.redirectEvents;
                eventsCount = response.redirectEventsCount;
            }

            const processedEvents = this.formatActionDateForEvents(events, this.format);

            this.setState({
                isFetching: false,
                events: processedEvents,
                eventsCount
            });
        } catch (err) {
            console.error(err);

            this.setState({
                isFetching: false
            });
        }
    };

    searchEvents = () => {
        const {to, from, event} = this.state;

        this.getEventList(from, to, event);
    };

    onValueEntered = async (controlName, event) => {
        let value = null;

        if (controlName === 'event') {
            value = event.value;
        } else if (controlName === 'from') {
            value = event;
        } else if (controlName === 'to') {
            value = event;
        }

        await this.setState({
            [controlName]: value
        });

        const searchButtonDisabled = this.shouldDisableSearchButton();

        this.setState({
            searchButtonDisabled
        });
    };

    onPageChange = async (page) => {
        const {to, from, event} = this.state;

        await this.setState({currentPage: page});

        this.getEventList(from, to, event, page);
    };

    goToTheFirstPage = async () => {
        await this.setState({currentPage: 1});

        const {to, from, event} = this.state;

        this.getEventList(from, to, event, 1);
    };

    goToTheLastPage = async () => {
        const {eventsCount, to, from, event} = this.state;
        // TODO: think of storing it somewhere instead of hardcoding
        const itemsPerPage = 10;
        const lastPage = Math.ceil(eventsCount / itemsPerPage);

        await this.setState({currentPage: lastPage});
        this.getEventList(from, to, event, lastPage);
    };

    render() {
        const {
            event: selectedEventType,
            events,
            currentPage,
            eventsCount,
            searchButtonDisabled,
            from,
            to
        } = this.state;

        return (
            <div>
                <Header isSectionsButtonsHidden shouldLogoBeLight={false}/>
                <div className="report-container">
                    <div className="search-section blue-gradient">
                        <div className="search-controls-wrapper">
                            <div className="event-select">
                                <Select
                                    menuPortalTarget={document.body}
                                    name="event"
                                    onChange={(event) => this.onValueEntered('event', event)}
                                    options={this.eventTypes}
                                    placeholder={'Event Type'}
                                    styles={searchContainerSelectStyles}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                />
                            </div>
                            <div className="from-date">
                                <DatePicker
                                    selected={from}
                                    onChange={(event) => this.onValueEntered('from', event)}
                                />
                            </div>
                            <div className="to-date">
                                <DatePicker
                                    selected={to}
                                    onChange={(event) => this.onValueEntered('to', event)}
                                />
                            </div>
                        </div>
                        <div className="search-button">
                            <button className={`button ${searchButtonDisabled ? 'not-allowed' : ''}`}
                                    onClick={this.searchEvents}
                                    disabled={searchButtonDisabled}>
                                <span className="material-icons">search</span>Search
                            </button>
                        </div>
                    </div>
                    <div className="event-list-container">
                        <div className="events-title">
                            <span>{selectedEventType} Events</span>
                        </div>
                        <EventsList
                            selectedEventType={selectedEventType}
                            eventsCount={eventsCount}
                            events={events}
                        />
                    </div>
                    <div className="pagination-container">
                        <div className="pagination-button" onClick={this.goToTheFirstPage}>
                            <span>First</span>
                        </div>
                        <div className="pagination-wrapper">
                            <Pagination
                                className="rc-pagination"
                                current={currentPage}
                                total={eventsCount}
                                locale="en_EN"
                                onChange={(page) => this.onPageChange(page)}
                            />
                        </div>
                        <div className="pagination-button" onClick={this.goToTheLastPage}>
                            <span>Last</span>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default ReportsPage;
