
import {authHeader, handleResponse} from "../_helpers";

var Constant = require('../_constants');

export const cfgService = {
    
    getYears(CodeType) {
        var requestOptions = {method: 'GET', headers: authHeader()};
        if(CodeType==Constant.CodeType_USCode_Year_String)
        {
            return fetch(`${process.env.REACT_APP_API_URL}/cfg/codeValue/${Constant.CodeType_USCode_Year}`, requestOptions).then(handleResponse);
        }
        else if(CodeType==Constant.CodeType_CFR_Year_String)
        {
            return fetch(`${process.env.REACT_APP_API_URL}/cfg/codeValue/${Constant.CodeType_CFR_Year}`, requestOptions).then(handleResponse);
        }

        // returns fetch(`${process.env.REACT_APP_API_URL}/us-codes/download?title=${title}&year=${year}&section=${section}`, requestOptions);
    },
    getTitles(CodeType) {
        const requestOptions = {method: 'GET', headers: authHeader()};
        if(CodeType==Constant.CodeType_USCode_Title_String)
        {
            return fetch(`${process.env.REACT_APP_API_URL}/cfg/codeValue/${Constant.CodeType_USCode_Title}`, requestOptions).then(handleResponse);
        }
        else if(CodeType==Constant.CodeType_CFR_Title_String)
        {
            return fetch(`${process.env.REACT_APP_API_URL}/cfg/codeValue/${Constant.CodeType_CFR_Title}`, requestOptions).then(handleResponse);
        }

    }
};
