import React, {Component} from 'react';
import {ContextMenu, MenuItem, ContextMenuTrigger} from 'react-contextmenu';

import './DocumentViewer.css';
import PDFViewer from '../PdfViewer/PdfViewer';
import Select from 'react-select';
import {connectCFRSelectStyles} from '../../CFRSearchPage/select-config';
import {getVersionTitle} from '../../_helpers/documents';
import {getIssuedDate} from "../../_helpers/date";
import EmptyResults from '../EmptyResults/EmptyResults';
import PlaceholderShimmer from "../PlaceholderShimmer/PlaceholderShimmer";
import {cfgService} from '../../_services/config.service';
import {convertResponseToValueLabelForYear, convertResponseToValueLabelForTitle} from '../../_helpers/handleConfigResponse';
var Constant = require('../../_constants');
// TODO: it's almost the same with document viewer. Think about creating one component
class DocumentViewer extends Component {

    cfrYears = [];

    constructor(props) {
        super(props);

        
        
        this.state = {
            // properties to hold data for cfr search
            searchCfrTitle: null,
            searchCfrYear: null,
            searchCfrPart: '',
            searchCfrSection: '',
            // properties to hold data for connected cfr search
            connectCfrTitle: null,
            connectCfrPart: null,
            titleListUsCode : [],
            titleListCFR : [],
            yearListUsCode : [],
            yearListCFR : [],

            // this property is needed to nullify part select when new cfr title is selected
            connectedCfrPartValueForSelect: null,
            // flags to control state of buttons (search cfr, search mapped cfr, .etc)
            shouldCfrSearchButtonBeDisabled: true,
            shouldCfrConnectButtonBeDisabled: true,
            cfrPartSelectDisabled: true,
            // this is crf parts that should be displayed when particular cfr title is selected
            cfrPartsToDisplayInSelect: []
        };
    }


    async componentDidMount() {
        var CFRTitles = await cfgService.getTitles(Constant.CodeType_CFR_Title_String);
        this.setState({titleListCFR:(convertResponseToValueLabelForTitle(CFRTitles))})  

        var USCodeTitles = await cfgService.getTitles(Constant.CodeType_USCode_Title_String);
        this.setState({titleListUsCode:(convertResponseToValueLabelForTitle(USCodeTitles))})  
        
        // var CFRYears = await cfgService.getYears(Constant.CodeType_CFR_Year_String);
        // this.setState({yearListUsCode:(convertResponseToValueLabelForYear(CFRYears)).reverse()})  

        var USCodeYears = await cfgService.getYears(Constant.CodeType_CFR_Year_String);
        this.setState({yearListCFR:(convertResponseToValueLabelForYear(USCodeYears)).reverse()})  

    }

    // I have to nullify everything except mode when mode is changed
    nullifyState = () => {
        this.setState({
            searchCfrTitle: null,
            searchCfrYear: null,
            searchCfrPart: '',
            searchCfrSection: '',
            connectCfrTitle: null,
            connectCfrPart: null,
            connectedCfrPartValueForSelect: null,
            shouldCfrSearchButtonBeDisabled: true,
            shouldCfrConnectButtonBeDisabled: true,
            cfrPartSelectDisabled: true
        });
    };

    isViewBillMode = () => {
        const {document} = this.props;

        return document.mode === 'view-bill';
    };

    isSearchCfrMode = () => {
        const {document} = this.props;

        return document.mode === 'search-cfr';
    };

    isConnectedCftMode = () => {
        const {document} = this.props;

        return document.mode === 'connected-cfr';
    };

    isViewDocumentMode = () => {
        const {document} = this.props;

        return document.mode === 'view-document'
    };

    getCfrTitleLabelByTitleNumber = (titleNumber) => {
        if (this.state.titleListCFR != undefined){
            if (this.state.titleListCFR != 0)
            {const cfrTitles = this.state.titleListCFR

        return cfrTitles.find(title => title.value === titleNumber).label;}
}
    };

    getUsCodeTitleLabelByTitleNuber = (titleNumber) => {
        if (this.state.titleListUsCode != undefined){
 
            if (this.state.titleListUsCode != 0)           {           const usCodeTitles = this.state.titleListUsCode
    
            return usCodeTitles.find(title => title.value === titleNumber).label;}
    }
    };

    getTitle = (documentLink, titleNumber) => {
        return documentLink.includes('uscode') ? this.getShortTitle(this.getUsCodeTitleLabelByTitleNuber(titleNumber)) : this.getShortTitle(this.getCfrTitleLabelByTitleNumber(titleNumber));
    };

    onCfrSearch = () => {
        const {searchCfrTitle, searchCfrPart, searchCfrSection, searchCfrYear} = this.state;
        const {document: {id}} = this.props;

        this.props.onCfrSearch(id, searchCfrYear, searchCfrTitle, searchCfrPart, searchCfrSection);
    };

    onCfrConnect = () => {
        const {connectCfrTitle, connectCfrPart} = this.state;
        const {document: {id}} = this.props;

        this.props.onCfrConnect(id, connectCfrTitle, connectCfrPart);
    };

    shouldCfrSearchButtonBeDisabled = () => {
        const {searchCfrPart, searchCfrTitle, searchCfrYear} = this.state;

        return searchCfrPart === '' || searchCfrTitle === null || searchCfrYear === null;
    };

    shouldCfrConnectButtonBeDisabled = () => {
        const {connectCfrTitle, connectCfrPart} = this.state;

        return connectCfrTitle === null || connectCfrPart === null;
    };

    shouldSelectCfrPartBeDisabled = () => {
        const {connectCfrTitle} = this.state;

        return !connectCfrTitle;
    };

    onValueEntered = async (controlName, event) => {
        let value = null;
        let cfrPartsToDisplayInSelect = this.state.cfrPartsToDisplayInSelect;
        let connectedCfrPartValueForSelect;
        let connectCfrPart;

        if (controlName === 'connectCfrTitle') {
            value = event.value;
            cfrPartsToDisplayInSelect = this.getCfrPartsBySelectedCfrTitle(value);
            // we should nullify connectCfrPart & connectedCfrPartValueForSelect when new title is selected
            connectedCfrPartValueForSelect = null;
            connectCfrPart = null;
        } else if(controlName === 'connectCfrPart') {
            value = event.value;
            connectCfrPart = value;
            connectedCfrPartValueForSelect = cfrPartsToDisplayInSelect.find(part => part.value === value);
        } else if (controlName === 'searchCfrTitle') {
            value = event.value;
        } else if(controlName === 'searchCfrYear') {
            value = event.value;
        } else {
            // searchCfrSection and searchCfrPart is string, so we leave it as it is
            value = event.target.value;
        }

        // we need always to to it before shouldCfrSearchButtonBeDisabled, shouldCfrConnectButtonBeDisabled, shouldSelectCfrPartBeDisabled
        await this.setState({
            [controlName]: value,
            cfrPartsToDisplayInSelect,
            connectedCfrPartValueForSelect,
            connectCfrPart
        });

        const shouldCfrSearchButtonBeDisabled = this.shouldCfrSearchButtonBeDisabled();
        const shouldCfrConnectButtonBeDisabled = this.shouldCfrConnectButtonBeDisabled();
        const cfrPartSelectDisabled = this.shouldSelectCfrPartBeDisabled();

        this.setState({
            shouldCfrSearchButtonBeDisabled,
            shouldCfrConnectButtonBeDisabled,
            cfrPartSelectDisabled
        });
    };

    // get related cfr parts by given cfr title
    getCfrPartsBySelectedCfrTitle = (cfrTitle) => {
        const {connectedCfrParts} = this.props;
        const target = connectedCfrParts.find(cfrPart => cfrPart.cfrTitle === cfrTitle);

        return target.cfrParts;
    };

    isViewDocumentDataPresent = () => {
        const {document} = this.props;

        // to display document correctly all data should be present (section is optional, that is why I omit it)
        // isLoading also should be omitted, 'cause it is not document data, it's just a flag used for spinner purposes
        return !Object
            .keys(document)
            .filter(key => !(key === 'section' || key === 'part' || key === 'bill' || key === 'isLoading'))
            .some(key => !document[key]);
    };

    changeMode = (mode) => {
        const {document} = this.props;

        this.nullifyState();

        this.props.onChangeMode(document.id, mode);
    };

    getShortTitle = (title) => {
        let shortTitle;
            if(title != undefined)
        {if(title.length >= 50) {
            shortTitle = title.substr(0, 50) + '...';
        } else {
            shortTitle = title;
        }return shortTitle;}

    };

    downloadDocument = (type) => {
        let data = null;

        if(type === 'package') {
            // if we know that type is package then we should go to bill property and grab package id
            data = { packageId: this.props.document.bill.packageId };
        } else {
            data = this.props.document;
        }

        this.props.onDownload(type, data);
    };

    onBillSearch = () => {
        const {document} = this.props;

        this.props.onBillSearch(document.id);
    };

    render() {
        const {document, cfrTitles, connectedCfrTitles} = this.props;
        const {
            shouldCfrSearchButtonBeDisabled,
            shouldCfrConnectButtonBeDisabled,
            searchCfrPart,
            searchCfrSection,
            cfrPartSelectDisabled,
            cfrPartsToDisplayInSelect,
            connectedCfrPartValueForSelect
        } = this.state;

        const bill = document && document.bill;

        return (
            <>
                {
                    this.isSearchCfrMode() && (
                        <div className="document-container">
                            <div className="document-description-container">
                                <div className="name-container">
                                    <span>{document.name}</span>
                                    <ContextMenuTrigger
                                        id={document.id.toString()}
                                        holdToDisplay={0}
                                    >
                                        <img alt="3 dots icon" src="/static/media/3-dots.svg"/>
                                    </ContextMenuTrigger>

                                    <ContextMenu id={document.id.toString()}>
                                        <MenuItem onClick={() => this.changeMode('search-cfr')}>
                                            CFR Search
                                        </MenuItem>
                                        <MenuItem onClick={() => this.changeMode('connected-cfr')}>
                                            Connected CFR
                                        </MenuItem>
                                        <MenuItem onClick={this.onBillSearch}>
                                            Bill Search
                                        </MenuItem>
                                        <MenuItem className="disabled">
                                            Download
                                        </MenuItem>
                                    </ContextMenu>
                                </div>
                            </div>
                            <div className="connected-cfr-search-container">
                                <div className="description-container">
                                    <span>CFRs search</span><br/>
                                    <span>(Please select Title, Part & Section to view)</span>
                                </div>
                                <div className="selects-container">
                                    <div className="year-select">
                                        <Select
                                            placeholder={'Year'}
                                            onChange={(event) => this.onValueEntered('searchCfrYear', event)}
                                            styles={connectCFRSelectStyles}
                                            options={this.state.yearListCFR}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                        />
                                    </div>
                                    <div className="title-select">
                                        <Select
                                            placeholder={'Title Number'}
                                            onChange={(event) => this.onValueEntered('searchCfrTitle', event)}
                                            styles={connectCFRSelectStyles}
                                            options={cfrTitles}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                        />
                                    </div>
                                    <div className="part-number">
                                        <input
                                            type="text"
                                            pattern="[0-9]*"
                                            placeholder="Part number"
                                            onChange={(event) => this.onValueEntered('searchCfrPart', event)}
                                            value={searchCfrPart || ''}
                                            autoComplete="off"
                                            className="text-field text-field-input"/>
                                    </div>
                                    <div className="section-number">
                                        <input
                                            type="text"
                                            pattern="[0-9]*"
                                            placeholder="Section number"
                                            onChange={(event) => this.onValueEntered('searchCfrSection', event)}
                                            value={searchCfrSection || ''}
                                            autoComplete="off"
                                            className="text-field text-field-input"/>
                                    </div>
                                    <div>
                                        <button
                                            disabled={shouldCfrSearchButtonBeDisabled}
                                            className={`button button-blue w-100 ${shouldCfrSearchButtonBeDisabled ? 'not-allowed' : ' '}`}
                                            onClick={this.onCfrSearch}
                                        >View
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    this.isViewDocumentMode() && this.isViewDocumentDataPresent() && !document.isLoading && (
                        <div className="document-container">
                            <div className="document-description-container">
                                <div className="name-container">
                                    <span>{document.name}</span>
                                    <ContextMenuTrigger
                                        id={document.id.toString()}
                                        holdToDisplay={0}
                                    >
                                        <img alt="3 dots icon" src="/static/media/3-dots.svg"/>
                                    </ContextMenuTrigger>

                                    <ContextMenu id={document.id.toString()}>
                                        {
                                            document.id !== 1 && (
                                                <>
                                                    <MenuItem onClick={() => this.changeMode('search-cfr')}>
                                                        CFR Search
                                                    </MenuItem>
                                                    <MenuItem onClick={() => this.changeMode('connected-cfr')}>
                                                        Connected CFR
                                                    </MenuItem>
                                                    <MenuItem onClick={this.onBillSearch}>
                                                        Bill Search
                                                    </MenuItem>
                                                </>
                                            )
                                        }
                                        <MenuItem
                                            onClick={() => this.downloadDocument(document.id !== 1 ? 'cfr' : 'uscode')}>
                                            Download
                                        </MenuItem>
                                    </ContextMenu>
                                </div>
                                <div className="title-container">
                                    <span>{this.getTitle(document.documentLink, document.title)}</span>
                                    <div className="chips-container">
                                        <span className="chips">{document.year} Edition</span>
                                        {
                                            document.part && (
                                                <span className="chips">Part {document.part}</span>
                                            )
                                        }
                                        {
                                            document.section && (
                                                <span className="chips">Section {document.section}</span>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="pdf-viewer-container">
                                <PDFViewer file={document.documentLink}/>
                            </div>
                        </div>
                    )
                }
                {
                    this.isViewDocumentMode() && !this.isViewDocumentDataPresent() && !document.isLoading && (
                        <div className="document-container">
                            <div className="document-description-container">
                                <div className="name-container">
                                    <span>{document.name}</span>
                                    <ContextMenuTrigger
                                        id={document.id.toString()}
                                        holdToDisplay={0}
                                    >
                                        <img alt="3 dots icon" src="/static/media/3-dots.svg"/>
                                    </ContextMenuTrigger>

                                    <ContextMenu id={document.id.toString()}>
                                        <MenuItem onClick={() => this.changeMode('search-cfr')}>
                                            CFR Search
                                        </MenuItem>
                                        <MenuItem onClick={() => this.changeMode('connected-cfr')}>
                                            Connected CFR
                                        </MenuItem>
                                        <MenuItem onClick={this.onBillSearch}>
                                            Bill Search
                                        </MenuItem>
                                        <MenuItem className="disabled">
                                            Download
                                        </MenuItem>
                                    </ContextMenu>
                                </div>
                            </div>
                            <EmptyResults text={'Markup Redline™ only provides document and information made available by the Government Publishing Office as updated daily. This document has not been published by the GPO for the year you searched.'}/>
                        </div>
                    )
                }
                {
                    this.isViewBillMode() && bill && (
                        <div className="document-container">
                            <div className="document-description-container">
                                <div className="name-container">
                                    <span>{document.name}</span>
                                    <ContextMenuTrigger
                                        id={document.id.toString()}
                                        holdToDisplay={0}
                                    >
                                        <img alt="3 dots icon" src="/static/media/3-dots.svg"/>
                                    </ContextMenuTrigger>

                                    <ContextMenu id={document.id.toString()}>
                                        {
                                            document.id !== 1 && (
                                                <>
                                                    <MenuItem onClick={() => this.changeMode('search-cfr')}>
                                                        CFR Search
                                                    </MenuItem>
                                                    <MenuItem onClick={() => this.changeMode('connected-cfr')}>
                                                        Connected CFR
                                                    </MenuItem>
                                                    <MenuItem onClick={this.onBillSearch}>
                                                        Bill Search
                                                    </MenuItem>
                                                </>
                                            )
                                        }
                                        <MenuItem onClick={() => this.downloadDocument('package')} >Download</MenuItem>
                                    </ContextMenu>
                                </div>
                                <div className="title-container">
                                    <span>{this.getShortTitle(bill.title)}</span>
                                    <div className="chips-container connected-us-code">
                                        <span className="chips">{bill.type}.{bill.number}</span>
                                        <span className="chips">{getVersionTitle(bill.version)}</span>
                                        <span className="chips">{getIssuedDate(bill.issuedAt)}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="pdf-viewer-container">
                                <PDFViewer file={bill.documentLink}/>
                            </div>
                        </div>
                    )
                }
                {
                    this.isViewDocumentMode() && document.isLoading && (
                        <div className="document-container">
                            <PlaceholderShimmer />
                            <div className="lds-dual-ring-container">
                                <div className="lds-dual-ring"></div>
                            </div>
                        </div>
                    )
                }
                {
                    this.isConnectedCftMode() && (
                        <div className="document-container">
                            <div className="document-description-container">
                                <div className="name-container">
                                    <span>{document.name}</span>
                                    <ContextMenuTrigger
                                        id={document.id.toString()}
                                        holdToDisplay={0}
                                    >
                                        <img alt="3 dots icon" src="/static/media/3-dots.svg"/>
                                    </ContextMenuTrigger>

                                    <ContextMenu id={document.id.toString()}>
                                        <MenuItem onClick={() => this.changeMode('search-cfr')}>
                                            CFR Search
                                        </MenuItem>
                                        <MenuItem onClick={() => this.changeMode('connected-cfr')}>
                                            Connected CFR
                                        </MenuItem>
                                        <MenuItem onClick={this.onBillSearch}>
                                            Bill Search
                                        </MenuItem>
                                        <MenuItem className="disabled">
                                            Download
                                        </MenuItem>
                                    </ContextMenu>
                                </div>
                            </div>
                            <div className="connected-cfr-search-container">
                                <div className="description-container">
                                    <span>Connected CFRs</span><br/>
                                    <span>(Parallel Table is revised as of Jan 1, 2019)</span>
                                </div>
                                <div className="selects-container">
                                    <div className="title-select">
                                        <Select
                                            onChange={(event) => this.onValueEntered('connectCfrTitle', event)}
                                            placeholder={'Title Number'}
                                            options={connectedCfrTitles}
                                            styles={connectCFRSelectStyles}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                        />
                                    </div>
                                    <div className="part-select">
                                        <Select
                                            onChange={(event) => this.onValueEntered('connectCfrPart', event)}
                                            placeholder={'Part Number'}
                                            options={cfrPartsToDisplayInSelect}
                                            styles={connectCFRSelectStyles}
                                            value={connectedCfrPartValueForSelect}
                                            isDisabled={cfrPartSelectDisabled}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <button
                                            disabled={shouldCfrConnectButtonBeDisabled}
                                            className={`button button-blue w-100 ${shouldCfrConnectButtonBeDisabled ? 'not-allowed' : ' '}`}
                                            onClick={this.onCfrConnect}
                                        >View
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </>
        );
    }
}

export default DocumentViewer;
