import React, {Component} from 'react';
import Header from '../_components/Header/Header';
import Footer from '../_components/Footer/Footer';
import PDFViewer from '../_components/PdfViewer/PdfViewer';
import {documentService} from '../_services/document.service';
import DownloadFile from '../_components/DownloadFile/DownloadFile';
import {Redirect} from 'react-router-dom';

import 'react-responsive-modal/styles.css';
import {getVersionTitle} from '../_helpers/documents';
import {getIssuedDate} from '../_helpers/date';
import './ViewerPage.css';
import PlaceholderShimmer from '../_components/PlaceholderShimmer/PlaceholderShimmer';

class ViewerPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isRunningInsideMicrosoftTeams: false,
            packageId: null,
            fileLink: this.props.fileLink || '',
            searchQuery: this.props.location?.search,
            redirectUrl: null,
            isLoading: false,
            document: null,
            modalOpen: false,
            isVersionsShow: false,
            documentVersions: [],
            selectedPackageId: null
        }
    }

    async componentDidMount() {
        const isRunningInsideMicrosoftTeamsFromLocalStorage = localStorage.getItem('isRunningInsideMicrosoftTeams');
        const isRunningInsideMicrosoftTeams = isRunningInsideMicrosoftTeamsFromLocalStorage === 'true';

        await this.setState({
            isLoading: true,
            packageId: this.props.match?.params?.packageId,
            isRunningInsideMicrosoftTeams
        });

        const result = await documentService.getDocument(this.state.packageId);
        this.setState({
            fileLink: result.link,
            document: result.document,
            isLoading: false
        });
        this.getDocumentVersions();
    }


    getDocumentVersions = () => {
        documentService.getDocumentVersions(this.state.packageId)
            .then((response) => {
                this.setState({documentVersions: response.documents});
            });
    };

    showSearch = async () => {
        await this.setState({redirectUrl: '/search' + this.state.searchQuery});
    };

    showVersions = async () => {
        await this.setState({isVersionsShow: !this.state.isVersionsShow});
    };

    selectPackage = (packageId) => {
        (packageId !== this.state.packageId) && this.setState({selectedPackageId: packageId});
    };

    showSelectedPackage = async () => {
        if (!this.state.selectedPackageId || (this.state.selectedPackageId === this.state.packageId)) {
            this.setState({isVersionsShow: false});
        } else {
            await this.setState({
                packageId: this.state.selectedPackageId,
                selectedPackageId: null,
                isLoading: true
            });
            this.props.history.push('/view/' + this.state.packageId + '/view' + this.state.searchQuery);
            const result = await documentService.getDocument(this.state.packageId);
            this.setState({
                fileLink: result.link,
                document: result.document,
                isVersionsShow: false,
                isLoading: false
            });
        }
    };

    openPage = (page) => {
        this.setState({redirectUrl: '/view/' + this.state.packageId + '/' + page + this.state.searchQuery})
    };

    render() {
        const currentRoute = this.props.location.pathname;
        const {document, redirectUrl} = this.state;
        if (redirectUrl) {
            return <Redirect to={redirectUrl}/>
        }

        return (
            <div>
                {!this.state.isRunningInsideMicrosoftTeams && <Header currentRoute={currentRoute} activeMenuItem='3' shouldLogoBeLight={false}/>}
                <div className="viewer-section blue-gradient">
                    {/* TODO:: move this part to separate common component */}
                    <div className="back-button" onClick={this.showSearch}>
                        <span className="material-icons">keyboard_backspace</span>
                        <span className="back-text">Back</span>
                    </div>
                    <div className="viewer-section-buttons">
                        <button className="viewer-section-button left-rounded active">View</button>
                        <button className="viewer-section-button no-left-right-borders"
                                onClick={() => this.openPage('side-by-side')}>Side by side
                        </button>
                        <button className="viewer-section-button right-rounded"
                                onClick={() => this.openPage('redline')}>Redline
                        </button>
                    </div>
                    <div className="change-version-button">
                        <button className="button" onClick={this.showVersions}><span
                            className="material-icons">{this.state.isVersionsShow ? 'cancel' : 'update'}</span>{this.state.isVersionsShow ? 'Cancel' : 'Change version'}
                        </button>
                    </div>
                </div>
                <div className="document-item-header">
                    {/* { !document && isLoading &&
            <div className='details-loading'></div>
          } */}
                    {document &&
                    <div className="document-details">
                        <h3>{document.title}</h3>
                        <div className="document-description">{document.description}</div>
                        <div className="document-meta-tags">
                            <span className="meta-tag"
                                  style={{"textTransform": "uppercase"}}>{document.type}.{document.number}</span>
                            <span className="meta-tag">{getVersionTitle(document.version)}</span>
                            <span className="meta-tag">{getIssuedDate(document.issuedAt)}</span>
                        </div>
                    </div>
                    }
                    <div className="document-download-button">
                        {!this.state.isLoading && this.state.packageId &&
                        <div className="download-icon-wrapper">
                            <DownloadFile
                                icon="word"
                                type={'package'}
                                packageId={this.state.packageId}
                            />
                        </div>
                        }
                    </div>
                </div>
                {!this.state.isLoading && this.state.fileLink &&
                <div className="viewer">
                    {this.state.isVersionsShow &&
                    // TODO:: move this part to separate component
                    <div className="versions-container">
                        <div className="version-header">
                            <div className="version-text">
                                <span>{this.state.documentVersions.length}</span> Versions Available
                            </div>
                        </div>
                        <div className="versions">
                            {this.state.documentVersions.map(document => {
                                const disabled = (document.packageId === this.state.packageId);
                                const selected = (document.packageId === this.state.selectedPackageId);
                                let classes = 'version';
                                disabled && (classes = classes + ' disabled');
                                selected && (classes = classes + ' selected blue-gradient');
                                return (
                                    <div onClick={() => this.selectPackage(document.packageId)} className={classes}
                                         key={document.packageId}>
                                        <div className="version-icon">
                                            <span
                                                className="material-icons">{disabled || selected ? 'check_circle' : 'radio_button_unchecked'}</span>
                                        </div>
                                        <div className="details">
                                            <div className="name">{getVersionTitle(document.version)}</div>
                                            <div className="issued-at">{getIssuedDate(document.issuedAt)}</div>
                                        </div>
                                    </div>
                                )
                            })
                            }
                            {this.state.documentVersions.length > 1 &&
                            <button onClick={this.showSelectedPackage}
                                    className="button blue-gradient view-document-button">View</button>
                            }
                        </div>
                    </div>
                    }
                    <PDFViewer file={this.state.fileLink}/>
                </div>
                }
                {this.state.isLoading &&
                <div>
                    <PlaceholderShimmer/>
                    <div className="lds-dual-ring-container">
                        <div className="lds-dual-ring"></div>
                    </div>
                </div>
                }
                {!this.state.isRunningInsideMicrosoftTeams && <Footer/>}
            </div>
        );
    }
}

export default ViewerPage;
