import React, {Component} from 'react';

import './InvitationItem.css';

class InvitationItem extends Component {

    isReminderSent() {
        const {invitation} = this.props;

        return invitation.isReminderSent;
    }

    isRegistered() {
        const {invitation} = this.props;

        return invitation.registered;
    }

    getStatus() {
        return this.isRegistered() ? 'Confirmed' : 'Awaiting registration'
    }

    getIconName() {
        return this.isReminderSent() ? 'opened.svg' : 'refresh.svg';
    }

    getDate() {
        const {invitation} = this.props;

        return this.isReminderSent() ? 'Reminder sent!' : invitation.lastInvitationSentAt;
    }

    resendInvitation() {
        if (this.isRegistered()) {
            return;
        }

        this.props.resendInvitation(this.props.invitation);
    }

    render() {
        const {invitation, shouldDisplayGreyBackground} = this.props;

        return (
            <div className={`invitation-item ${shouldDisplayGreyBackground ? 'dark' : ''}`}>
                <div className='email'>
                    <span>{invitation.emailAddress}</span>
                </div>
                <div className={`status ${this.isRegistered() ? 'confirmed' : ''}`}>
                    <span>{this.getStatus()}</span>
                </div>
                <div className={`date ${this.isReminderSent() ? 'sent' : ''}`}>
                    <span>{this.getDate()}</span>
                </div>
                <div
                    onClick={() => this.resendInvitation()}
                    className={`icon ${this.isReminderSent() ? 'sent' : ''} ${this.isRegistered() ? '' : 'cursor-pointer'}`}
                >
                    {!this.isRegistered() && <img alt="icon" src={`/static/media/${this.getIconName()}`}/>}
                </div>
            </div>
        )
    }
}

export default InvitationItem;
