import { authHeader, handleResponse } from '../_helpers';

export const userService = {
  getProfile() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${process.env.REACT_APP_API_URL}/users/profile`, requestOptions).then(handleResponse);
  },

  createUser(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/registration`, requestOptions)
      .then(handleResponse)
      .then(response => {
          return response;
      });
  },

  sendResetPasswordEmail(emailAddress) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({emailAddress})
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/recovery-email`, requestOptions).then(handleResponse)
  },

  resetPassword(token, password, confirmPassword) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({password, confirmPassword})
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/reset-password/${token}`, requestOptions).then(handleResponse)
  },

  updateUser(data) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/profile`, requestOptions)
      .then(handleResponse)
      .then(response => {
          return response;
      });
  },

  updateUserPassword(data) {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/password`, requestOptions)
      .then(handleResponse)
      .then(response => {
          return response;
      });
  },

  deleteUser(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users`, requestOptions)
      .then(handleResponse)
      .then(response => {
          return response;
      });
  },

  activateUser(token) {
    const requestOptions = { method: 'GET', headers: {'Content-Type': 'application/json'} };
    return fetch(`${process.env.REACT_APP_API_URL}/users/activation/${token}`, requestOptions)
    .then(handleResponse);
  },

  reactivateUser(data) {
    const sendData= {'emailAddress':data}
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(sendData)
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/reactivation`, requestOptions)
      .then(handleResponse)
      .then(response => {
          return response;
      });
  },

};

