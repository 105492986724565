import React, {Component} from 'react';

import {DownloadService} from '../../_services/download-file.service';


class DownloadFile extends Component {

    icons = {
        default: 'default',
        word: 'word'
    };

    constructor(props) {
        super(props);
    }

    getFileName = () => {
        const {type} = this.props;

        return DownloadService.getFileName(type, this.props);
    };

    getTitle = () => {
        const {type} = this.props;

        return DownloadService.getTitle(type);
    };

    getFileRequest = () => {
        const {type} = this.props;

        return DownloadService.getFileRequest(type, this.props);
    };

    downloadFile = async () => {
        const response = await this.getFileRequest();
        var responseFileURL = (await response.json()).fileUrl
        // const blob = await response.blob(x);
        const downloadLink = document.createElement("a");
        downloadLink.href = responseFileURL;
        downloadLink.download = "test";
        downloadLink.click()
        // const url = window.URL.createObjectURL(blob);
        // const a = document.createElement('a');

        // a.href = url;
        // a.download = this.getFileName();
        // a.click();
    };

    shouldDisplayDefaultIcon() {
        const {icon} = this.props;

        return icon === this.icons.default;
    }

    shouldDisplayWordIcon() {
        const {icon} = this.props;

        return icon === this.icons.word;
    }

    render() {
        const title = this.getTitle();

        return (
            <>
                {
                    this.shouldDisplayWordIcon() && (
                        <img
                            style={{width: '100%', height: '100%', cursor: 'pointer'}}
                            alt="download icon"
                            src="/static/media/word-download.svg"
                            onClick={this.downloadFile} title={title}
                        />
                    )
                }
                {
                    this.shouldDisplayDefaultIcon() && (
                        <span
                            title={title}
                            className="material-icons"
                            style={{'cursor': 'pointer'}}
                            onClick={this.downloadFile}>save_alt
                        </span>
                    )
                }
            </>
        );
    }
}

export default DownloadFile;
