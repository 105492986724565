import React, { Component } from 'react';
import './RegistrationPage.css'
import { authenticationService, userService, trackingsService } from '../_services';
import { Redirect, Link } from 'react-router-dom';
import Logo from '../_components/Logo/Logo';
import { toast } from 'react-toastify';
import Modal from 'react-responsive-modal';

class RegistrationPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isDisabledButton: true,
            emailError: false,
            confirmPasswordError: false,
            firstName: '',
            lastName: '',
            emailAddress: '',
            password: '',
            confirmPassword: '',
            // customers said to hide and stop using these fields
            // affiliation: '',
            // affiliationMemberName: '',
            isTermOfServiceAgreed: false,
            registrationErrorText: '',
            modalOpen: false,
            redirect: authenticationService.currentUserValue ? '/' : null, // redirect user to home if authorized,
            isRunningInsideMicrosoftTeams: false
        }
    }

    componentDidMount() {
        const isRunningInsideMicrosoftTeamsFromLocalStorage = localStorage.getItem('isRunningInsideMicrosoftTeams');
        const isRunningInsideMicrosoftTeams = isRunningInsideMicrosoftTeamsFromLocalStorage === 'true';

        this.setState({
            isRunningInsideMicrosoftTeams
        });
    }

    handleInputChange = async (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        await this.setState({ [name]: value });

        // enable signup button if all fields is not empty
        this.toggleSubmitButton();
    };
    // customers said to hide and stop using these fields
    // handleAffiliationChange = async (event) => {
    //   const target = event.target;
    //   const value = target.value;
    //
    //   await this.setState({ affiliation: value });
    //   // enable signup button if all fields is not empty
    //   this.toggleSubmitButton();
    // }

    handleCheckboxChange = async (event) => {
        await this.setState({ isTermOfServiceAgreed: !this.state.isTermOfServiceAgreed });
        // enable signup button if all fields is not empty
        this.toggleSubmitButton();
    };

    toggleSubmitButton = () => {
        if (!this.isAllFieldsFilled()) {
            !this.state.isDisabledButton && this.setState({ isDisabledButton: true });
        } else {
            this.state.isDisabledButton && this.setState({ isDisabledButton: false });
        }
    };

    isAllFieldsFilled = () => {
        // customers said to hide and stop using these fields
        // const { firstName, lastName, emailAddress, password, confirmPassword, affiliationMemberName, affiliation, isTermOfServiceAgreed} = this.state;
        const { firstName, lastName, emailAddress, password, confirmPassword, isTermOfServiceAgreed } = this.state;
        // return firstName && lastName && emailAddress && password && confirmPassword && affiliationMemberName && affiliation && isTermOfServiceAgreed;
        return firstName && lastName && emailAddress && password && confirmPassword && isTermOfServiceAgreed;
    };

    handleSubmit = async (event) => {
        this.setState({ isDisabledSignupButton: true });
        event.preventDefault();
        try {
            const namePattern = /^[a-zA-Z]+$/;
            const passwordPattern = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,32}$/;
            // customers said to hide and stop using these fields
            // const { firstName, lastName, emailAddress, password, confirmPassword, affiliationMemberName, affiliation } = this.state;
            const { firstName, lastName, emailAddress, password, confirmPassword } = this.state;
            // const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/


            if (!namePattern.test(firstName)) {
                throw new Error('No whitespace allowed in First Name')
            }
            else if (firstName.length < 3 || firstName.length > 32) {
                throw new Error('First name must be 3-32 characters long');
            }
            else if (!namePattern.test(lastName)) {
                throw new Error('No whitespace allowed in Last Name')
            }
            else if (lastName.length < 3 || lastName.length > 32) {
                throw new Error('Last name must be 3-32 characters long');
            }
            // else if (emailAddress.length < 3 || emailAddress.length > 256 || emailPattern.test(String(emailAddress).toLowerCase())) {
            else if (emailAddress.length < 3 || emailAddress.length > 256) {
                throw new Error('Invalid e-mail address');
            }

            else if (password.length < 6 || password.length > 32) {
                throw new Error('Password must be between 6-32 characters long');
            }
            //Passwords should contain at least one capital letter, one special character & one number
            else if (!passwordPattern.test(password)) {
                throw new Error('Password should contains a combination of uppercase and lowercase letter, special character and number are required');
            }
            else if (password !== confirmPassword) {
                throw new Error('Password and Confirm password must be identical');
            }


            this.setState({ registrationErrorText: '' });

            // customers said to hide and stop using these fields
            // const data = {firstName, lastName, emailAddress, password, affiliationMemberName, affiliation };
            const data = { firstName, lastName, emailAddress, password };
            const response = await userService.createUser(data);
            if (response && response.result === 'success') {
                const actionDate = trackingsService.getActionDateStringWithTimezone();
                trackingsService.silentRegistrationEventTracking(this.state.isRunningInsideMicrosoftTeams, emailAddress, actionDate);
                toast.info('Please check your email');
                this.props.history.push('/login');
            }
        } catch (error) {
            console.log('inside catch=', error)
            if (error === 'User is deactiavted') {
                this.onOpenModal();
            } else {
                this.setState({ registrationErrorText: error.message ? error.message : error });
            }
            console.log('done')
            // this.setState({registrationErrorText: error.message ? error.message : error});
            // this.onOpenModal();
            return null;
        }
    };
    onOpenModal = () => {
        this.setState({ modalOpen: true });
    };

    onCloseModal = () => {
        this.setState({ modalOpen: false });
    };

    handleYesClicked = async () => {

        try {
            //   if (this.state.emailAddress === this.state.deleteEmailAddressConfirmation) {

            // create and call await userService.reactivateUser(this.state.emailAddress);
            await userService.reactivateUser(this.state.emailAddress);
            await this.setState({ modalOpen: false });
            toast.info('Your account reactivated successfully!');
            authenticationService.logout();
            this.props.history.push('/login');
            //   } 

        } catch (error) {
            console.log(error);
            toast.error('Invalid operation!');
            return null;
        }
    }


    render() {
        if (this.state.redirect) {
            console.log('Redirect to home page');
            return <Redirect to={this.state.redirect} />
        }
        return (
            <>
                <div className="registration-container">
                    <div className="logo-left-section">
                        <Link to="/">
                            <div className="logo-wrapper">
                                <Logo />
                            </div>
                        </Link>
                    </div>
                    <div className="registration-section">
                        <div className="form-section">
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-container">
                                    <div className="registration-title">
                                        <Link to="/">
                                            <span className="material-icons">keyboard_backspace</span>
                                        </Link>
                                        <div>Sign Up to Get Started</div>
                                    </div>
                                    {this.state.registrationErrorText &&
                                        <div className="error-text registration-error">{this.state.registrationErrorText}</div>
                                    }
                                    <div className="username-section">
                                        <div className={"form-input mr-1" + (this.state.firstnameError ? ' error' : '')}>
                                            <label>
                                                <input type="text" name="firstName" autoComplete="off"
                                                    onChange={this.handleInputChange} required />
                                                <span className="placeholder">First Name</span>
                                            </label>
                                        </div>
                                        <div className={"form-input ml-1" + (this.state.lastnameError ? ' error' : '')}>
                                            <label>
                                                <input type="text" name="lastName" autoComplete="off"
                                                    onChange={this.handleInputChange} required />
                                                <span className="placeholder">Last Name</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className={"form-input" + (this.state.emailError ? ' error' : '')}>
                                        <label>
                                            <input type="email" name="emailAddress" autoComplete="off"
                                                onChange={this.handleInputChange} required />
                                            <span className="placeholder">Email address</span>
                                        </label>
                                    </div>
                                    <div className={"form-input" + (this.state.passwordError ? ' error' : '')}>
                                        <label>
                                            <input type="password" name="password" autoComplete="off"
                                                onChange={this.handleInputChange} required />
                                            <span className="placeholder">Password</span>
                                        </label>
                                    </div>
                                    <div className={"form-input" + (this.state.confirmPasswordError ? ' error' : '')}>
                                        <label>
                                            <input type="password" name="confirmPassword" autoComplete="off"
                                                onChange={this.handleInputChange} required />
                                            <span className="placeholder">Confirm Password</span>
                                        </label>
                                    </div>
                                    {/*<div className="affiliation-field">*/}
                                    {/*<div className="label">Party Affiliation</div>*/}
                                    {/*<div className="radio-options">*/}
                                    {/*<div className="radio-container option">*/}
                                    {/*<label className="checkbox-switch">*/}
                                    {/*<input type="radio" name="affiliation" onChange={this.handleAffiliationChange} value="democrat" /><div className="checkbox-slider"></div>*/}
                                    {/*</label>*/}
                                    {/*<div className="checkbox-label">Democrat</div>*/}
                                    {/*</div>*/}
                                    {/*<div className="radio-container option">*/}
                                    {/*<label className="checkbox-switch">*/}
                                    {/*<input type="radio" name="affiliation" onChange={this.handleAffiliationChange} value="republican" />*/}
                                    {/*<div className="checkbox-slider"></div>*/}
                                    {/*</label>*/}
                                    {/*<div className="checkbox-label">Republican</div>*/}
                                    {/*</div>*/}
                                    {/*</div>*/}
                                    {/*</div>*/}
                                    {/*<div className={"form-input" + (this.state.emailError ? ' error' : '')} >*/}
                                    {/*<label>*/}
                                    {/*<input  type="text" name="affiliationMemberName" autoComplete="off" onChange={this.handleInputChange} required />*/}
                                    {/*<span className="placeholder">Member or Committee Affiliation</span>*/}
                                    {/*</label>*/}
                                    {/*</div>*/}
                                    <div className="affiliation-field">
                                        <div className="radio-options">
                                            <div className="radio-container option">
                                                <label className="checkbox-switch">
                                                    <input type="checkbox" name="termsAndCondition"
                                                        onChange={this.handleCheckboxChange} />
                                                    <div className="checkbox-slider"></div>
                                                </label>
                                                <div className="checkbox-label">I agree to the Markup Redline <Link
                                                    to="/terms-of-service" target="_blank"
                                                    className="red-link">Terms &amp; Conditions</Link></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions">
                                        <button type="submit"
                                            className={"button blue-gradient w-100" + (this.state.isDisabledButton ? ' disabled' : '')}
                                            disabled={this.state.isDisabledButton ? 'disabled' : ''}>Create Account
                                        </button>
                                    </div>
                                    <div className="affiliation-field">
                                        <div className="checkbox-label">If you don't recieve a registration email, please <Link
                                            to="/help" target="_blank"
                                            className="red-link">Contact Us
                                        </Link>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="link-section">
                        <div>
                            Already have an account? <Link to="/login" className="login-link">Login</Link>
                        </div>
                    </div>
                </div >
                <Modal
                    open={this.state.modalOpen}
                    onClose={this.onCloseModal}
                    center
                    showCloseIcon={false}
                    classNames={{
                        overlay: 'custom-modal-overlay',
                        modal: 'custom-modal',
                    }} >
                    <div className="delete-modal-content">
                        <div className="modal-title">Email is already registered with us, Do you want to continue with the same email? </div>
                        <div className="action-buttons">
                            <div style={{ width: "50%" }}>
                                <button type="button" className="button button-transparent w-100" style={{ marginRight: '5px' }} onClick={this.onCloseModal}>No</button>
                            </div>
                            <div className="popupBtn" style={{ width: "50%" }}>
                                <button type="button" className="button button-transparent w-100" onClick={this.handleYesClicked}>Yes</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>

        );
    }
}

export default RegistrationPage;
