import * as moment from 'moment-timezone';

import {authHeader, handleResponse} from "../_helpers";

const EVENT_TYPES = [
    {
        label: 'Login Events',
        value: 'Login'
    },
    {
        label: 'Registration Events',
        value: 'Registration'
    },
    {
        label: 'Redirect Events',
        value: 'Redirect'
    }
];

export const trackingsService = {

    getEventTypes() {
        return EVENT_TYPES;
    },

    getActionDateStringWithTimezone() {
        const tz = moment.tz.guess();
        const date = moment();

        return date.tz(tz).format();
    },

    // methods that create events, but never fail with error

    async silentLoginEventTracking(isMicrosoftTeams, emailAddress, actionDate) {
        try {
            await this.trackLoginEvent(isMicrosoftTeams, emailAddress, actionDate);
        } catch (err) {
            console.error(`Error while tracking login event - ${err}`)
        }
    },


    async silentRegistrationEventTracking(isMicrosoftTeams, emailAddress, actionDate) {
        try {
            await this.trackRegistrationEvent(isMicrosoftTeams, emailAddress, actionDate)
        } catch (err) {
            console.error(`Error while tracking registration event - ${err}`);
        }
    },

    async silentRedirectEventTracking(isMicrosoftTeams, emailAddress, route, actionDate) {
        try {
            await this.trackRedirectEvent(isMicrosoftTeams, emailAddress, route, actionDate);
        } catch (err) {
            console.error(`Error while tracking redirect event - ${err}`);
        }
    },

    // methods that crate events and should be handled with try/catch

    trackLoginEvent(isMicrosoftTeams, emailAddress, actionDate) {
        const body = {
            isMicrosoftTeams,
            emailAddress,
            actionDate
        };

        const requestOptions = {
            method: 'POST',
            headers: Object.assign({'Content-Type': 'application/json'}),
            body: JSON.stringify(body)
        };

        return fetch(`${process.env.REACT_APP_API_URL}/trackings/track-login-event`, requestOptions).then(handleResponse);
    },

    trackRegistrationEvent(isMicrosoftTeams, emailAddress, actionDate) {
        const body = {
            isMicrosoftTeams,
            emailAddress,
            actionDate
        };

        const requestOptions = {
            method: 'POST',
            headers: Object.assign({'Content-Type': 'application/json'}),
            body: JSON.stringify(body)
        };

        return fetch(`${process.env.REACT_APP_API_URL}/trackings/track-registration-event`, requestOptions).then(handleResponse);
    },

    trackRedirectEvent(isMicrosoftTeams, emailAddress, route, actionDate) {
        const body = {
            isMicrosoftTeams,
            emailAddress,
            route,
            actionDate
        };

        const requestOptions = {
            method: 'POST',
            headers: Object.assign({'Content-Type': 'application/json'}),
            body: JSON.stringify(body)
        };

        return fetch(`${process.env.REACT_APP_API_URL}/trackings/track-redirect-event`, requestOptions).then(handleResponse);
    },

    getRegistrationEvents(from, to, page = 1) {
        const requestOptions = {
            method: 'GET',
            headers: Object.assign({'Content-Type': 'application/json', ...authHeader()}),
        };

        return fetch(`${process.env.REACT_APP_API_URL}/trackings/registration-events?page=${page}&from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}`, requestOptions).then(handleResponse);
    },

    getLoginEvents(from, to, page = 1) {
        const requestOptions = {
            method: 'GET',
            headers: Object.assign({'Content-Type': 'application/json', ...authHeader()}),
        };

        return fetch(`${process.env.REACT_APP_API_URL}/trackings/login-events?page=${page}&from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}`, requestOptions).then(handleResponse);
    },

    getRedirectEvents(from, to, page = 1) {
        const requestOptions = {
            method: 'GET',
            headers: Object.assign({'Content-Type': 'application/json', ...authHeader()}),
        };

        return fetch(`${process.env.REACT_APP_API_URL}/trackings/redirect-events?page=${page}&from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}`, requestOptions).then(handleResponse);
    }
};
