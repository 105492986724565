const versionTitles = {
  ats: 'Agreed to Senate',
  cps: 'Considered and Passed Senate',
  eah: 'Engrossed amendment House',
  eas: 'Engrossed amendment Senate',
  eh: 'Engrossed in House',
  enr: 'Enrolled Bill',
  ih: 'Introduced in House',
  is: 'Introduced in Senate',
  pch: 'Placed on Calendar House',
  pcs: 'Placed on Calendar Senate',
  rch: 'Reference Change House',
  rcs: 'Reference Change Senate',
  rds: 'Received in Senate',
  rfh: 'Referred in House',
  rfs: 'Referred in Senate',
  rh: 'Reported in House',
  rs: 'Reported in Senate',
  es: 'Engrossed in Senate'
};

export function getVersionTitle(version) {
  return versionTitles[version] || '('+version+')'
}