import React, {Component} from 'react';
import Select from 'react-select'
import './USCodePage.css';
import queryString from 'query-string';
import EmptyResults from '../_components/EmptyResults/EmptyResults';
import Header from '../_components/Header/Header';
import Footer from '../_components/Footer/Footer';
import {usCodeService} from '../_services/us-code.service';
import {mappingsService} from '../_services/mappings.service';
import PlaceholderShimmer from '../_components/PlaceholderShimmer/PlaceholderShimmer';
import {searchContainerSelectStyles, connectCFRSelectStyles} from '../CFRSearchPage/select-config';
import {cfgService} from '../_services/config.service';
import {convertResponseToValueLabelForYear, convertResponseToValueLabelForTitle} from '../_helpers/handleConfigResponse';

var Constant = require('../_constants');
class USCodePage extends Component {

    years = [];
    titles = [];

    constructor(props) {
        super(props);
        this.state = {
            isRunningInsideMicrosoftTeams: false,
            yearList: [],
            titleList: [],
            titleListCFR : [],
            title: null,
            year: null,
            section: null,
            isFetching: false,
            // handle disable/enable buttons/selects state
            searchButtonDisabled: true,
            connectButtonDisabled: true,
            cfrPartSelectDisabled: true,
            // used for display purposes
            usCodeData: null,
            // these props are used to store values from selects
            connectedCfrTitleNumber: null,
            connectedCfrPartNumber: null,
            // this property is needed to nullify part select when new cfr title is selected
            connectedCfrPartValueForSelect: null,
            // these props hold connected cfrTitles and cfrParts
            cfrTitles: [],
            cfrParts: [],
            // this is crf parts that should be displayed when particular cfr title is selected
            cfrPartsToDisplayInSelect: []
        };
    }

    async componentDidMount() {
        const isRunningInsideMicrosoftTeamsFromLocalStorage = localStorage.getItem('isRunningInsideMicrosoftTeams');
        const isRunningInsideMicrosoftTeams = isRunningInsideMicrosoftTeamsFromLocalStorage === 'true';

        var yearList = await cfgService.getYears(Constant.CodeType_USCode_Year_String);
        var titlesList = await cfgService.getTitles(Constant.CodeType_USCode_Title_String);
        this.setState({yearList:(convertResponseToValueLabelForYear(yearList)).reverse()})
        this.setState({titleList:(convertResponseToValueLabelForTitle(titlesList))})   


        // this API Call is just to send data to mappingsService.getcftTitles and not used anywhere else
        var CFRTitles = await cfgService.getTitles(Constant.CodeType_CFR_Title_String);
        this.setState({titleListCFR:(convertResponseToValueLabelForTitle(CFRTitles))})  

        const values = queryString.parse(this.props.location.search);
        
        await this.setState({
            isRunningInsideMicrosoftTeams,
            year: values.usCodeYear ? +values.usCodeYear : this.state.yearList[0].value,
            title: values.usCodeTitle ? +values.usCodeTitle : this.state.titleList[0].value,
            section: values.usCodeSection ? values.usCodeSection : null
        });

        const searchButtonDisabled = this.shouldDisableSearchButton();

        this.setState({
            searchButtonDisabled
        });
    }

    onValueEntered = async (controlName, event) => {
        let value = null;
        // set default value for cfrPartsToDisplayInSelect (it can be either current one, or if we selected connectedCfrTitleNumber then a new one)
        let cfrPartsToDisplayInSelect = this.state.cfrPartsToDisplayInSelect;
        let connectedCfrPartNumber;
        let connectedCfrPartValueForSelect;

        // TODO: clarify types of these fields, 'cause not all of them should be integer only
        if (controlName === 'section') {
            const sectionNumber = parseInt(event.target.value, 10);
            if (!Number.isNaN(sectionNumber) && sectionNumber >= 0) {
                value = sectionNumber
            }
        } else if (controlName === 'year') {
            value = event.value;
        } else if (controlName === 'title') {
            value = event.value;
        } else if (controlName === 'connectedCfrPartNumber') {
            value = event.value;
            // we need it to nullify it when new connect cfr title is selected. I know that it is a bullshit, but I don't have time to implement it the right way
            connectedCfrPartNumber = value;
            connectedCfrPartValueForSelect = cfrPartsToDisplayInSelect.find(part => part.value === value);
        } else if (controlName === 'connectedCfrTitleNumber') {
            value = event.value;
            // only if we selected cfr title number then it makes sense to get related cfr parts
            cfrPartsToDisplayInSelect = this.getCfrPartsBySelectedCfrTitle(value);
            // nullify part for newly selected title
            connectedCfrPartValueForSelect = null;
            // we need it to nullify it when new connect cfr title is selected I know that it is a bullshit, but I don't have time to implement it the right way
            connectedCfrPartNumber = null;
        }

        // we need always to to it before shouldDisableSearchButton, shouldDisableConnectButton, shouldSelectCfrPartBeDisabled
        await this.setState({
            [controlName]: value,
            connectedCfrPartValueForSelect,
            connectedCfrPartNumber,
            cfrPartsToDisplayInSelect
        });

        const searchButtonDisabled = this.shouldDisableSearchButton();
        const connectButtonDisabled = this.shouldDisableConnectButton();
        const cfrPartSelectDisabled = this.shouldSelectCfrPartBeDisabled();

        this.setState({
            searchButtonDisabled,
            connectButtonDisabled,
            cfrPartSelectDisabled
        });
    };

    goToView = () => {
        const {title, section, year} = this.state;

        let url = null;

        let yearValue = year === 'Most recent' ? 2018 : year;

        if (section) {
            url = `/us-code-view?title=${title}&section=${section}&year=${yearValue}&isView=true`;
        } else {
            url = `/us-code-view?title=${title}&year=${yearValue}&isView=true`
        }

        this.props.history.push(url);
    };

    gotToConnect = () => {
        const {year, section, title, connectedCfrPartNumber, connectedCfrTitleNumber} = this.state;

        let yearValue = year === 'Most recent' ? 2018 : year;

        const connectedCfrYear = 2018;
        let url = `/us-code-view?isConnect=true&usCodeYear=${yearValue}&usCodeSection=${section}&usCodeTitle=${title}&connectedCfrTitleNumber=${connectedCfrTitleNumber}&connectedCfrPartNumber=${connectedCfrPartNumber}&connectedCfrYear=${connectedCfrYear}`;

        this.props.history.push(url);
    };

    search = async () => {
        try {
            let {year, section, title} = this.state;
            let cfrTitles = [];
            let cfrParts = [];

           

            this.setState({
                isFetching: true
            });

            const usCodeData = await usCodeService.getUsCode(title, year, section);

            if (usCodeData.isPresent) {
                const response = await mappingsService.getCFRMappings(title, section);
                cfrTitles = this.getCftTitles(response.cfrTitles);
                cfrParts = this.getCfrParts(response.groupedCfrParts);
            }

            this.setState({
                isFetching: false,
                cfrParts,
                cfrTitles,
                usCodeData: {...usCodeData, year, section, title}
            });
        } catch (err) {
            console.error(err);

            this.setState({
                isFetching: false
            });
        }
    };

    isCfrMappingPresent = () => {
        const {cfrTitles, cfrParts} = this.state;

        return cfrTitles.length && cfrParts.length;
    };

    // get related cfr parts by given cfr title
    getCfrPartsBySelectedCfrTitle = (cfrTitle) => {
        const {cfrParts} = this.state;
        const target = cfrParts.find(cfrPart => cfrPart.cfrTitle === cfrTitle);

        return target.cfrParts;
    };

    getCfrParts = (cfrParts = []) => {
        return mappingsService.getCfrParts(cfrParts);
    };

    getCftTitles = (cfrTitles = []) => {
        return mappingsService.getCftTitles(cfrTitles, this.state.titleListCFR);
    };

    getSelectedTitleLabel = (titleValue) => {
        const target = this.state.titleList.find(title => title.value === titleValue);

        return target ? target.label : 'Default label';
    };

    shouldDisableSearchButton = () => {
        const {title, year, section} = this.state;

        return !(title && year && section);
    };

    shouldDisableConnectButton = () => {
        const {connectedCfrTitleNumber, connectedCfrPartNumber} = this.state;

        return !connectedCfrTitleNumber || !connectedCfrPartNumber;
    };

    shouldSelectCfrPartBeDisabled = () => {
        const {connectedCfrTitleNumber} = this.state;

        return !connectedCfrTitleNumber;
    };

    render() {
        const {
            isFetching,
            usCodeData,
            section,
            year,
            title,
            searchButtonDisabled,
            cfrTitles,
            connectButtonDisabled,
            cfrPartsToDisplayInSelect,
            cfrPartSelectDisabled,
            connectedCfrPartValueForSelect,
            isRunningInsideMicrosoftTeams
        } = this.state;

        const currentRoute = this.props.location.pathname;

        return (
            <div>
                {!isRunningInsideMicrosoftTeams && <Header currentRoute={currentRoute} shouldLogoBeLight={false}/>}
                <div className="search-section blue-gradient">
                    <div className="year-select">
                        <Select
                            menuPortalTarget={document.body}
                            name="year"
                            onChange={(event) => this.onValueEntered('year', event)}
                            options={this.state.yearList}
                            value={this.state.yearList.find(currentYear => currentYear.value === year)}
                            placeholder={'Year'}
                            defaultValue={this.state.yearList[0]}
                            styles={searchContainerSelectStyles}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                        />
                       
                    </div>
                    <div className="title-select">
                        <Select
                            menuPortalTarget={document.body}
                            onChange={(event) => this.onValueEntered('title', event)}
                            options={this.state.titleList}
                            value={this.state.titleList.find(currentTitle => currentTitle.value === title)}
                            placeholder={'Title Number'}
                            styles={searchContainerSelectStyles}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                        />
                    </div>
                    <div className="search-input section">
                        <input
                            type="text"
                            pattern="[0-9]*"
                            value={section || ''}
                            placeholder="Section number"
                            autoComplete="off"
                            onChange={(event) => this.onValueEntered('section', event)}
                            className="text-field text-field-input"/>
                    </div>
                    <div className="search-button">
                        <button className={`button ${searchButtonDisabled ? 'not-allowed' : ''}`} onClick={this.search}
                                disabled={searchButtonDisabled}>
                            <span className="material-icons">search</span>Search
                        </button>
                    </div>
                </div>
                <div>
                    {
                        isFetching && (
                            <div>
                                <PlaceholderShimmer/>
                                <div className="lds-dual-ring-container">
                                    <div className="lds-dual-ring"></div>
                                </div>
                            </div>
                        )
                    }
                    {
                        (!isFetching && usCodeData && usCodeData.isPresent) &&
                        <>
                            <div className="us-code-container">
                                <div className="description-container">
                                    <div>
                                        <span
                                            className="description">United States Code, {this.getSelectedTitleLabel(usCodeData.title)}</span>
                                    </div>
                                    <div>
                                        <span className="chips">{usCodeData.year} Edition</span>
                                        <span className="chips">Section {usCodeData.section}</span>
                                    </div>
                                </div>
                                <div className="button-container">
                                    <button className="button button-blue" onClick={this.goToView}>View
                                    </button>
                                </div>
                            </div>
                            {
                                this.isCfrMappingPresent() ? (
                                    <div className="connected-cfr-container">
                                        <div className="description">
                                            <span>Connected CFRs (Parallel Table is revised as of Jan 1, 2019)</span>
                                        </div>
                                        <div className="controls-container">
                                            <div className="cfr-title-number">
                                                <Select
                                                    placeholder={'Title Number'}
                                                    onChange={(event) => this.onValueEntered('connectedCfrTitleNumber', event)}
                                                    options={cfrTitles}
                                                    styles={connectCFRSelectStyles}
                                                    components={{
                                                        IndicatorSeparator: () => null
                                                    }}
                                                />
                                            </div>
                                            <div className="cfr-part-number">
                                                <Select
                                                    placeholder={'Part Number'}
                                                    isDisabled={cfrPartSelectDisabled}
                                                    onChange={(event) => this.onValueEntered('connectedCfrPartNumber', event)}
                                                    styles={connectCFRSelectStyles}
                                                    options={cfrPartsToDisplayInSelect}
                                                    value={connectedCfrPartValueForSelect}
                                                    components={{
                                                        IndicatorSeparator: () => null
                                                    }}
                                                />
                                            </div>
                                            <div className="connect-button">
                                                <button
                                                    disabled={connectButtonDisabled}
                                                    className={`button button-blue ${connectButtonDisabled ? 'not-allowed' : ' '}`}
                                                    onClick={this.gotToConnect}>Connect
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ) : null
                            }
                        </>
                    }
                    {
                        !isFetching && usCodeData && !usCodeData.isPresent &&
                        <EmptyResults
                            text={'Markup Redline™ only provides document and information made available by the Government Publishing Office as updated daily. This document has not been published by the GPO for the year you searched.'}/>
                    }
                    {
                        !isFetching && !usCodeData &&
                        <EmptyResults text={'Select year, title number and section number.'}/>
                    }
                </div>
                {!isRunningInsideMicrosoftTeams && <Footer/>}
            </div>
        );
    }
}

export default USCodePage;
