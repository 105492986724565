import React, { Component } from 'react'
// import { PDF_VIEWER } from '../configs'
import './PdfViewer.css';
import { Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { searchPlugin } from '@react-pdf-viewer/search';
import '@react-pdf-viewer/search/lib/styles/index.css';





function PdfViewer(props){

    const searchPluginInstance = searchPlugin();
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    return (
        
        <div className="pdf-viewer" content="Loading">
          
          
        <Viewer
        fileUrl={`${props.file}` }
        plugins={[
            searchPluginInstance,
            defaultLayoutPluginInstance
        ]}
        defaultScale={SpecialZoomLevel.PageWidth}
        />

            
        </div>
    )}

export default PdfViewer;
