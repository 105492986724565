import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import Header from '../_components/Header/Header';
import Footer from '../_components/Footer/Footer';
import PDFViewer from '../_components/PdfViewer/PdfViewer';
import {documentService} from '../_services/document.service';
import DownloadFile from '../_components/DownloadFile/DownloadFile';

import './SideBySidePage.css';
import PlaceholderShimmer from '../_components/PlaceholderShimmer/PlaceholderShimmer';
import {getVersionTitle} from '../_helpers/documents';
import {getIssuedDate} from '../_helpers/date';
import EmptyResults from "../_components/EmptyResults/EmptyResults";
import DocumentList from "../SearchPage/DocumentsList";
import Pagination from "rc-pagination";
import Modal from "react-responsive-modal";
import ReactTooltip from 'react-tooltip';

class SideBySidePage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isRunningInsideMicrosoftTeams: false,
            searchQuery: this.props.location?.search,
            packageId: null,
            comparedPackageId: null,
            selectedPackageId: null,
            comparedSelectedPackageId: null,
            document: null,
            comparedDocument: null,
            fileLink: null,
            comparedFileLink: null,
            isLoading: false,
            isComparedLoading: false,
            redirectUrl: null,
            documentVersions: [],
            isVersionsShow: false,
            isComparedVersionsShow: false,
            downloadPackageId: null,
            comparedDownloadPackageId: null,
            // prop to handle modal
            modalOpen: false,
            // props for bill search
            bills: [],
            currentPage: 0,
            billsCount: 0,
            value: '',
            areBillsFetching: false,
            // props to control tooltips
            shouldDisplayShorterTitleForFirstDocument: false,
            shouldDisplayShorterDescriptionForFirstDocument: false,
            shouldDisplayShorterTitleForSecondDocument: false,
            shouldDisplayShorterDescriptionForSecondDocument: false
        };
    }

    async componentDidMount() {
        const isRunningInsideMicrosoftTeamsFromLocalStorage = localStorage.getItem('isRunningInsideMicrosoftTeams');
        const isRunningInsideMicrosoftTeams = isRunningInsideMicrosoftTeamsFromLocalStorage === 'true';

        await this.setState({
            isRunningInsideMicrosoftTeams,
            isLoading: true,
            packageId: this.props.match?.params?.packageId
        });
        documentService.getDocument(this.state.packageId)
            .then(response => {
                this.setState({
                    fileLink: response.link,
                    document: response.document,
                    comparedDocument: response.document,
                    downloadPackageId: response.document.packageId,
                    isLoading: false
                });

                this.updateDocumentTitleAndDescriptionFlags(response.document, response.document);
            });
        this.getDocumentVersions();
    }

    updateDocumentTitleAndDescriptionFlags = (document, comparedDocument) => {
        const titleFlags = this.getDocumentsTitleFlags(document, comparedDocument);
        const descriptionFlags = this.getDocumentsDescriptionFlags(document, comparedDocument);

        this.setState({
            ...titleFlags,
            ...descriptionFlags
        });
    };

    getDocumentsDescriptionFlags = (document, comparedDocument) => {
        let shouldDisplayShorterDescriptionForFirstDocument = false;
        let shouldDisplayShorterDescriptionForSecondDocument = false;

        if(document.description.length > comparedDocument.description.length) {
            shouldDisplayShorterDescriptionForFirstDocument = true;
            shouldDisplayShorterDescriptionForSecondDocument = false;
        } else if(document.description.length < comparedDocument.description.length) {
            shouldDisplayShorterDescriptionForSecondDocument = true;
            shouldDisplayShorterDescriptionForFirstDocument = false;
        }

        return Object.assign({}, {
            shouldDisplayShorterDescriptionForSecondDocument,
            shouldDisplayShorterDescriptionForFirstDocument
        })
    };

    getDocumentsTitleFlags = (document, comparedDocument) => {
        let shouldDisplayShorterTitleForFirstDocument = false;
        let shouldDisplayShorterTitleForSecondDocument = false;

        if(document.title.length > comparedDocument.title.length) {
            shouldDisplayShorterTitleForFirstDocument = true;
            shouldDisplayShorterTitleForSecondDocument = false;
        } else if(document.title.length < comparedDocument.title.length) {
            shouldDisplayShorterTitleForSecondDocument = true;
            shouldDisplayShorterTitleForFirstDocument = false;
        }

        return Object.assign({}, {
            shouldDisplayShorterTitleForFirstDocument,
            shouldDisplayShorterTitleForSecondDocument
        });
    };

    getShortDescription = (description, shorterDescriptionLength) => {
        return `${this.getShortString(description, shorterDescriptionLength)}...`;
    };

    getShortTitle = (title, shorterTitleLength) => {
        return `${this.getShortString(title, shorterTitleLength)}...`;
    };

    getShortString = (str, sliceLength) => {
        return str.slice(0, sliceLength);
    };

    getDocumentVersions = () => {
        documentService.getDocumentVersions(this.state.packageId)
            .then((response) => {
                this.setState({
                    documentVersions: response.documents,
                    selectedPackageId: this.state.packageId,
                    isComparedVersionsShow: true
                });
            });
    };

    showVersions = async () => {
        await this.setState({isVersionsShow: !this.state.isVersionsShow});
        if (this.state.comparedFileLink) {
            await this.setState({isComparedVersionsShow: this.state.isVersionsShow});
        }
    };

    showSearch = async () => {
        this.setState({redirectUrl: '/search' + this.state.searchQuery});
    };

    openPage = (page) => {
        if (page === 'redline' && this.state.comparedPackageId) {
            page = page + '/' + this.state.comparedPackageId;
        }
        this.setState({redirectUrl: '/view/' + this.state.packageId + '/' + page + this.state.searchQuery})
    };


    selectPackage = (packageId) => {
        (packageId !== this.state.comparedPackageId) && this.setState({selectedPackageId: packageId});
    };

    showSelectedPackage = async () => {
        if (this.state.selectedPackageId === this.state.packageId) {
            this.setState({isVersionsShow: false});
        } else {
            await this.setState({
                packageId: this.state.selectedPackageId,
                isLoading: true
            });
            this.state.packageId === this.state.comparedSelectedPackageId && this.setState({comparedSelectedPackageId: this.state.comparedPackageId});
            this.props.history.push('/view/' + this.state.packageId + '/side-by-side' + this.state.searchQuery);
            const result = await documentService.getDocument(this.state.packageId);
            this.setState({
                fileLink: result.link,
                document: result.document,
                isVersionsShow: false,
                isLoading: false,
                downloadPackageId: result.document.packageId
            });

            this.updateDocumentTitleAndDescriptionFlags(result.document, this.state.comparedDocument);
        }
    };

    //TODO:: refactor code into separate common function, when have time, DO NOT USE DUPLICATED CODE!

    selectComparedPackage = (packageId) => {
        // this.setState({comparedSelectedPackageId: packageId});
        this.state.packageId !== packageId && this.setState({comparedSelectedPackageId: packageId});
    };

    showComparedSelectedPackage = async () => {
        if (!this.state.comparedSelectedPackageId && this.state.comparedSelectedPackageId === this.state.comparedPackageId) {
            this.setState({isComparedVersionsShow: true});
        } else {
            await this.setState({
                isComparedVersionsShow: false,
                comparedPackageId: this.state.comparedSelectedPackageId,
                isComparedLoading: true
            });
            this.state.comparedPackageId === this.state.selectedPackageId && this.setState({selectedPackageId: this.state.packageId});
            const result = await documentService.getDocument(this.state.comparedPackageId);
            this.setState({
                comparedFileLink: result.link,
                comparedDocument: result.document,
                isComparedLoading: false,
                comparedDownloadPackageId: result.document.packageId // use with await???
            });

            this.updateDocumentTitleAndDescriptionFlags(this.state.document, result.document);
        }
    };

    clearBillsData = () => {
        this.setState({
            currentPage: 0,
            billsCount: 0,
            value: '',
            areBillsFetching: false,
            documentId: null,
            bills: []
        });
    };

    onlBillClicked = async (packageId) => {
        // comparedSelectedPackageId is used in html, os I decided that I need to set it here as well
        await this.setState({
            comparedSelectedPackageId: packageId
        });

        await this.setState({
            isComparedVersionsShow: false,
            comparedPackageId: packageId,
            isComparedLoading: true
        });

        // I don't know what it is for, but I decided to copy it for the sake of it
        this.state.comparedPackageId === this.state.selectedPackageId && this.setState({selectedPackageId: this.state.packageId});
        this.onCloseModal();
        const result = await documentService.getDocument(this.state.comparedPackageId);

        this.setState({
            comparedFileLink: result.link,
            comparedDocument: result.document,
            isComparedLoading: false,
            comparedDownloadPackageId: result.document.packageId
        });

        this.updateDocumentTitleAndDescriptionFlags(this.state.document, result.document);

        // this.onCloseModal();
    };

    onBillSearch = () => {
        this.onOpenModal();
    };

    onCloseModal = () => {
        this.setState({
            modalOpen: false
        });
    };

    onOpenModal = async () => {
        // clear the data that was entered before opening the modal
        await this.clearBillsData();

        this.setState({
            modalOpen: true
        });
    };

    getBills = async () => {
        if (this.state.value) {
            this.setState({areBillsFetching: true});

            const result = await documentService.getDocuments(this.state.value, this.state.currentPage);

            this.setState({areBillsFetching: false});
            this.setState({
                bills: result.documents || [],
                billsCount: result.documentsCount || 0
            })
        }
    };

    handleChangeSearch = (event) => {
        this.setState({value: event.target.value});
    };

    handleSearchKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (this.state.value) {
                this.getBills();
            }
        }
    };

    searchClick = async () => {
        await this.setState({currentPage: 1});
        this.getBills();
    };

    onPageChange = async (page) => {
        await this.setState({currentPage: page});
        this.getBills();
    };

    render() {
        const {
            document,
            comparedDocument,
            shouldDisplayShorterTitleForFirstDocument,
            shouldDisplayShorterDescriptionForFirstDocument,
            shouldDisplayShorterTitleForSecondDocument,
            shouldDisplayShorterDescriptionForSecondDocument
        } = this.state;
        const currentRoute = this.props.location.pathname;

        if (this.state.redirectUrl) {
            return <Redirect to={this.state.redirectUrl}/>
        }
        return (
            <div>
                {!this.state.isRunningInsideMicrosoftTeams &&
                <Header currentRoute={currentRoute} isSearchHidden activeMenuItem='3' shouldLogoBeLight={false}/>}

                <div className="viewer-section blue-gradient">
                    <div className="back-button" onClick={this.showSearch}>
                        <span className="material-icons">keyboard_backspace</span>
                        <span className="back-text">Back</span>
                    </div>
                    <div className="viewer-section-buttons">
                        <button className="viewer-section-button left-rounded"
                                onClick={() => this.openPage('view')}>View
                        </button>
                        <button className="viewer-section-button no-left-right-borders active">Side by side</button>
                        <button className="viewer-section-button right-rounded"
                                onClick={() => this.openPage('redline')}>Redline
                        </button>
                    </div>
                    <div className="change-version-button">
                        <button className="button" onClick={this.showVersions}><span
                            className="material-icons">{this.state.isVersionsShow ? 'cancel' : 'update'}</span>{this.state.isVersionsShow ? 'Cancel' : 'Change version'}
                        </button>
                    </div>
                </div>

                <div className='side-by-side-container'>
                    <div className="side-by-side-viewer view-separator">
                        {document &&
                        <div className="document-item-header">
                            <div className="document-details">
                                {
                                    shouldDisplayShorterTitleForFirstDocument
                                        ? (
                                            <>
                                                <ReactTooltip
                                                    id="documentTitleTooltip"
                                                />
                                                <h3
                                                    data-tip={document.title}
                                                    data-multiline={true}
                                                    data-for="documentTitleTooltip"
                                                    data-class="title-tooltip"
                                                    data-arrow-color="#266ea1"
                                                >{this.getShortTitle(document.title, comparedDocument.title.length)}</h3>
                                            </>
                                        )
                                        : (
                                            <h3>{document.title}</h3>
                                        )
                                }
                                {
                                    shouldDisplayShorterDescriptionForFirstDocument
                                        ? (
                                            <>
                                                <ReactTooltip
                                                    id="documentDescriptionTooltip"
                                                />
                                                <div
                                                    data-tip={document.description}
                                                    data-multiline={true}
                                                    data-for="documentDescriptionTooltip"
                                                    className="document-description"
                                                    data-class="title-tooltip"
                                                    data-arrow-color="#266ea1"
                                                >{this.getShortDescription(document.description, comparedDocument.description.length)}</div>
                                            </>
                                        )
                                        : (
                                            <div className="document-description">{document.description}</div>
                                        )
                                }
                                <div className="document-meta-tags">
                                    <span className="meta-tag"
                                          style={{"textTransform": "uppercase"}}>{document.type}.{document.number}</span>
                                    <span className="meta-tag">{getVersionTitle(document.version)}</span>
                                    <span className="meta-tag">{getIssuedDate(document.issuedAt)}</span>
                                </div>
                            </div>
                            <div className="document-download-button">
                                <div className="download-icon-wrapper">
                                    <DownloadFile
                                        icon="word"
                                        id="downloadFile"
                                        packageId={this.state.downloadPackageId}
                                        type={'package'}
                                    />
                                </div>
                            </div>
                        </div>
                        }
                        {!this.state.isLoading && this.state.fileLink &&
                        <div className="viewer">
                            {this.state.isVersionsShow &&
                            // TODO:: move this part to separate component
                            <div className="versions-container">
                                <div className="version-header">
                                    <div className="version-text">
                                        <span>{this.state.documentVersions.length}</span> Versions Available
                                    </div>
                                </div>
                                <div className="versions">
                                    {this.state.documentVersions.map(document => {
                                        const disabled = (document.packageId === this.state.comparedPackageId);
                                        const selected = (document.packageId === this.state.selectedPackageId);
                                        let classes = 'version';
                                        disabled && (classes = classes + ' disabled');
                                        selected && (classes = classes + ' selected blue-gradient');
                                        return (
                                            <div onClick={() => this.selectPackage(document.packageId)}
                                                 className={classes} key={document.packageId}>
                                                <div className="version-icon">
                                                    <span
                                                        className="material-icons">{disabled || selected ? 'check_circle' : 'radio_button_unchecked'}</span>
                                                </div>
                                                <div className="details">
                                                    <div className="name">{getVersionTitle(document.version)}</div>
                                                    <div
                                                        className="issued-at">{getIssuedDate(document.issuedAt)}</div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                    {this.state.documentVersions.length > 1 &&
                                    <button onClick={this.showSelectedPackage}
                                            className="button blue-gradient view-document-button">View</button>
                                    }
                                </div>
                            </div>
                            }
                            <PDFViewer file={this.state.fileLink}/>
                        </div>
                        }
                        {this.state.isLoading &&
                        <div>
                            <PlaceholderShimmer/>
                            <div className="lds-dual-ring-container">
                                <div className="lds-dual-ring"></div>
                            </div>
                        </div>
                        }
                    </div>


                    <div className="side-by-side-viewer">
                        {comparedDocument &&
                        <div className="document-item-header">
                            <div className="document-details">
                                {
                                    shouldDisplayShorterTitleForSecondDocument
                                        ? (
                                            <>
                                                <ReactTooltip
                                                    id="comparedDocumentTitleTooltip"
                                                />
                                                <h3
                                                    data-tip={comparedDocument.title}
                                                    data-multiline={true}
                                                    data-for="comparedDocumentTitleTooltip"
                                                    data-class="title-tooltip"
                                                    data-arrow-color="#266ea1"
                                                >{this.getShortTitle(comparedDocument.title, document.title.length)}</h3>
                                            </>
                                        )
                                        : (
                                            <h3>{comparedDocument.title}</h3>
                                        )
                                }
                                {
                                    shouldDisplayShorterDescriptionForSecondDocument
                                        ? (
                                            <>
                                                <ReactTooltip
                                                    id="comparedDocumentDescription"
                                                />
                                                <div
                                                    data-tip={comparedDocument.description}
                                                    data-multiline={true}
                                                    data-for="comparedDocumentDescription"
                                                    className="document-description"
                                                    data-class="title-tooltip"
                                                    data-arrow-color="#266ea1"
                                                >{this.getShortDescription(comparedDocument.description, document.description.length)}</div>
                                            </>
                                        )
                                        : (
                                            <div className="document-description">{comparedDocument.description}</div>
                                        )
                                }
                                <div className="document-meta-tags">
                                    <span className="meta-tag"
                                          style={{"textTransform": "uppercase"}}>{comparedDocument.type}.{comparedDocument.number}</span>
                                    <span className="meta-tag">{getVersionTitle(comparedDocument.version)}</span>
                                    <span className="meta-tag">{getIssuedDate(comparedDocument.issuedAt)}</span>
                                </div>
                            </div>
                            {this.state.comparedDownloadPackageId &&
                            <div className="document-download-button">
                                <div className="download-icon-wrapper">
                                    <DownloadFile
                                        icon="word"
                                        id="comparedDownloadFile"
                                        type={'package'}
                                        packageId={this.state.comparedDownloadPackageId}/>
                                </div>
                            </div>
                            }
                        </div>
                        }

                        <div className="viewer">
                            {this.state.isComparedVersionsShow &&
                            // TODO:: move this part to separate component
                            <div className="versions-container">
                                <div className="version-header">
                                    <div className="version-text">
                                        <span>{this.state.documentVersions.length}</span> Versions Available
                                    </div>
                                </div>
                                <div className="versions">
                                    {this.state.documentVersions.map(document => {
                                        const disabled = (document.packageId === this.state.packageId);
                                        const selected = (document.packageId === this.state.comparedSelectedPackageId);
                                        let classes = 'version';
                                        disabled && (classes = classes + ' disabled');
                                        selected && (classes = classes + ' selected blue-gradient');
                                        return (
                                            <div onClick={() => this.selectComparedPackage(document.packageId)}
                                                 className={classes} key={document.packageId}>
                                                <div className="version-icon">
                                                    <span
                                                        className="material-icons">{disabled || selected ? 'check_circle' : 'radio_button_unchecked'}</span>
                                                </div>
                                                <div className="details">
                                                    <div className="name">{getVersionTitle(document.version)}</div>
                                                    <div
                                                        className="issued-at">{getIssuedDate(document.issuedAt)}</div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                    {this.state.documentVersions.length > 1 &&
                                    <button onClick={this.showComparedSelectedPackage}
                                            className="button blue-gradient view-document-button">View</button>
                                    }
                                    <button onClick={this.onBillSearch}
                                            className="button blue-gradient view-document-button">Search Bill
                                    </button>
                                </div>
                            </div>
                            }
                            {!this.state.isComparedVersionsShow && !this.state.isComparedLoading && this.state.comparedFileLink &&
                            <>
                                <PDFViewer file={this.state.comparedFileLink}/>
                            </>
                            }
                        </div>

                        {this.state.isComparedLoading &&
                        <div>
                            <PlaceholderShimmer/>
                            <div className="lds-dual-ring-container">
                                <div className="lds-dual-ring"></div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
                <Modal
                    open={this.state.modalOpen}
                    onClose={this.onCloseModal}
                    center
                    showCloseIcon={false}
                    classNames={{
                        overlay: 'custom-modal-overlay',
                        modal: 'custom-modal bills-search',
                    }}>
                    <div>
                        <div className="bill-search-title-container">
                            <span>Search for Bills</span>
                            <img
                                alt="close icon"
                                src="/static/media/cross.svg"
                                onClick={this.onCloseModal}
                            />
                        </div>
                        <div className="search-container">
                            <div className="search-section blue-gradient">
                                <div className="search-input">
                                    <input
                                        type="text"
                                        placeholder="Search for U.S. Bills and Resolutions"
                                        autoComplete="off"
                                        name="value"
                                        className="text-field text-field-input"
                                        onChange={this.handleChangeSearch}
                                        onKeyDown={this.handleSearchKeyDown}
                                        value={this.state.value}
                                    />
                                </div>
                                <div className="search-button">
                                    <button className="button" onClick={this.searchClick}><span
                                        className="material-icons">search</span>Search
                                    </button>
                                </div>
                            </div>
                            {this.state.billsCount > 0 && !this.state.areBillsFetching &&
                            <div className='documents-count-title'>{this.state.billsCount} Documents Found</div>
                            }
                            {this.state.areBillsFetching &&
                            <>
                                <PlaceholderShimmer/>
                                <PlaceholderShimmer/>
                                <PlaceholderShimmer/>
                                <PlaceholderShimmer/>
                                <PlaceholderShimmer/>
                            </>
                            }

                            {!this.state.areBillsFetching && this.state.bills.length === 0 &&
                            <EmptyResults text={'Enter a bill number, version, or keywords of its description.'}/>
                            }
                            {this.state.bills && this.state.bills.length > 0 && !this.state.areBillsFetching &&
                            <div>
                                <DocumentList
                                    onlBillClicked={this.onlBillClicked}
                                    isUsedForCFRAndUsCodes={true}
                                    documents={this.state.bills}
                                    searchQuery={this.state.value}
                                    isRunningInsideMicrosoftTeams={this.state.isRunningInsideMicrosoftTeams}
                                />
                                <div className="pagination-section">
                                    <Pagination
                                        className="rc-pagination"
                                        current={this.state.currentPage}
                                        total={this.state.billsCount}
                                        locale="en_EN"
                                        onChange={(page) => this.onPageChange(page)}/>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </Modal>
                {!this.state.isRunningInsideMicrosoftTeams && <Footer/>}
            </div>
        );
    }
}

export default SideBySidePage;
