import React from 'react';
import {Link, Redirect} from 'react-router-dom';
import './Header.css';
import {authenticationService} from '../../_services';
import OldLogo from '../OldLogo/OldLogo';
import Logo from '../Logo/Logo';

class Header extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            // logoColor: this.props.logoColor || '#1F3B4C',
            isSectionsButtonsHidden: this.props.isSectionsButtonsHidden || false,
            isLogoutDisplayed: this.props.isLogoutDisplayed || false,
            currentUser: authenticationService.currentUserValue,
            // searchQuery: this.props.searchQuery || '',
            // handleQuery: this.props.handleQuery || null,
            // value: '',
            redirect: null
        };
    }

    handleLogout = (event) => {
        authenticationService.logout();
        this.setState({redirect: '/login'})
    };

    redirectToPage = (route) => {
        this.setState({redirect: route});
    };

    shouldDisplayReportsLink = () => {
        const jsonUser = localStorage.getItem('currentUser');
        const user = JSON.parse(jsonUser);

        return user.isAdmin;
    };

    // componentDidMount() {
    //   this.setState({value: this.state.searchQuery});
    // }

    // handleChangeSearch = (event) => {
    //   this.setState({value: event.target.value});
    // }

    // handleSearchKeyDown = (event) => {
    //   if (event.key === 'Enter') {
    //     if (this.state.handleQuery) {
    //       this.state.handleQuery(this.state.value);
    //     } else {
    //       this.setState({ redirect: `/search?query=${this.state.value}` });
    //     }
    //   }
    // }

    // async clearSearchField(){
    //   await this.setState({value: ''});
    // }

    // TODO: think about better solution -- it's just a quick one
    shouldHighLight = (routes, currentRoute) => {
        return routes.some(route => currentRoute.includes(route));
    };

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }

        const {currentRoute, shouldLogoBeLight} = this.props;

        return (
            <header className="header">
                <div className="left-menu">
                    <Link to="/">
                        {/*<Logo logoColor={this.state.logoColor}/>*/}
                        <div className="logo-wrapper">
                            <Logo
                                shouldLogoBeLight={shouldLogoBeLight}
                            />
                        </div>
                    </Link>
                    <div className="items">
                        <a className="item" href="https://www.markuplabs.com/company" target="_blank">About Us</a>
                        <a className="item" href="https://www.markuplabs.com/redline" target="_blank">User Guide</a>
                        {/* <div className="item">Products</div> */}
                    </div>
                </div>
                {!this.state.isSectionsButtonsHidden &&
                <div className="top-section-buttons">
                    <button
                        onClick={() => this.redirectToPage('/search?query=')}
                        className={`section-button left-rounded blue-gradient`}
                        // className={`section-button left-rounded ${currentRoute && this.shouldHighLight(['/view', '/search'], currentRoute) && 'blue-gradient'}`}
                    >Bills
                    </button>
                    <button
                        onClick={() => this.redirectToPage('/us-code-search')}
                        className={`section-button no-left-right-borders blue-gradient`}
                        // className={`section-button no-left-right-borders ${currentRoute && this.shouldHighLight(['/us-code-search', '/us-code-view'], currentRoute) && 'blue-gradient'}`}
                    >U.S. Code
                    </button>
                    <button
                        onClick={() => this.redirectToPage('/cfr-search-page')}
                        className={`section-button right-rounded  blue-gradient`}
                        // className={`section-button right-rounded ${currentRoute && this.shouldHighLight(['/cfr-search-page', '/cfr-view-page'], currentRoute) && 'blue-gradient'}`}
                    >CFR
                    </button>
                </div>
                }
                <div className="rightItems">
                    {/*this.state.currentUser && !this.state.isSearchHidden &&
            <div className="search-box">
              <div className="search-box-prefix">
                <span className="material-icons">search</span>
              </div>
              <input type="text" placeholder="Search for U.S. Bills and Resolutions" autoComplete="off" className="text-field text-field-input" name="value" onChange={this.handleChangeSearch} onKeyDown={this.handleSearchKeyDown} value={this.state.value} />
              <div className="search-box-postfix">
                <span className="material-icons" onClick={() => this.clearSearchField()} style={{'cursor': 'pointer'}}>close</span>
              </div>
            </div>
          */}
                    {!this.state.currentUser &&
                    <>
                        {/* <Link className="item" to="/help">Help</Link> */}
                        <Link className="item" to="/login">Sign In</Link>
                        <Link className="button button-grey" to="/registration">Sign Up</Link>
                    </>
                    }

                    {this.state.currentUser &&
                    <>
                        {/* <Link className="item" to="/help">Help</Link> */}
                        {
                            this.shouldDisplayReportsLink()
                                ? (
                                    <Link className="item" to="/reports">Reports</Link>
                                )
                                : null

                        }
                        <Link className="item" to="/invitations">Invite Member</Link>
                        {this.state.isLogoutDisplayed &&
                        <button className="button button-grey button-logout" onClick={this.handleLogout}>
                            <span className="material-icons">exit_to_app</span> Logout
                        </button>
                        }
                        {!this.state.isLogoutDisplayed &&
                        <button className="button button-grey" onClick={() => this.redirectToPage('/user/profile')}>
                            <span
                                className="material-icons">person</span>{this.state.currentUser.firstName + ' ' + this.state.currentUser.lastName}
                        </button>
                        }
                    </>
                    }
                </div>
            </header>
        );
    }
}

export default Header;
