import React, {Component} from 'react';

import './CFRViewPage.css';
import {CFRService} from '../_services/cfr.service';
import queryString from 'query-string';
import Header from '../_components/Header/Header';
import PlaceholderShimmer from '../_components/PlaceholderShimmer/PlaceholderShimmer';
import PDFViewer from '../_components/PdfViewer/PdfViewer';
import EmptyResults from '../_components/EmptyResults/EmptyResults';
import Footer from '../_components/Footer/Footer';
import DownloadFile from '../_components/DownloadFile/DownloadFile';
import {usCodeService} from "../_services/us-code.service";
import {mappingsService} from '../_services/mappings.service';
import DocumentViewerCopy from '../_components/DocumentViewerCopy/DocumentViewerCopy';
import {DownloadService} from "../_services/download-file.service";
import DocumentList from "../SearchPage/DocumentsList";
import Pagination from "rc-pagination";
import Modal from "react-responsive-modal";
import {documentService} from "../_services/document.service";
import {convertResponseToValueLabelForYear, convertResponseToValueLabelForTitle} from '../_helpers/handleConfigResponse';
import {cfgService} from '../_services/config.service';
var Constant = require('../_constants');
// TODO: it's very similar to us code view page. Think about creating one component
class CFRViewPage extends Component {
    titles = [];

    constructor(props) {
        super(props);
        // this.titles = CFRService.getTitles();

        this.state = {
            isRunningInsideMicrosoftTeams: false,
            // this property is used to manage modal window for bill search
            modalOpen: false,
            // props to hold data about cfr (view mode)
            title: null,
            section: null,
            titleListUsCode : [],
            titleListCFR : [],
            part: null,
            year: null,
            cfrData: null,
            isFetching: false,
            // these props are used to regulate whether it is view mode or connect mode
            isView: false,
            isConnect: false,
            // these props are used for connected us code
            connectedUSCodeTitleNumber: null,
            connectedUSCodeSectionNumber: null,
            connectedUSCodeYear: null,
            // these props are used for cfr that is passed to connect page
            cfrSection: null,
            cfrPart: null,
            cfrTitle: null,
            cfrYear: null,
            // props to hold mapped us data titles and sections
            connectedUsCodeTitles: [],
            connectedUsCodeSections: [],
            // array that holds documents data for connect mode
            documents: [
                {
                    id: 1,
                    year: null,
                    documentLink: null,
                    section: null,
                    part: null,
                    title: null,
                    name: 'Document A - CFR',
                    mode: 'view-document',
                    bill: null
                },
                {
                    id: 2,
                    year: null,
                    documentLink: null,
                    section: null,
                    part: null,
                    title: null,
                    name: 'Document B - U.S. Code',
                    mode: 'view-document',
                    bill: null
                },
                {
                    id: 3,
                    year: null,
                    documentLink: null,
                    section: null,
                    part: null,
                    title: null,
                    name: 'Document C',
                    mode: 'search-us-code',
                    bill: null
                }
            ],
            // props for bill search
            bills: [],
            currentPage: 0,
            billsCount: 0,
            value: '',
            areBillsFetching: false,
            // this fields is used to hold the info which viewer invoked bill search modal
            documentId: null
        }
    }

    async componentDidMount() {
        const isRunningInsideMicrosoftTeamsFromLocalStorage = localStorage.getItem('isRunningInsideMicrosoftTeams');
        const isRunningInsideMicrosoftTeams = isRunningInsideMicrosoftTeamsFromLocalStorage === 'true';
        var CFRTitles = await cfgService.getTitles(Constant.CodeType_CFR_Title_String);
        this.setState({titleListCFR:(convertResponseToValueLabelForTitle(CFRTitles))})  

        var USCodeTitles = await cfgService.getTitles(Constant.CodeType_USCode_Title_String);
        this.setState({titleListUsCode:(convertResponseToValueLabelForTitle(USCodeTitles))})  
        this.setState({
            isRunningInsideMicrosoftTeams
        });
        this.setState({
            isRunningInsideMicrosoftTeams
        });

        const values = queryString.parse(this.props.location.search);
        const isView = values.isView === 'true';
        const isConnect = values.isConnect === 'true';

        try {
            if (isView) {
                await this.setState({
                    title: +values.title,
                    // TODO: clarify if section is number
                    section: !(values.section === 'null') && +values.section,
                    part: +values.part,
                    year: +values.year,
                    isView
                });

                const {title, section, year, part} = this.state;

                this.setState({
                    isFetching: true
                });

                const response = await CFRService.getCFR(title, year, section, part);

                this.setState({
                    isFetching: false,
                    cfrData: response
                });
            }

            if (isConnect) {
                const {documents} = this.state;
                let updatedDocuments;

                // get params from route when we go from view page to connect
                await this.setState({
                    // us code data
                    cfrYear: +values.cfrYear,
                    cfrTitle: +values.cfrTitle,
                    cfrPart: +values.cfrPart,
                    // first we need to check if it is passed as query param at all, 'cause otherwise we'll get NaN
                    // TODO: clarify if section is number
                    cfrSection: values.cfrSection && values.cfrSection !== 'null' && +values.cfrSection,
                    // connected us code data
                    connectedUSCodeTitleNumber: +values.connectedUSCodeTitleNumber,
                    // TODO: clarify if section is number
                    connectedUSCodeSectionNumber: +values.connectedUSCodeSectionNumber,
                    connectedUSCodeYear: +values.connectedUSCodeYear,
                    isConnect
                });

                const {cfrYear, cfrTitle, cfrPart, cfrSection, connectedUSCodeTitleNumber, connectedUSCodeSectionNumber, connectedUSCodeYear} = this.state;


                // set isLoading flag true for first 2 documents
                updatedDocuments = documents.map(document => (document.id === 1 || document.id === 2) ? Object.assign({...document}, {isLoading: true}) : document);
                this.setState({documents: updatedDocuments});

                // get data for cfr
                const cfrResponse = await CFRService.getCFR(cfrTitle, cfrYear, cfrSection, cfrPart);
                // get data for us code
                const usCodeResponse = await usCodeService.getUsCode(connectedUSCodeTitleNumber, connectedUSCodeYear, connectedUSCodeSectionNumber);
                // get us code mappings for given cfr
                const mappingsResponse = await mappingsService.getUsCodeMappings(cfrTitle, cfrPart);
                // prepare titles for selects
                const connectedUsCodeTitles = this.getUsCodeTitles(mappingsResponse.usCodeTitles);
                const connectedUsCodeSections = this.getUsCodeSections(mappingsResponse.groupedUsCodeSections);

                let connectedUsCodeData, cfrData;

                // TODO: add if is present

                if (cfrResponse.isPresent) {
                    cfrData = {
                        year: cfrYear,
                        documentLink: cfrResponse.link,
                        section: cfrSection,
                        part: cfrPart,
                        title: cfrTitle,
                        isLoading: false
                    };
                } else {
                    cfrData = {
                        year: null,
                        documentLink: null,
                        section: null,
                        part: null,
                        title: null,
                        isLoading: false
                    };
                }

                if (usCodeResponse.isPresent) {
                    connectedUsCodeData = {
                        year: connectedUSCodeYear,
                        documentLink: usCodeResponse.link,
                        section: connectedUSCodeSectionNumber,
                        part: null,
                        title: connectedUSCodeTitleNumber,
                        isLoading: false
                    };
                } else {
                    connectedUsCodeData = {
                        year: null,
                        documentLink: null,
                        section: null,
                        part: null,
                        title: null,
                        isLoading: false
                    };
                }

                const cfrDocument = Object.assign({...documents[0]}, cfrData);
                const connectedUsCodeDocument = Object.assign({...documents[1]}, connectedUsCodeData);

                // first two documents should be updated and the 3rd one should be left as it is
                updatedDocuments = [cfrDocument, connectedUsCodeDocument, documents[2]];

                // old logic
                this.setState({
                    documents: updatedDocuments,
                    connectedUsCodeSections,
                    connectedUsCodeTitles
                });
            }

        } catch (err) {
            console.error(err);

            if (isView) {
                this.setState({
                    isFetching: false
                });
            } else {
                const {documents} = this.state;
                const updatedDocuments = documents.map(document => (document.id === 1 || document.id === 2) ? Object.assign({...document}, {isLoading: false}) : document);
                this.setState({
                    documents: updatedDocuments
                });
            }
        }
    }

    getDocumentNameByIdAndType = (id, type) => {
        let documentName;

        if (id === 3) {
            documentName = `Document C - ${type === 'us-code' ? 'U.S. Code' : 'Bill'}`;
        } else if (id === 2) {
            documentName = `Document B - ${type === 'us-code' ? 'U.S. Code' : 'Bill'}`;
        } else {
            documentName = 'Document A - US Code';
        }

        return documentName;
    };

    onCloseModal = () => {
        this.setState({
            modalOpen: false
        });
    };

    getUsCodeSections = (usCodeSections = []) => {
        return mappingsService.getUsCodeSections(usCodeSections);
    };

    getUsCodeTitles = (usCodeTitles = []) => {

        return mappingsService.getUsCodeTitles(usCodeTitles, this.state.titleListUsCode);
    };

    goBack = () => {
        const {isView} = this.state;

        let backUrl = null;

        if (isView) {
            const {title, section, part, year} = this.state;

            if (section) {
                backUrl = `/cfr-search-page?cfrYear=${year}&cfrTitle=${title}&cfrPart=${part}&cfrSection=${section}`;
            } else {
                backUrl = `/cfr-search-page?cfrYear=${year}&cfrTitle=${title}&cfrPart=${part}`;
            }
        } else {
            const {documents} = this.state;
            const cfr = documents[0];

            const {part, section, year, title} = cfr;

            if (section) {
                backUrl = `/cfr-search-page?cfrYear=${year}&cfrTitle=${title}&cfrPart=${part}&cfrSection=${section}`;
            } else {
                backUrl = `/cfr-search-page?cfrYear=${year}&cfrTitle=${title}&cfrPart=${part}`;
            }
        }

        this.props.history.push(backUrl);
    };

    getSelectedTitleLabel = (titleValue) => {
        const target = this.state.titleListCFR.find(title => title.value === titleValue);

        return target ? target.label : 'Default label';
    };

    goView = async () => {
        // take passed cfr data
        const {cfrYear, cfrTitle, cfrSection, cfrPart, documents} = this.state;

        // if we clicked view from connect then we should display first document that should always be cfr
        // (section is optional)
        if (cfrYear && cfrTitle && cfrPart) {
            await this.setState({
                title: cfrTitle,
                section: cfrSection,
                year: cfrYear,
                part: cfrPart,
                cfrData: {
                    isPresent: true,
                    link: documents[0].documentLink
                }
            });
        }

        // change view
        this.setState({
            isView: true,
            isConnect: false
        })
    };

    goConnect = async () => {
        const {year, title, section, part, cfrData, documents} = this.state;

        // if we clicked go connect from view we should take document from view and display it as first document in documents array

        if (year && title && part && cfrData) {
            const documentData = {
                year,
                title,
                section,
                part,
                documentLink: cfrData.link
            };

            // get mappings
            const mappingsResponse = await mappingsService.getUsCodeMappings(title, part);
            // prepare titles & parts for selects
            const connectedUsCodeTitles = this.getUsCodeTitles(mappingsResponse.usCodeTitles);
            const connectedUsCodeSections = this.getUsCodeSections(mappingsResponse.groupedUsCodeSections);

            // we need to set it here, 'cause the next function (isUsCodeMappingPresent) depends on this props from state
            await this.setState({
                connectedUsCodeTitles,
                connectedUsCodeSections
            });

            const mode = this.isUsCodeMappingPresent() ? 'connected-us-code' : 'search-us-code';

            const updatedDocuments = documents.map(doc => {
                let document = null;

                if (doc.id === 1) {
                    document = Object.assign({...doc}, documentData);
                } else if (doc.id === 2) {
                    document = Object.assign({...doc}, {mode})
                } else {
                    document = doc;
                }

                return document;
            });

            await this.setState({
                documents: updatedDocuments
            });

        }

        this.setState({
            isView: false,
            isConnect: true
        })
    };

    // this is gonna be used for hide/show purposes
    isUsCodeMappingPresent = () => {
        const {connectedUsCodeTitles, connectedUsCodeSections} = this.state;

        return connectedUsCodeTitles.length && connectedUsCodeSections.length;
    };

    onUsCodeSearch = async (id, searchUsCodeYear, searchUsCodeTitle, searchUsCodeSection) => {
        try {
            let documentData = null;
            let updatedDocuments;
            const {documents} = this.state;

            // set isLoading flag to true for given document (id)
            updatedDocuments = documents.map(document => document.id === id ? Object.assign({...document}, {
                isLoading: true,
                mode: 'view-document'
            }) : document);
            this.setState({documents: updatedDocuments});

            const response = await usCodeService.getUsCode(searchUsCodeTitle, searchUsCodeYear, searchUsCodeSection);

            if (response.isPresent) {
                // I need to do it, 'cause otherwise we can run into the situation when previous mode was view bill
                // and If I don't do this then the if statement in componentWillReceiveProps hook will be fired and view bill mode will be set
                documentData = {
                    year: searchUsCodeYear,
                    documentLink: response.link,
                    section: searchUsCodeSection,
                    part: null,
                    title: searchUsCodeTitle,
                    mode: 'view-document',
                    isLoading: false,
                    bill: null,
                    name: this.getDocumentNameByIdAndType(id, 'us-code')
                };
            } else {
                documentData = {
                    year: null,
                    documentLink: null,
                    section: null,
                    part: null,
                    title: null,
                    mode: 'view-document',
                    isLoading: false,
                    bill: null
                };
            }

            updatedDocuments = documents.map(document => document.id === id ? Object.assign({...document}, documentData) : document);

            this.setState({
                documents: updatedDocuments
            });
        } catch (err) {
            console.error(err);
            // in case of error set isLoading flag to false for given document
            const {documents} = this.state;
            let updatedDocuments;

            updatedDocuments = documents.map(document => document.id === id ? Object.assign({...document}, {isLoading: false}) : document);

            this.setState({
                documents: updatedDocuments
            });
        }
    };

    onUsCodeConnect = async (id, connectUsCodeTitle, connectUsCodeSection) => {
        try {
            // now we use only 2018 mappings
            const year = 2018;
            let connectedUsCodeData = null;
            let updatedDocuments;
            const {documents} = this.state;

            // set isLoading flag to true for given document (id)
            updatedDocuments = documents.map(document => document.id === id ? Object.assign({...document}, {
                isLoading: true,
                mode: 'view-document'
            }) : document);
            this.setState({documents: updatedDocuments});

            const response = await usCodeService.getUsCode(connectUsCodeTitle, year, connectUsCodeSection, null);

            if (response.isPresent) {
                // I need to do it, 'cause otherwise we can run into the situation when previous mode was view bill
                // and If I don't do this then the if statement in componentWillReceiveProps (DocumentViewerCopy) hook will be fired and view bill mode will be set
                connectedUsCodeData = {
                    year,
                    documentLink: response.link,
                    title: connectUsCodeTitle,
                    section: connectUsCodeSection,
                    mode: 'view-document',
                    isLoading: false,
                    bill: null,
                    name: this.getDocumentNameByIdAndType(id, 'us-code')
                };
            } else {
                connectedUsCodeData = {
                    year: null,
                    documentLink: null,
                    title: null,
                    section: null,
                    mode: 'view-document',
                    isLoading: false,
                    bill: null
                };
            }

            updatedDocuments = documents.map(document => document.id === id ? Object.assign({...document}, connectedUsCodeData) : document);

            this.setState({
                documents: updatedDocuments
            });
        } catch (err) {
            console.error(err);
            // in case of error set isLoading flag to false for given document
            const {documents} = this.state;
            let updatedDocuments;

            updatedDocuments = documents.map(document => document.id === id ? Object.assign({...document}, {isLoading: false}) : document);

            this.setState({
                documents: updatedDocuments
            });
        }
    };

    onDownload = async (type, data) => {
        const response = await DownloadService.getFileRequest(type, data);

        var responseFileURL = (await response.json()).fileUrl
        const downloadLink = document.createElement("a");
        downloadLink.href = responseFileURL;
        downloadLink.download = "test";
        downloadLink.click()
    };

    // document id is the viewer this request came from
    onBillSearch = async (documentId) => {
        this.onOpenModal(documentId);
    };

    onOpenModal = async (documentId) => {
        // clear the data that was entered before opening the modal
        await this.clearBillsData();

        this.setState({
            modalOpen: true,
            documentId
        });
    };

    clearBillsData = () => {
        this.setState({
            currentPage: 0,
            billsCount: 0,
            value: '',
            areBillsFetching: false,
            documentId: null,
            bills: []
        });
    };

    onlBillClicked = async (packageId) => {
        const {documentId} = this.state;

        const response = await documentService.getDocument(packageId);

        const {documents} = this.state;
        const updatedDocuments = documents.map(document =>
            document.id === documentId
                ? Object.assign({...document}, {
                    mode: 'view-bill',
                    bill: {...response.document, documentLink: response.link},
                    name: this.getDocumentNameByIdAndType(document.id, 'bill')
                })
                : document
        );

        this.setState({
            documents: updatedDocuments
        });

        this.onCloseModal();
    };

    handleChangeSearch = (event) => {
        this.setState({value: event.target.value});
    };

    handleSearchKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (this.state.value) {
                this.getBills();
            }
        }
    };

    searchClick = async () => {
        await this.setState({currentPage: 1});
        this.getBills();
    };

    onPageChange = async (page) => {
        await this.setState({currentPage: page});
        this.getBills();
    };

    getBills = async () => {
        if (this.state.value) {
            this.setState({areBillsFetching: true});

            const result = await documentService.getDocuments(this.state.value, this.state.currentPage);

            this.setState({areBillsFetching: false});
            this.setState({
                // I had to rename it, 'cause otherwise I will have naming collisions
                bills: result.documents || [],
                billsCount: result.documentsCount || 0
            })
        }
    };

    onChangeMode = (documentId, mode) => {
        const {documents} = this.state;
        const updatedDocuments = documents.map(document => document.id === documentId ? Object.assign({...document}, {mode}) : document);
        this.setState({documents: updatedDocuments});
    };

    render() {
        const {
            isFetching,
            cfrData,
            title,
            year,
            section,
            part,
            isView,
            isConnect,
            documents,
            connectedUsCodeTitles,
            connectedUsCodeSections,
            isRunningInsideMicrosoftTeams
        } = this.state;
        const currentRoute = this.props.location.pathname;

        var usCodeTitles = []
        if (usCodeTitles.length == 0){
            usCodeTitles = this.state.titleListUsCode
        }

        return (
            <div>
                {!isRunningInsideMicrosoftTeams && <Header currentRoute={currentRoute} shouldLogoBeLight={false}/>}
                <div className="blue-gradient navigation-section us-code-viewer-page">
                    <div className="back-button" onClick={this.goBack}>
                        <span className="material-icons">keyboard_backspace</span>
                        <span className="back-text">Back</span>
                    </div>
                    <div className="viewer-section-buttons">
                        <button
                            className={`viewer-section-button left-rounded ${isView ? 'active' : ''}`}
                            onClick={this.goView}
                        >View
                        </button>
                        <button
                            className={`viewer-section-button right-rounded ${isConnect ? 'active' : ''}`}
                            onClick={this.goConnect}
                        >Connect
                        </button>
                    </div>
                </div>
                {
                    isView && (
                        <div>
                            {
                                isFetching && (
                                    <div>
                                        <PlaceholderShimmer/>
                                        <div className="lds-dual-ring-container">
                                            <div className="lds-dual-ring"></div>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                (!isFetching && cfrData && cfrData.isPresent) &&
                                <>
                                    <div className="us-code-container view-page">
                                        <div className="description-container">
                                            <div>
                                        <span
                                            className="description">Code of Federal Regulations, {this.getSelectedTitleLabel(title)}</span>
                                            </div>
                                            <div>
                                                <span className="chips">{year} Edition</span>
                                                <span className="chips">Part {part}</span>
                                                {section && <span className="chips">Section {section}</span>}
                                            </div>
                                        </div>
                                        <div>
                                            <DownloadFile
                                                icon="default"
                                                type={'cfr'}
                                                title={title}
                                                year={year}
                                                section={section}
                                                part={part}
                                            />
                                        </div>
                                    </div>
                                    <PDFViewer file={cfrData.link}/>
                                </>
                            }
                            {

                                !isFetching && cfrData && !cfrData.isPresent &&
                                <EmptyResults text={'Check your data, because CFR is not found.'}/>
                            }
                        </div>
                    )
                }
                {
                    isConnect && (
                        <div className="connected-docs-container">
                            <DocumentViewerCopy
                                document={documents[0]}
                                usCodeTitles={usCodeTitles}
                                onDownload={this.onDownload}
                            />
                            <DocumentViewerCopy
                                document={documents[1]}
                                usCodeTitles={usCodeTitles}
                                onUsCodeSearch={this.onUsCodeSearch}
                                onUsCodeConnect={this.onUsCodeConnect}
                                onDownload={this.onDownload}
                                onBillSearch={this.onBillSearch}
                                onChangeMode={this.onChangeMode}
                                connectedUsCodeTitles={connectedUsCodeTitles}
                                connectedUsCodeSections={connectedUsCodeSections}
                            />
                            <DocumentViewerCopy
                                document={documents[2]}
                                usCodeTitles={usCodeTitles}
                                onUsCodeSearch={this.onUsCodeSearch}
                                onUsCodeConnect={this.onUsCodeConnect}
                                onDownload={this.onDownload}
                                onBillSearch={this.onBillSearch}
                                onChangeMode={this.onChangeMode}
                                connectedUsCodeTitles={connectedUsCodeTitles}
                                connectedUsCodeSections={connectedUsCodeSections}
                            />
                        </div>
                    )
                }
                <Modal
                    open={this.state.modalOpen}
                    onClose={this.onCloseModal}
                    center
                    showCloseIcon={false}
                    classNames={{
                        overlay: 'custom-modal-overlay',
                        modal: 'custom-modal bills-search',
                    }}>
                    <div>
                        <div className="bill-search-title-container">
                            <span>Search for Bills</span>
                            <img
                                alt="close icon"
                                src="/static/media/cross.svg"
                                onClick={this.onCloseModal}
                            />
                        </div>
                        <div className="search-container">
                            <div className="search-section blue-gradient">
                                <div className="search-input">
                                    <input
                                        type="text"
                                        placeholder="Search for U.S. Bills and Resolutions"
                                        autoComplete="off"
                                        name="value"
                                        className="text-field text-field-input"
                                        onChange={this.handleChangeSearch}
                                        onKeyDown={this.handleSearchKeyDown}
                                        value={this.state.value}
                                    />
                                </div>
                                <div className="search-button">
                                    <button className="button" onClick={this.searchClick}><span
                                        className="material-icons">search</span>Search
                                    </button>
                                </div>
                            </div>
                            {this.state.billsCount > 0 && !this.state.areBillsFetching &&
                            <div className='documents-count-title'>{this.state.billsCount} Documents Found</div>
                            }
                            {this.state.areBillsFetching &&
                            <>
                                <PlaceholderShimmer/>
                                <PlaceholderShimmer/>
                                <PlaceholderShimmer/>
                                <PlaceholderShimmer/>
                                <PlaceholderShimmer/>
                            </>
                            }

                            {!this.state.areBillsFetching && this.state.bills.length === 0 &&
                            <EmptyResults text={'Enter a bill number, version, or keywords of its description.'}/>
                            }
                            {this.state.bills && this.state.bills.length > 0 && !this.state.areBillsFetching &&
                            <div>
                                <DocumentList
                                    onlBillClicked={this.onlBillClicked}
                                    isUsedForCFRAndUsCodes={true}
                                    documents={this.state.bills}
                                    searchQuery={this.state.value}
                                    isRunningInsideMicrosoftTeams={this.state.isRunningInsideMicrosoftTeams}
                                />
                                <div className="pagination-section">
                                    <Pagination
                                        className="rc-pagination"
                                        current={this.state.currentPage}
                                        total={this.state.billsCount}
                                        locale="en_EN"
                                        onChange={(page) => this.onPageChange(page)}/>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </Modal>
                {!isRunningInsideMicrosoftTeams && <Footer/>}
            </div>
        );
    }
}

export default CFRViewPage;
