import {handleResponse} from "../_helpers";

export const linkedinService = {

    signInWithLinkedIn(linkedinCode, linkedinState) {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({code: linkedinCode, state: linkedinState})
        };

        return fetch(`${process.env.REACT_APP_API_URL}/linkedin/sign-in`, requestOptions).then(handleResponse);
    }
};
