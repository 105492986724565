import React, {Component} from 'react';

import './EventsList.css';
import EventItem from './EventItem';

const HEADER_CELLS = [
    {label: 'Is Teams app', className: 'teams-app'},
    {label: 'E-mail Address', className: 'email-address'},
    {label: 'First name', className: 'first-name'},
    {label: 'Last name', className: 'last-name'},
    {label: 'Action date', className: 'action-date'}
];

class EventsList extends Component {

    headerCells = [...HEADER_CELLS];

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {selectedEventType} = nextProps;

        if (selectedEventType === 'Redirect') {
            this.headerCells = [...HEADER_CELLS, {label: 'Route', className: 'route'}];
        } else {
            this.headerCells = [...HEADER_CELLS];
        }
    }

    getBody = () => {
        const {events, selectedEventType} = this.props;

        return events.map((event, index) => {
            return (
                <EventItem
                    key={event.id}
                    selectedEventType={selectedEventType}
                    shouldDisplayGreyBackground={index % 2 !== 0}
                    event={event}
                />
            )
        });
    };

    getHeader = () => {
        return this.headerCells.map(headerCell => {
            return (
                <div className={`header-cell ${headerCell.className}`}>
                    <span>{headerCell.label}</span>
                </div>
            )
        });
    };

    render() {
        const {eventsCount} = this.props;

        return (
            <div>
                <div className="event-list-header">
                    {this.getHeader()}
                </div>
                {
                    eventsCount === 0
                        ? (
                            <div className="no-events-container">
                                <span>There are no events</span>
                            </div>
                        )
                        : (
                            <div className="events-list">
                                {this.getBody()}
                            </div>
                        )
                }
            </div>
        );
    }
}

export default EventsList;
