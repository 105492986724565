import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../_components/Header/Header';
import Footer from '../_components/Footer/Footer';

class TermsPage extends Component {
  state = {};

  render() { 
    return (
      <div>
        <Header isSectionsButtonsHidden />
        <div className="content">
          <h1>Terms &amp; Conditions</h1>
          <h2>Introduction</h2>
          <p>These Service Standard Terms and Conditions shall manage your use of all services provided by Markup LLC ("we", "us", "our"). </p>
          <p>These Terms will be applied fully and affect to your use of our services. By using our services, you agreed to accept all terms and conditions written here. You must not use Markup LLC services if you disagree with any of these Service Standard Terms and Conditions.</p>
          <h2>Intellectual Property Rights</h2>
          <p>Other than the content you own, under these Terms, Markup LLC and/or its licensors own all the intellectual property rights and materials contained in our services.</p>
          <p>You are granted a limited license only for purposes of viewing the material contained on our services.</p>
          <h2>Restrictions</h2>
          <p>You are specifically restricted from all of the following:</p>
          <ul>
            <li>Publishing any services material in any other media;</li>
            <li>Selling, sublicensing and/or otherwise commercializing any services material;</li>
            <li>Publicly performing and/or showing any services material;</li>
            <li>Using Markup LLC services in any way that is or may be damaging to our services;</li>
            <li>Using Markup LLC services in any way that impacts user access to our services;</li>
            <li>Using Markup LLC services contrary to applicable laws and regulations, or in anyway may cause harm to the Website, or to any person or business entity;</li>
            <li>Engaging in any data mining, data harvesting, data extracting or any other similaractivity in relation to Markup LLC services;</li>
            <li>Using Markup LLC services to engage in any advertising or marketing.</li>
          </ul>
          <h2>Your Content</h2>
          <p>In these Services Standard Terms and Conditions, "Your Content" shall mean any audio, video text, images, information or other material you create or display on Markup LLC services. By displaying Your Content, you grant Markup LLC a non-exclusive, worldwide irrevocable, sub licensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media.</p>
          <p>Your Content must be your own and must not be invading any third-party’s rights. Markup LLC reserves the right to remove any of Your Content from Markup LLC services at any time without notice.</p>
          <h2>Your Privacy</h2>
          <p>Please read <Link to="/privacy-policy">Privacy Policy</Link>.</p>
          <h2>No warranties</h2>
          <p>Markup LLC services are provided "as is," with all faults, and Markup LLC expresses norepresentations or warranties, of any kind related to this Website or any services provided by Markup LLC.</p>
          <h2>Limitation of liability</h2>
          <p>In no event shall Markup LLC, nor any of its officers, directors and employees, be held liable for anything arising out of or in any way connected with your use of Markup LLC services, whether such liability is under contract. Markup LLC, including its officers, directors and employees shall not be held liable for any indirect, consequential or special liability arising out of or in any way related to your use of Markup LLC services.</p>
          <h2>Indemnification</h2>
          <p>You hereby indemnify to the fullest extent Markup LLC from and against any and/or all liabilities, costs, demands, causes of action, damages and expenses arising in any way related to your breach of any of the provisions of these Terms.</p>
          <h2>Severability</h2>
          <p>If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.</p>
          <h2>Variation of Terms</h2>
          <p>Markup LLC is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review these Terms on a regular basis.</p>
          <h2>Assignment</h2>
          <p>The Markup LLC is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.</p>
          <h2>Entire Agreement</h2>
          <p>These Terms constitute the entire agreement between Markup LLC and you in relation to your use of Markup LLC services, and supersede all prior agreements and understandings.</p>
          <h2>Governing Law &amp; Jurisdiction</h2>
          <p>These Terms will be governed by and interpreted in accordance with the laws of the State of Delaware, and you submit to the non-exclusive jurisdiction of the courts locatedin the State of Delaware for the resolution of any disputes.</p>
        </div>
        <Footer />
      </div>
    );
  }
}
 
export default TermsPage;