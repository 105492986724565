export const searchContainerSelectStyles = {
    placeholder: defaultStyles => (
        {
            ...defaultStyles,
            color: 'white',
        }),
    control: defaultStyles => (
        {
            ...defaultStyles,
            background: 'transparent',
            borderRadius: '8px',
            // it is a hack to make it 40px
            minHeight: '40px',
            borderColor: 'white',
            cursor: 'pointer',
            '&:hover': {
                borderColor: 'white'
            }
        }),
    option: (defaultStyles, state) => {
        return {
            ...defaultStyles,
            color: state.isSelected ? 'white' : 'black',
            backgroundImage: state.isSelected ? 'linear-gradient(267deg, #389be1, #266ea1);' : 'white',
            // only works in combination with menuPortalTarget={document.body}
            zIndex: 999
        };
    },
    dropdownIndicator: defaultStyles => {
        return {
            ...defaultStyles,
            color: 'white',
            '&:hover': {
                color: 'white'
            }
        };
    }
};

export const connectCFRSelectStyles = {
    placeholder: defaultStyles => (
        {
            ...defaultStyles,
            color: '#a2aec1',
        }),
    control: (defaultStyles, state) => (
        {
            ...defaultStyles,
            // disable blue outline
            boxShadow: state.isFocused ? 0 : 0,
            background: 'transparent',
            borderRadius: '8px',
            // it is a hack to make it 40px
            minHeight: '40px',
            borderColor: '#d7ddeb',
            cursor: 'pointer',
            '&:hover': {
                borderColor: '#d7ddeb'
            }
        }),
    option: (defaultStyles, state) => {
        return {
            ...defaultStyles,
            // only works in combination with menuPortalTarget={document.body}
            zIndex: 999,
            color: state.isSelected ? 'white' : '#3b4757',
            backgroundImage: state.isSelected ? 'linear-gradient(267deg, #389be1, #266ea1);' : 'white'
        };
    },
    dropdownIndicator: defaultStyles => {
        return {
            ...defaultStyles,
            color: '#3b4757',
            '&:hover': {
                color: '#3b4757'
            }
        };
    }
};
