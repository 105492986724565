import React, {Component} from 'react';
import {toast} from 'react-toastify';

import './InvitationsPage.css';
import Header from '../_components/Header/Header';
import InvitationList from './InvitationsList';
import {invitationService} from "../_services/invitation.service";
import Pagination from "rc-pagination";
import Modal from "react-responsive-modal";
import OldLogo from '../_components/OldLogo/OldLogo';
import {isEmailValid} from "../_helpers";
import {parse} from "../_helpers/query";

class InvitationsPage extends Component {

    constructor(props) {
        super(props);

        const queryObj = parse(this.props.location.search);

        this.state = {
            loading: false,
            invitations: [],
            invitationsCount: 0,
            currentPage: 1,
            modalOpen: false,
            invitationEmail: '',
            logoColor: '#1F3B4C',
            // TODO: think of using react forms or moving modal to the separate component, 'cause this page is getting bigger
            sendInvitationButtonDisabled: true,
            isEmailInputTouched: false
        };
    }

    componentDidMount() {
        this.getInvitations();
    }

    // TODO: think of using react forms or moving modal to the separate component, 'cause this page is getting bigger
    shouldDisplayError = () => {
        const {isEmailInputTouched, sendInvitationButtonDisabled} = this.state;

        return isEmailInputTouched && sendInvitationButtonDisabled;
    };

    async onPageChange(page) {
        await this.setState({currentPage: page});
        this.getInvitations();
    }

    async getInvitations() {
        try {
            this.setState({loading: true});

            const response = await invitationService.getInvitations(this.state.currentPage);
            let invitations = response.invitations || [];
            invitations = invitationService.processInvitationsFromResponse(invitations);

            this.setState({loading: false});
            this.setState({
                invitations,
                invitationsCount: response.invitationsCount || 0
            });
        } catch (error) {
            console.error(error);
            toast.error(error);
            this.setState({loading: false});
        }
    }

    async resendInvitation(invitation) {
        if (invitation.isReminderSent) {
            return
        }

        try {
            this.setState({loading: true});

            const invitations = this.state.invitations;
            await invitationService.sendReminder(invitation);

            const remappedInvitations = invitations
                .map(currentInvitation => invitation.id === currentInvitation.id ? Object.assign({...invitation}, {isReminderSent: true}) : currentInvitation);

            this.setState({invitations: remappedInvitations, loading: false});
        } catch (error) {
            toast.error(error);
            console.error(error);
            this.setState({loading: false});
        }
    }

    onOpenModal = () => {
        this.setState({modalOpen: true, invitationEmail: ''});
    };

    onCloseModal = () => {
        this.setState({
            modalOpen: false,
            invitationEmail: '',
            sendInvitationButtonDisabled: true,
            isEmailInputTouched: false
        });
    };

    onSendInvitations = async () => {
        try {
            const {invitationEmail} = this.state;
            await invitationService.createInvitation(invitationEmail);
            this.setState({
                modalOpen: false,
                invitationEmail: '',
                sendInvitationButtonDisabled: true,
                isEmailInputTouched: false
            });
            await this.getInvitations();
            toast.info('Invitation was send successfully!');
        } catch (error) {
            toast.error(error);
            console.error(error);
        }
    };

    handleInvitationEmailChange = (event) => {
        const target = event.target;
        const value = target.value;
        let sendInvitationButtonDisabled = false;

        if (!isEmailValid(value)) {
            sendInvitationButtonDisabled = true;
        }

        this.setState({invitationEmail: value, sendInvitationButtonDisabled, isEmailInputTouched: true});
    };

    getInvitationText = (invitationsCount) => {
        let invitationText = '';

        if (invitationsCount === 0) {
            invitationText = 'invitations';
        } else if (invitationsCount === 1) {
            invitationText = 'invitation';
        } else {
            invitationText = 'invitations';
        }

        return invitationText;
    };

    goToTheFirstPage = async () => {
        await this.setState({currentPage: 1});
        this.getInvitations();
    };

    goToTheLastPage = async () => {
        // TODO: think of storing it somewhere instead of hardcoding
        const itemsPerPage = 8;
        const lastPage = Math.ceil(this.state.invitationsCount / itemsPerPage);
        await this.setState({currentPage: lastPage});
        this.getInvitations();
    };

    render() {
        const {invitations, currentPage, invitationsCount, invitationEmail, sendInvitationButtonDisabled} = this.state;

        return (
            <div>
                <Header isSectionsButtonsHidden shouldLogoBeLight={false}/>
                <div className="invitations-container">
                    <div className="invitations-header">
                        <div>
                            <div className="invitations-back">
                                <span>Invitations</span>
                            </div>
                            <div className="invitations-info">
                                <span>You have sent {invitationsCount} {this.getInvitationText(invitationsCount)}</span>
                            </div>
                        </div>
                        <div>
                            <button className="button button-blue button-invite" onClick={this.onOpenModal}>Invite
                            </button>
                        </div>
                    </div>
                    <div className="invitation-list-container">
                        <InvitationList
                            invitationsCount={invitationsCount}
                            invitations={invitations}
                            resendInvitation={(invitation) => this.resendInvitation(invitation)}
                        />
                    </div>
                    <div className="pagination-container">
                        <div className="pagination-button" onClick={this.goToTheFirstPage}>
                            <span>First</span>
                        </div>
                        <div className="pagination-wrapper">
                            <Pagination
                                className="rc-pagination"
                                current={currentPage}
                                total={invitationsCount}
                                locale="en_EN"
                                onChange={(page) => this.onPageChange(page)}
                            />
                        </div>
                        <div className="pagination-button" onClick={this.goToTheLastPage}>
                            <span>Last</span>
                        </div>
                    </div>
                </div>
                <Modal
                    open={this.state.modalOpen}
                    onClose={this.onCloseModal}
                    center
                    showCloseIcon={false}
                    classNames={{
                        overlay: 'custom-modal-overlay',
                        modal: 'custom-modal',
                    }}>
                    <div className="invite-modal-container">
                        <div className="logo-container">
                            <OldLogo
                                logoColor={this.state.logoColor}
                                class={'invite-logo'}
                            />
                        </div>

                        <div className="input-container">
                            <span className="info">Send invitations to Markup Redline!</span>
                            <div className="input-wrapper">
                                <input
                                    type="email"
                                    name="sendInvitation"
                                    placeholder="Email Address"
                                    autoComplete="off"
                                    onChange={this.handleInvitationEmailChange}
                                    value={invitationEmail}
                                    required
                                />
                                {
                                    this.shouldDisplayError() &&
                                    (
                                        <div>
                                            <span className="invalid-email">Please enter a valid email</span>
                                        </div>
                                    )
                                }
                            </div>
                        </div>

                        <div className="footer-container">
                            <button type="button" onClick={this.onCloseModal} className="button button-cancel">Cancel
                            </button>
                            <button
                                type="button"
                                onClick={this.onSendInvitations}
                                className={`button button-send ${sendInvitationButtonDisabled ? ' disabled' : ''}`}
                                disabled={sendInvitationButtonDisabled}
                            >Send Invitations
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default InvitationsPage;
