import React, { Component } from 'react';
import Header from '../_components/Header/Header';
import Footer from '../_components/Footer/Footer';
import './AboutPage.css';

class AboutPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isRunningInsideMicrosoftTeams: false
    };
  }

  componentDidMount() {
    const isRunningInsideMicrosoftTeamsFromLocalStorage = localStorage.getItem('isRunningInsideMicrosoftTeams');
    const isRunningInsideMicrosoftTeams = isRunningInsideMicrosoftTeamsFromLocalStorage === 'true';

    this.setState({
        isRunningInsideMicrosoftTeams
    });
  }

  render() {
    return (
      <div>
          {!this.state.isRunningInsideMicrosoftTeams && <Header isSectionsButtonsHidden />}
        <div className="content">
          <div className="container">
            <section className="about-us">
              <div className="description">
                <h1>About Us</h1>
                <h2>Markup Redline™ is a Washington D.C. based team of Public Policy and Technology Professionals.</h2>
                <p>Markup Redline™'s premise is that there should be a smart and secure way for people to create, manage, influence, and analyze public policy.</p>
                <p>That's not the way it works today. In fact, not much has changed in 229 years. We're fixing that.</p>
              </div>
              <div className="image">
                <img alt="Bill of rights" src="static/media/about-image.png" />
              </div>
            </section>
            <section className="team">
              <div className="members">
                <h2>Management Team</h2>
                <div className="member">
                  <div className="name">Andrew Baluch</div>
                  <a className="email" href="mailto:andrew.baluch@markuplabs.com"><i className="far fa-envelope"></i> andrew.baluch@markuplabs.com</a>
                  <div className="role">Founder</div>
                  <ul className="details">
                    <li className="detail">Special Assistant to U.S. Patent &amp; Trademark Office Director</li>
                    <li className="detail">White House Intellectual Property Advisor</li>
                    <li className="detail">Partner, Smith Balluch LLP</li>
                  </ul>
                </div>
                <div className="member">
                  <div className="name">Peter Harter</div>
                  <a className="email" href="mailto:peter.harter@markuplabs.com"><i className="far fa-envelope"></i> peter.harter@markuplabs.com</a>
                  <div className="role">Founder</div>
                  <ul className="details">
                    <li className="detail">25+ Year Public Policy Veteran</li>
                    <li className="detail">Netscape, Securify, eMusic, Intellectual Ventures, The Farrington Group</li>
                  </ul>
                </div>
              </div>
              <div className="members">
                <h2>Founding Advisory Board</h2>
                <div className="member">
                  <div className="name">Mona Sutphen</div>
                  <ul className="details">
                    <li className="detail"><span className="group">Senior Advisor,</span> The Vistria Group (private equity)</li>
                    <li className="detail"><span className="group">Board member:</span> Pattern Energy Group (NASDAQ: PEGI); Pioneer Natural Resources (NYSE: PXD); International Rescue Committee; Human Rights First; Mount Holyoke College</li>
                    <li className="detail"><span className="group">Former:</span> White House Deputy Chief of Staff for Policy for President Obama; President’sIntelligence Advisory Board; National Security Council, President Clinton; Managing Director, UBS; Macro Advisory Partners</li>
                  </ul>
                </div>
                <div className="member">
                  <div className="name">Simon Rosenberg</div>
                  <ul className="details">
                    <li className="detail"><span className="group">President,</span> NDN and the New Policy Institute</li>
                    <li className="detail"><span className="group">Board member:</span> Tisch College of Civic Life at Tufts University; Henry Crown Fellow at the Aspen Institute</li>
                    <li className="detail"><span className="group">Advisor:</span> Open Source Election Technology Institute; DCCC and state Democratic parties on cybersecurity, information integrity</li>
                  </ul>
                </div>
                <div className="member">
                  <div className="name">Mike Platt</div>
                  <ul className="details">
                    <li className="detail"><span className="group">Founder,</span> Platt Strategic, Inc.</li>
                    <li className="detail"><span className="group">Former:</span> Assistant Secretary, Legislative &amp; Intergovernmental Affairs, US Department of Commerce; Chief of Staff, US Rep. Marsha Blackburn; Director, Government Affairs, Recording Industry Association of America (RIAA); Vice President, TechNet</li>
                  </ul>
                </div>
                <div className="member">
                  <div className="name">Hazen Marshall</div>
                  <ul className="details">
                    <li className="detail"><span className="group">Co-President,</span> Marshall &amp; Popp</li>
                    <li className="detail"><span className="group">Former:</span> Policy Director for Senate Majority Leader McConnell; Staff Director, Senate Budget Committee and Chief of Staff to Assistant Republican Leader</li>
                  </ul>
                </div>
                <div className="member">
                  <div className="name">Hugh Halpern*</div>
                  <ul className="details">
                    <li className="detail"><span className="group">Former,</span> Floor Director, U.S. House of Representatives (Speaker Paul Ryan); Staff Director, House Committee on Rules; General Counsel, House Committee on Financial Services; Parliamentarian, House Select Committee on Homeland Security; Parliamentarian, House Committee on Energy &amp; Commerce</li>
                  </ul>
                  <div className="notice">* Mr. Halpern left the Markup Advisory Board as of June 30, 2019 to return to public service.</div>
                </div>
              </div>
            </section>
          </div>
        </div>
          {!this.state.isRunningInsideMicrosoftTeams && <Footer />}
      </div>
    );
  }
}

export default AboutPage;
