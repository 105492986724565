import React from 'react';

import './PlaceholderShimmer.css';

export default function PlaceholderShimmer() {
  
return (
  <div className="placeholder-loader-container">
    <div className="placeholder-loader-header">
      <div className="placeholder-loader-nav">
        <div className="placeholder-loader-content">
          <div className="placeholder-loader-animated">
            <div className="placeholder-loader-parent"></div>
          </div>
        </div>  
        {/* <div className="placeholder-loader-nav-items">
          <i className="material-icons md-24">more_vert</i>
        </div> */}
      </div>
      <div className="placeholder-loader-content">
        <div className="placeholder-loader-animated">
          <div className="placeholder-loader-parent"></div>
          <div className="placeholder-loader-padding"></div>
          <div className="placeholder-loader-title"></div>
          <div className="placeholder-loader-padding"></div>
          <div className="placeholder-loader-followers"></div>
          <div className="placeholder-loader-padding"></div>
          <div className="placeholder-loader-description"></div>
          <div className="placeholder-loader-padding"></div>
        </div>
      </div>
    </div>
  </div>
  );
}
