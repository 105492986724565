import { BehaviorSubject } from 'rxjs';
import { handleResponse } from '../_helpers';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
  currentUser: currentUserSubject.asObservable(),

  get currentUserValue () { return currentUserSubject.value },

  login(emailAddress, password) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ emailAddress, password })
    };
  
    return fetch(`${process.env.REACT_APP_API_URL}/users/authentication`, requestOptions)
      .then(handleResponse)
      .then(user => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          currentUserSubject.next(user);
  
          return user;
      });
  },

  // finction that updated user data in memry and localstorage
  updateAuthenticatedUser(data) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const updatedUser = {
      ...currentUser,
      ...data
    }
    localStorage.setItem('currentUser', JSON.stringify(updatedUser));
    currentUserSubject.next(updatedUser);
    return updatedUser;
  },

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
  }
  
};
