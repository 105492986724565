import React from 'react';
import {Redirect} from 'react-router-dom';
import {getIssuedDate} from '../_helpers/date';
import {getVersionTitle} from '../_helpers/documents';
import './DocumentItem.css';

export default class DocumentItem extends React.Component {

    state = {
        document: this.props.document,
        searchQuery: this.props.searchQuery,
        isRunningInsideMicrosoftTeams: this.props.isRunningInsideMicrosoftTeams,
        redirect: null
    };

    showDocument = (packageId) => {
        let redirect = '/view/' + packageId + '/view?query=' + this.state.searchQuery;

        if (this.props.isRunningInsideMicrosoftTeams) {
            redirect += '&isRunningInsideMicrosoftTeams=' + this.state.isRunningInsideMicrosoftTeams;
        }
        this.setState({redirect});
    };

    onClickDocument = (packageId) => {
        const {isUsedForCFRAndUsCodes, isMicrosoftTeamsConfigurationTab} = this.props;

        if (isUsedForCFRAndUsCodes || isMicrosoftTeamsConfigurationTab) {
            this.props.onlBillClicked(packageId);
        } else {
            this.showDocument(packageId);
        }
    };

    getShortTitle = (title) => {
        const requiredLength = 35;

        let shortenedTitle = title;

        if (title.length >= requiredLength) {
            shortenedTitle = `${title.slice(0, requiredLength)}...`;
        }

        return shortenedTitle;
    };

    getShortDescription = (description) => {
        const requiredLength = 92;

        let shortenedDescription = description;

        if (description.length >= requiredLength) {
            shortenedDescription = `${description.slice(0, requiredLength)}...`;
        }

        return shortenedDescription;
    };

    render() {
        const {document} = this.state;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }

        const {selectedDocumentPackageId, isMicrosoftTeamsConfigurationTab} = this.props;

        return (
            <div
                className={`search-result ${(isMicrosoftTeamsConfigurationTab && selectedDocumentPackageId === document.packageId) ? 'selected' : ''}`}
                onClick={() => this.onClickDocument(document.packageId)}
            >
                <div className="search-result-header">
                    {
                        isMicrosoftTeamsConfigurationTab
                            ? (
                                <div className="search-result-title">{this.getShortTitle(document.title)} </div>
                            )
                            : (
                                <div className="search-result-title">{document.title} </div>
                            )
                    }
                </div>
                {/*<div className="search-result-name">{document.title}</div> */}
                {
                    isMicrosoftTeamsConfigurationTab
                        ? (
                            <div
                                className="search-result-description">{this.getShortDescription(document.description)}</div>
                        )
                        : (
                            <div className="search-result-description">{document.description}</div>
                        )
                }
                <div className="document-meta-tags">
                    <span className="meta-tag"
                          style={{"textTransform": "uppercase"}}>{document.type}.{document.number}</span>
                    <span className="meta-tag">{getVersionTitle(document.version)}</span>
                    <span className="meta-tag">{getIssuedDate(document.issuedAt)}</span>
                </div>
            </div>
        );
    }
}
