const {REACT_APP_LINKEDIN_STATE, REACT_APP_LINKEDIN_SCOPE, REACT_APP_LINKEDIN_CLIENT_ID} = process.env;

export const queryToObject = queryString => {
    const pairsString = queryString[0] === '?' ? queryString.slice(1) : queryString;
    const pairs = pairsString
        .split('&')
        .map(str => str.split('=').map(decodeURIComponent));

    return pairs.reduce((acc, [key, value]) => key ? { ...acc, [key]: value } : acc, {});
};

const getURLWithQueryParams = (base, params) => {
    const query = Object
        .entries(params)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join('&');

    return `${base}?${query}`
};


export const LINKEDIN_URL = getURLWithQueryParams('https://www.linkedin.com/oauth/v2/authorization', {
    response_type: 'code',
    client_id: REACT_APP_LINKEDIN_CLIENT_ID,
    redirect_uri: window.origin + '/linkedin-redirect',
    state: REACT_APP_LINKEDIN_STATE,
    scope: REACT_APP_LINKEDIN_SCOPE
});
