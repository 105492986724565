import React, {Component} from 'react';

import './EventItem.css';

class EventItem extends Component {

    constructor(props) {
        super(props);
    }

    isRedirectEvent = () => {
        const {selectedEventType} = this.props;

        return selectedEventType === 'Redirect';
    };

    render() {
        const {event, shouldDisplayGreyBackground} = this.props;

        return (
            <div className={`event-item ${shouldDisplayGreyBackground ? 'dark' : ''}`}>
                <div className="teams-app">
                    <span>{event.isMicrosoftTeams.toString()}</span>
                </div>
                <div className="email">
                    <span>{event.User.emailAddress}</span>
                </div>
                <div className="first-name">
                    <span>{event.User.firstName}</span>
                </div>
                <div className="last-name">
                    <span>{event.User.lastName}</span>
                </div>
                <div className="action-date">
                    <span>{event.actionDate}</span>
                </div>
                {
                    this.isRedirectEvent()
                        ? (
                            <div className="route">
                                <span>{event.route}</span>
                            </div>
                        )
                        : null
                }
            </div>
        )
    }
}

export default EventItem;
