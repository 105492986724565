import {authHeader, handleResponse} from '../_helpers';

export const mappingsService = {
    getUsCodeMappings(cfrTitle, cfrPart) {
        const requestOptions = {method: 'GET', headers: authHeader()};

        return fetch(`${process.env.REACT_APP_API_URL}/us-code-mappings?cfrTitle=${cfrTitle}&cfrPart=${cfrPart}`, requestOptions).then(handleResponse);
    },

    getCFRMappings(usCodeTitle, usCodeSection) {
        const requestOptions = {method: 'GET', headers: authHeader()};

        return fetch(`${process.env.REACT_APP_API_URL}/cfr-mappings?usCodeTitle=${usCodeTitle}&usCodeSection=${usCodeSection}`, requestOptions).then(handleResponse);
    },

    // TODO: avoid duplication

    getCfrParts(cfrParts = []) {
        return cfrParts.map(cfrPart => {
            const cfrParts = cfrPart.cfrParts;
            const cfrTitle = cfrPart.cfrTitle;
            const processedCfrParts = cfrParts.map(cfrPart => Object.assign({}, {value: cfrPart, label: cfrPart}));

            return Object.assign({}, {cfrTitle, cfrParts: processedCfrParts});
        });
    },

    getCftTitles(cfrTitles =[], titleListCFR = []) {
        const titles = titleListCFR

        return cfrTitles.map(cfrTitle => {
            const titleLabel = titles.find(title => title.value === cfrTitle).label;

            return Object.assign({}, {
                value: cfrTitle,
                label: titleLabel
            })
        });
    },

    getUsCodeSections(usCodeSections = []) {
        return usCodeSections.map(usCodeSection => {
            const usCodeSections = usCodeSection.usCodeSections;
            const usCodeTitle = usCodeSection.usCodeTitle;
            const processedUsCodeSections = usCodeSections.map(usCodeSection => Object.assign({}, {value: usCodeSection, label: usCodeSection}));

            return Object.assign({}, {usCodeTitle, usCodeSections: processedUsCodeSections});
        });
    },

    getUsCodeTitles(usCodeTitles = [], titleListUsCode = []) {
        const titles = titleListUsCode

        return usCodeTitles.map(usCodeTitle => {
            const titleLabel = titles.find(title => title.value === usCodeTitle).label;

            return Object.assign({}, {
                value: usCodeTitle,
                label: titleLabel
            })
        });
    }
};
