import React, {Component} from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import {Redirect, Link} from 'react-router-dom';
import './LoginPage.css';
import {authenticationService, userService, trackingsService} from '../_services';
import Logo from '../_components/Logo/Logo';
import {parse} from '../_helpers/query';
import {LINKEDIN_URL} from '../_helpers/linkedin';
import {linkedinService} from '../_services/linkedin.service';
import {TEAMS_STATIC_TABS} from '../_helpers/tabs';

const {REACT_APP_LINKEDIN_STATE} = process.env;

class LoginPage extends Component {

    popup;

    constructor(props) {
        super(props);

        this.state = {
            isDisabledButton: true,
            emailError: false,
            passwordError: false,
            loginError: false,
            emailAddress: '',
            password: '',
            redirect: authenticationService.currentUserValue ? '/' : null, // redirect user to home if authorized,
            isRunningInsideMicrosoftTeams: false,
            isMicrosoftTeamsConfigurationTab: false
        };
    }

    handleInputChange = async (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        await this.setState({[name]: value});
        // check if email and pass is not empty - enable signin button
        if (!this.state.emailAddress || !this.state.password) {
            !this.state.isDisabledButton && this.setState({isDisabledButton: true});
        } else {
            this.state.isDisabledButton && this.setState({isDisabledButton: false});
        }
    };

    // TODO: maybe move to service
    // it should be called only after microsoftTeams.initialize()
    getContext = () => {
        return new Promise((resolve, reject) => {
            microsoftTeams.getContext((context) => {
                resolve(context);
            });
        })
    };

    // TODO: maybe move to service
    // it should be called only after microsoftTeams.initialize()
    getTabs = () => {
        return new Promise((resolve, reject) => {
            microsoftTeams.getTabInstances((tabs) => {
                resolve(tabs);
            });
        });
    };

    getOpenedTabUrl = async () => {
        // get current microsoft teams context
        const context = await this.getContext();
        // get tabs created by given microsoft teams app
        const {teamTabs: tabs} = await this.getTabs();
        // get current tab id
        const entityId = context.entityId;
        // get current tab
        const openedTab = tabs.find(tab => tab.entityId === entityId);

        return openedTab && openedTab.url;
    };

    handleSubmit = async (event) => {
        const {isRunningInsideMicrosoftTeams, isMicrosoftTeamsConfigurationTab} = this.state;
        event.preventDefault();
        this.setState({isDisabledButton: true});
        try {
            const user = await authenticationService.login(this.state.emailAddress, this.state.password);
            const actionDate = trackingsService.getActionDateStringWithTimezone();
            trackingsService.silentLoginEventTracking(isRunningInsideMicrosoftTeams, this.state.emailAddress, actionDate);
            if (user && user.firstName) {
                if (isRunningInsideMicrosoftTeams && !isMicrosoftTeamsConfigurationTab) {
                    const context = await this.getContext();
                    const url = await this.getOpenedTabUrl();

                    // if url is not found then we know that it was not configuration tab (tab created by us)
                    // it is just a static tab
                    if (!url) {
                        let redirectUrl = TEAMS_STATIC_TABS[context.entityId] || '/';

                        this.props.history.push(redirectUrl);
                    } else {
                        // get url to go to after login
                        const redirectUrl = url.split(window.location.origin)[1];
                        // go to that page
                        this.props.history.push(redirectUrl);
                    }
                }

                if (isRunningInsideMicrosoftTeams && isMicrosoftTeamsConfigurationTab) {
                    this.props.history.push('/search');
                }

                if (!isRunningInsideMicrosoftTeams) {
                    // redirect to home page if authorized
                    this.props.history.push('/');
                }
            } else {
                this.setState({
                    loginError: false,
                    emailError: true,
                    passwordError: true,
                    isDisabledButton: false
                })
            }
        } catch (error) {
            console.log('Login error', error);
            this.setState({
                loginError: true,
                emailError: true,
                passwordError: true,
                isDisabledButton: false
            })
        }
    };

    async componentDidMount() {
        const isRunningInsideMicrosoftTeamsFromLocalStorage = localStorage.getItem('isRunningInsideMicrosoftTeams');
        const isRunningInsideMicrosoftTeams = isRunningInsideMicrosoftTeamsFromLocalStorage === 'true';

        this.setState({
            isRunningInsideMicrosoftTeams
        });

        if (isRunningInsideMicrosoftTeams) {
            microsoftTeams.getContext((context) => {
                if (context.frameContext === 'settings') {
                    this.setState({
                        isMicrosoftTeamsConfigurationTab: true
                    });
                }
            });
        }

        const queryObj = parse(this.props.location.search);
        if (queryObj && queryObj.email) {
            this.setState({emailAddress: queryObj.email});
        }

        if (queryObj && queryObj.activate) {
            await userService.activateUser(queryObj.activate);
        }
    }

    // invoke linkedin sign in modal and add listener to fetch code
    signInWithLinkedin = () => {
        this.popup = window.open(LINKEDIN_URL, '_blank', 'width=600,height=600');
        window.addEventListener('message', this.receiveLinkedInMessage)
    };

    receiveLinkedInMessage = async ({origin, data: {state, code, error, ...rest}}) => {
        const {isRunningInsideMicrosoftTeams} = this.state;

        try {
            if (origin !== window.location.origin || state !== REACT_APP_LINKEDIN_STATE) return;

            // if code is present then make request to node.js backend with this code and state
            if (code) {
                // get user data with linkedin signin
                const user = await linkedinService.signInWithLinkedIn(code, REACT_APP_LINKEDIN_STATE);
                const actionDate = trackingsService.getActionDateStringWithTimezone();
                trackingsService.silentLoginEventTracking(isRunningInsideMicrosoftTeams, user.emailAddress, actionDate);
                // save user data in local storage
                localStorage.setItem('currentUser', JSON.stringify(user));
                if (isRunningInsideMicrosoftTeams) {
                    const context = await this.getContext();
                    const url = await this.getOpenedTabUrl();

                    if (!url) {
                        let redirectUrl = TEAMS_STATIC_TABS[context.entityId] || '/';

                        window.location.replace(`${window.location.origin}${redirectUrl}`);
                    } else {
                        window.location.replace(url);
                    }
                } else {
                    // navigate to home page via replace (to reload the page)
                    window.location.replace(window.origin);
                }
            } else if (error && !['user_cancelled_login', 'user_cancelled_authorize'].includes(error)) {
                console.log('error is not related to user canceled login/authorize');
            }

            this.popup.close();
        } catch (err) {
            console.error(err);
            this.popup.close();
        }
    };

    // remove listeners in order to avoid memory leaks
    componentWillUnmount() {
        window.removeEventListener('message', this.receiveLinkedInMessage);
        this.popup && this.popup.close()
    }

    goToResetPassword = () => {
        const {isRunningInsideMicrosoftTeams} = this.state;

        if (isRunningInsideMicrosoftTeams) {
            microsoftTeams.getContext((context) => {
                if (context.frameContext === 'settings') {
                    window.open(window.location.origin + '/reset-password');
                } else {
                    this.props.history.push('/reset-password');
                }
            });
        } else {
            this.props.history.push('/reset-password');
        }
    };

    goToRegistration = () => {
        const {isRunningInsideMicrosoftTeams} = this.state;

        if (isRunningInsideMicrosoftTeams) {
            microsoftTeams.getContext((context) => {
                if (context.frameContext === 'settings') {
                    window.open(window.location.origin + '/registration');
                } else {
                    this.props.history.push('/registration');
                }
            });
        } else {
            this.props.history.push('/registration');
        }
    };

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }
        return (
            <div className="login-container">
                <div className="login-section">
                    <div className="logo-section">
                        <Link to="/">
                            <Logo/>
                        </Link>
                    </div>
                    {this.state.loginError &&
                    <div className="error-text ">INCORRECT CREDENTIALS</div>
                    }
                    <div className="form-section">
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-container">
                                <div className={"form-input" + (this.state.emailError ? ' error' : '')}>
                                    <label>
                                        <input type="text" name="emailAddress" onChange={this.handleInputChange}
                                               required value={this.state.emailAddress}/>
                                        <span className="placeholder">Email address</span>
                                    </label>
                                </div>
                                <div className={"form-input" + (this.state.passwordError ? ' error' : '')}>
                                    <label>
                                        <input type="password" name="password" onChange={this.handleInputChange}
                                               required/>
                                        <span className="placeholder">Password</span>
                                    </label>
                                </div>
                                <div className="form-actions">
                                    <button type="submit"
                                            className={"button blue-gradient w-100" + (this.state.isDisabledButton ? ' disabled' : '')}
                                            disabled={this.state.isDisabledButton ? 'disabled' : ''}>Sign In
                                    </button>
                                    {/* <div className="linkedin-signin" onClick={this.signInWithLinkedin}>
                                        <img alt="3 dots icon" src="/static/media/linkedin-signin-small.png"/>
                                    </div> */}
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="mt-2 need-to-signup">
                        Need to signup? <span onClick={this.goToRegistration} className="red-link">Create account</span>
                    </div>
                    <div className="mt-1 reset-password-link">
                        <div onClick={this.goToResetPassword}>Forgot password?</div>
                    </div>
                </div>
                <div className="image-section">
                    {/* <div className="slogan-text">
                        <h1>Connect.</h1>
                        <h1>Compare.</h1>
                        <h1>Collaborate.</h1>
                    </div> */}
                    <div className="slogan-text" style={{fontSize: 12, lineHeight: "0.9em;"}}>
                        <h1 style={{lineHeight: "0.85em"}}>Connect.</h1>
                        <h1 style={{lineHeight: "0.85em"}}>Compare.</h1>
                        <h1 style={{lineHeight: "0.85em"}}>Collaborate.</h1>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoginPage;
