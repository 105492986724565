import React from 'react';

import './HomePage.css';
import Header from '../_components/Header/Header';
import Footer from '../_components/Footer/Footer';
import { Redirect, Link } from 'react-router-dom';
import Logo from '../_components/Logo/Logo';

class HomePage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
      users: null,
      value: '',
      redirect: null,
      isVideoVisible: false
    };
  }

  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.setState({ redirect: `/search?query=${this.state.value}` });
    }
  };

  handleSearchClick = () => {
    this.setState({ redirect: `/search?query=${this.state.value}` });
  };

  showVideoPlayer = () => {
    this.setState({ isVideoVisible: true });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <div className="home-container">
        <div className="header-container">
          <div className="jumbotron">
            <Header logoColor="#ffffff" shouldLogoBeLight={true} />
            <div>
              <div className="stepsbox">
                <div className="stepsbox-header">
                  <div className="stepsbox-title">Compare.</div>
                  <div className="stepsbox-title">Connect.</div>
                  <div className="stepsbox-title">Collaborate.</div>
                  <div className="stepsbox-list">
                    <div className="stepsbox-step">
                      <div className="style-search">
                        <div className="form">
                          <div className="search-box">
                            <div className="prefix">
                              <span className="material-icons">search</span>
                            </div>
                            <input type="text"
                              placeholder="Search for U.S. Bills and Resolutions"
                              autoComplete="off"
                              className="text-field"
                              value={this.state.value}
                              onChange={this.handleChange}
                              onKeyDown={this.handleKeyDown} />
                          </div>
                          <div>
                            <button className="button blue-gradient" onClick={() => this.handleSearchClick()}>
                              <span className="material-icons">arrow_forward</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="stepsbox-steps">
                  <div className="stepsbox-video-container">
                    <iframe width="535" height="301"
                      src="https://www.youtube.com/embed/AIfwyVT1WDA"
                      allowFullScreen
                    >
                    </iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-container">
          <div className="small-blocks">
            <div className="small-item">
              <div className="img-item">
                <img alt="Word Icon" src="/static/media/word-1.svg" className="word-1" />
              </div>
              <div className="text">
                Redline and View Bills in Microsoft World
              </div>
            </div>
            <div className="small-item">
              <div className="img-item">
                <img alt="Side-By-Side Icon" src="/static/media/CompareDocuments_Icon.svg" className="layer-123" />
              </div>
              <div className="text">
                Review Amendments, Bills, U.S. Code and Regulations Side by Side
              </div>
            </div>
            <div className="small-item">
              <div className="img-item">
                <img alt="Microsoft Teams Icon" src="/static/media/microsoft-teams-1.svg" className="microsoft-team-1" />
              </div>
              <div className="text">
                Comment & Collaborate in Microsoft Teams
              </div>
            </div>
            <div className="small-item">
              <div className="img-item">
                <img alt="Adobe Icon" src="/static/media/BillCode_Icon.svg" className="group-3" />
              </div>
              <div className="text">
                Research historical context for laws 1992 to Present
              </div>
            </div>
          </div>
          <div className="content">
            <section className="instructions">
              <div className="container">
                <img alt="Infographic" src="/static/media/infographic.png" className="infographic"/> 
              </div>
            </section>
          </div>
        </div>
        <div className="get-started">
          <Link className="button blue-gradient" to="/search">Get Started!</Link>
          <a className="button blue-gradient" style={{ marginLeft: '24px' }} href="https://www.markuplabs.com/products">See more Markup Products</a>
        </div>
        <div className="get-started">
        </div>
        <Footer />
      </div>
    );
  }
}

export default HomePage;
