import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import loadScript from 'load-script2';

function PrizmDocViewerWrapper(props) {
    const [ready, setReady] = useState(false);
    const containerRef = useRef(null);

    useEffect(function asyncLoadRequiredViewerScripts() {
        (async () => {
            await loadScript(process.env.PUBLIC_URL + '/viewer-assets/js/jquery-3.4.1.min.js');
            await loadScript(process.env.PUBLIC_URL + '/viewer-assets/js/jquery.hotkeys.min.js');
            await loadScript(process.env.PUBLIC_URL + '/viewer-assets/js/underscore.min.js');
            await loadScript(process.env.PUBLIC_URL + '/viewer-assets/js/viewercontrol.js');
            await loadScript(process.env.PUBLIC_URL + '/viewer-assets/js/viewer.js');
            await loadScript(process.env.PUBLIC_URL + '/viewer-assets/js/viewerCustomizations.js');
            setReady(true);
        })();
    }, []); // The empty array ensures this useEffect hook is only executed once.

    useEffect(() => {
        if (ready && props.viewingSessionId) {
            window.$(containerRef.current).pccViewer({
                documentID: props.viewingSessionId,
                imageHandlerUrl: `${process.env.REACT_APP_BACKEND_BASE_URL}/pas-proxy`,     // Base path the viewer should use to make requests to PAS (PrizmDoc Application Services).
                viewerAssetsPath: `${process.env.PUBLIC_URL}/viewer-assets/`, // Base path the viewer should use for static assets
                resourcePath: 'viewer-assets/img', // Base path the viewer should use for images
                language: window.viewerCustomizations.languages['en-US'],
                template: window.viewerCustomizations.template,
                icons: window.viewerCustomizations.icons,
                annotationsMode: "LayeredAnnotations", // Use the new "LayeredAnnotations" system, which will persist annotation data as JSON (instead of the default "LegacyAnnotations" system, which uses a different XML format)
                redactionReasons: {
                    enableRedactionReasonSelection: true, // Enable the UI to allow users to select a redaction reason.
                    enableFreeformRedactionReasons: true, // Allow users to type a custom redaction reason.
                    enableMultipleRedactionReasons: true, // Allow users to apply multiple redaction reasons to a single redaction (requires a backend running version 13.13 or higher)

                    // TODO: Define your own set of redaction reasons for your users to pick from:
                    reasons: [{
                        reason: '1.a',                   // Text to apply to the redaction itself.
                        description: 'Client Privilege'  // Optional extended description the user will see when choosing from the list of redaction reasons.
                    }, {
                        reason: '1.b',
                        description: 'Privacy Information'
                    }, {
                        reason: '1.c'
                    }]
                }
            });
        }
    }, [props.viewingSessionId, ready]);

    // console.log(ready, props.viewingSessionId)

    return (
        <>
            <Helmet>
                {/* Ensures the viewer works best across various browsers and devices */}
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1 user-scalable=no" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />

                {/* CSS required by the viewer */}
                <link rel="stylesheet" href={process.env.PUBLIC_URL + "/viewer-assets/css/normalize.min.css"} />
                <link rel="stylesheet" href={process.env.PUBLIC_URL + "/viewer-assets/css/viewer.css"} />
            </Helmet>
            { (ready && props.viewingSessionId) &&
            <div ref={containerRef} style={props.style} />
            }
        </>
    );
}

export default PrizmDocViewerWrapper;
