export const parse = (queryString) => {
  const queryObj = {};
  // remove symbol "?" if exists in string
  const params = decodeURI(queryString).replace('?', '').split('&');
  for (let param of params) {
    const exploded = param.split('=');
    if(exploded){
       queryObj[exploded[0]] = exploded[1];
    }
   
  }
  return queryObj;
}