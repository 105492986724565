import React, {Component} from 'react';
import queryString from 'query-string';
import {toast} from 'react-toastify';
import './ResetPasswordPage.css';
import {Link} from "react-router-dom";
import Logo from '../_components/Logo/Logo';
import {userService} from '../_services';

class ResetPasswordPage extends Component {

    errors = {
        invalidPasswordLength: 'Password must be between 6-32 characters long',
        invalidConfirmPasswordLength: 'Confirm password must be between 6-32 characters long',
        passwordsDontMatch: 'Password does not match confirm password',
        invalidPasswordPattern: 'Password should contains a combination of uppercase and lowercase letter, special character and number are required'
    };

    modes = {
        sendResetPasswordEmail: 'sendResetPasswordEmail',
        resetPassword: 'resetPassword'
    };

    constructor(props) {
        super(props);

        this.state = {
            // holds page's mode
            mode: this.modes.sendResetPasswordEmail,
            // props for sendResetPasswordEmail mode
            shouldDisableSendResetPasswordEmailButton: true,
            isEmailError: false,
            emailErrorMessage: '',
            emailAddress: '',
            isResetPasswordEmailSent: false,
            // props for resetPassword mode
            password: '',
            confirmPassword: '',
            token: null,
            emailToDisplay: null,
            isResetPasswordError: false,
            resetPasswordError: '',
            shouldDisableResetPasswordButton: true
        };
    }

    componentDidMount() {
        const values = queryString.parse(this.props.location.search);
        const {token, email} = values;

        if (token && email) {
            this.setState({
                mode: this.modes.resetPassword,
                emailToDisplay: email,
                token
            });
        } else {
            this.setState({
                mode: this.modes.sendResetPasswordEmail
            });
        }
    }

    handleInputChange = async (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        await this.setState({[name]: value});

        if (!this.state.emailAddress) {
            this.setState({
                shouldDisableSendResetPasswordEmailButton: true
            });
        }
        if (this.state.emailAddress) {
            this.setState({
                shouldDisableSendResetPasswordEmailButton: false
            });
        }
        if (this.state.password && this.state.confirmPassword) {
            this.setState({
                shouldDisableResetPasswordButton: false
            });
        }
        if (!this.state.password || !this.state.confirmPassword) {
            this.setState({
                shouldDisableResetPasswordButton: true
            });
        }
    };

    isResetPasswordMode = () => {
        const {mode} = this.state;

        return mode === this.modes.resetPassword;
    };

    isSendResetPasswordEmailLink = () => {
        const {mode} = this.state;

        return mode === this.modes.sendResetPasswordEmail;
    };

    sendResetPasswordEmail = async () => {
        try {
            const {emailAddress} = this.state;

            await userService.sendResetPasswordEmail(emailAddress);

            this.setState({
                isEmailError: false,
                emailErrorMessage: '',
                isResetPasswordEmailSent: true
            });
        } catch (err) {
            console.error(err);

            this.setState({
                isEmailError: true,
                emailErrorMessage: err
            });
        }
    };

    cancelResetPassword = async() =>{
        this.props.history.push('/login');
    };

    resetPassword = async () => {
        try {
            const passwordPattern = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,32}$/;
            const {password, confirmPassword, token} = this.state;

            if (password !== confirmPassword) {
                this.setState({
                    isResetPasswordError: true,
                    resetPasswordError: this.errors.passwordsDontMatch
                });

                return;
            }

            if (password.length < 6 || password.length > 32) {
                this.setState({
                    isResetPasswordError: true,
                    resetPasswordError: this.errors.invalidPasswordLength
                });

                return;
            }

            if (!passwordPattern.test(password)){
                this.setState({
                    isResetPasswordError: true,
                    resetPasswordError  :this.errors.invalidPasswordPattern
                })

                return;
            }

            if (confirmPassword.length < 6 || confirmPassword.length > 32) {
                this.setState({
                    isResetPasswordError: true,
                    resetPasswordError: this.errors.invalidConfirmPasswordLength
                });

                return;
            }
            
            if (!passwordPattern.test(confirmPassword)){
                this.setState({
                    isResetPasswordError: true,
                    resetPasswordError  :this.errors.invalidPasswordPattern
                })
                
                return;
            }

            await userService.resetPassword(token, password, confirmPassword);

            this.setState({
                isResetPasswordError: false,
                resetPasswordError: ''
            });
            toast.info('Your password has been changed succesfully.');
            this.props.history.push('/login');

        } catch (err) {
            console.error(err);

            this.setState({
                isResetPasswordError: true,
                resetPasswordError: err
            });
        }
    };

    render() {
        const {
            shouldDisableSendResetPasswordEmailButton,
            emailAddress,
            isEmailError,
            emailErrorMessage,
            isResetPasswordEmailSent,
            password,
            confirmPassword,
            isResetPasswordError,
            resetPasswordError,
            shouldDisableResetPasswordButton,
            emailToDisplay
        } = this.state;

        return (
            <div className="reset-password-container">
                <div className="reset-password-section">
                    <div className="logo-section">
                        <Link to="/">
                            <Logo/>
                        </Link>
                    </div>
                    <div className="form-section">
                        {
                            this.isResetPasswordMode() && (
                                <form>
                                    <h2 className="reset-password-title">Reset password</h2>
                                    <div className="form-container">
                                        <div className={"form-input"}>
                                            {
                                                isResetPasswordError && (
                                                    <label className="error-text">{resetPasswordError}</label>
                                                )
                                            }
                                            <label>
                                                <input
                                                    type="email"
                                                    name="emailAddress"
                                                    value={emailToDisplay}
                                                />
                                                <span className="placeholder">Email address</span>
                                            </label>
                                        </div>
                                        <div className={"form-input"}>
                                            <label>
                                                <input
                                                    type="password"
                                                    name="password"
                                                    value={password}
                                                    onChange={this.handleInputChange}
                                                    required
                                                />
                                                <span className="placeholder">Password</span>
                                            </label>
                                        </div>
                                        <div className={"form-input"}>
                                            <label>
                                                <input
                                                    type="password"
                                                    name="confirmPassword"
                                                    value={confirmPassword}
                                                    onChange={this.handleInputChange}
                                                    required
                                                />
                                                <span className="placeholder">Confirm password</span>
                                            </label>
                                        </div>
                                        <div className="form-actions">
                                            <button
                                                type="button"
                                                className={"button blue-gradient w-100" + (shouldDisableResetPasswordButton ? ' disabled' : '')}
                                                disabled={shouldDisableResetPasswordButton ? 'disabled' : ''}
                                                onClick={this.resetPassword}
                                            >Reset password
                                            </button>
                                            <button
                                                type="button"
                                                className={"button button-red w-100 cancelBtn"}
                                                onClick={this.cancelResetPassword}
                                            >Cancel
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            )
                        }
                        {
                            this.isSendResetPasswordEmailLink() && isResetPasswordEmailSent && (
                                <div>
                                    <h2 className="reset-password-title">Check your inbox</h2>
                                    <p className="reset-password-description">A recovery e-mail has been sent
                                        to <span>{emailAddress}</span> with instructions on how to reset your password.
                                    </p>
                                    <p className="link-expiration-description">Your recovery link will expire after 12
                                        hours</p>
                                </div>
                            )
                        }
                        {
                            this.isSendResetPasswordEmailLink() && !isResetPasswordEmailSent && (
                                <form>
                                    <h2 className="reset-password-title">Reset password</h2>
                                    <div className="form-container">
                                        <div className={"form-input" + (isEmailError ? ' error' : '')}>
                                            {
                                                isEmailError && (
                                                    <label className="error-text">{emailErrorMessage}</label>
                                                )
                                            }
                                            <label>
                                                <input
                                                    type="email"
                                                    name="emailAddress"
                                                    value={emailAddress}
                                                    onChange={this.handleInputChange}
                                                    required
                                                />
                                                <span className="placeholder">Email address</span>
                                            </label>
                                        </div>
                                        <div className="form-actions">
                                            <button
                                                type="button"
                                                className={"button blue-gradient w-100" + (shouldDisableSendResetPasswordEmailButton ? ' disabled' : '')}
                                                disabled={shouldDisableSendResetPasswordEmailButton ? 'disabled' : ''}
                                                onClick={this.sendResetPasswordEmail}
                                            >Reset password
                                            </button>
                                        </div>
                                        <div className="affiliation-field">
                                        <div className="checkbox-label">If you can't reset your password, please <Link
                                            to="/help" target="_blank"
                                            className="red-link">Contact Us
                                        </Link>
                                        </div>
                                    </div>
                                    </div>
                                </form>
                            )
                        }
                    </div>
                </div>
                <div className="image-section">
                    <div className="slogan-text" style={{fontSize: 12, lineHeight: "0.9em;"}}>
                        <h1 style={{lineHeight: "0.85em"}}>Connect.</h1>
                        <h1 style={{lineHeight: "0.85em"}}>Compare.</h1>
                        <h1 style={{lineHeight: "0.85em"}}>Collaborate.</h1>
                    </div>
                    {/* <div className="slogan-text" style={{fontSize: 12, lineHeight: "0.85em;"}}>
                        <h1 style={{lineHeight: "0.85em"}}>Modern</h1>
                        <h1 style={{lineHeight: "0.85em"}}>Lawmaking:</h1>
                        <br/>
                        <h1 style={{lineHeight: "0.85em"}}>Connect.</h1>
                        <h1 style={{lineHeight: "0.85em"}}>Compare.</h1>
                        <h1 style={{lineHeight: "0.85em"}}>Collaborate.</h1>
                    </div> */}
                </div>
            </div>
        );
    }
}

export default ResetPasswordPage;
