import {authHeader, handleResponse} from "../_helpers";

// TODO: this data will be probably moved to the BE
const YEARS = [];
const TITLES = [];
// const YEARS = [
//     // I was asked to stop using this for us codes
//     // {
//     //     value: 'Most recent',
//     //     label: 'Most recent'
//     // },
//     {
//         value: 2018,
//         label: '2018'
//     },
//     {
//         value: 2017,
//         label: '2017'
//     },
//     {
//         value: 2016,
//         label: '2016'
//     },
//     {
//         value: 2015,
//         label: '2015'
//     },
//     {
//         value: 2014,
//         label: '2014'
//     },
//     {
//         value: 2013,
//         label: '2013'
//     },
//     {
//         value: 2012,
//         label: '2012'
//     },
//     {
//         value: 2011,
//         label: '2011'
//     },
//     {
//         value: 2010,
//         label: '2010'
//     },
//     {
//         value: 2009,
//         label: '2009'
//     },
//     {
//         value: 2008,
//         label: '2008'
//     },
//     {
//         value: 2007,
//         label: '2007'
//     },
//     {
//         value: 2006,
//         label: '2006'
//     },
//     {
//         value: 2005,
//         label: '2005'
//     },
//     {
//         value: 2004,
//         label: '2004'
//     },
//     {
//         value: 2003,
//         label: '2003'
//     },
//     {
//         value: 2002,
//         label: '2002'
//     },
//     {
//         value: 2001,
//         label: '2001'
//     },
//     {
//         value: 2000,
//         label: '2000'
//     },
//     {
//         value: 1999,
//         label: '1999'
//     },
//     {
//         value: 1998,
//         label: '1998'
//     },
//     {
//         value: 1997,
//         label: '1997'
//     },
//     {
//         value: 1996,
//         label: '1996'
//     },
//     {
//         value: 1995,
//         label: '1995'
//     },
//     {
//         value: 1994,
//         label: '1994'
//     }
// ];

// const TITLES = [
//     {value: 1, label: 'Title 1 - General Provisions'},
//     {value: 2, label: 'Title 2 - The Congress'},
//     {value: 3, label: 'Title 3 - The President'},
//     {value: 4, label: 'Title 4 - Flag And Seal, Seat Of Government, And The States'},
//     {value: 5, label: 'Title 5 - Government Organization And Employees'},
//     {value: 6, label: 'Title 6 - Domestic Security'},
//     {value: 7, label: 'Title 7 - Agriculture'},
//     {value: 8, label: 'Title 8 - Aliens And Nationality'},
//     {value: 9, label: 'Title 9 - Arbitration'},
//     {value: 10, label: 'Title 10 - Armed Forces'},
//     {value: 11, label: 'Title 11 - Bankruptcy'},
//     {value: 12, label: 'Title 12 - Banks And Banking'},
//     {value: 13, label: 'Title 13 - Census'},
//     {value: 14, label: 'Title 14 - Coast Guard'},
//     {value: 15, label: 'Title 15 - Commerce And Trade'},
//     {value: 16, label: 'Title 16 - Conservation'},
//     {value: 17, label: 'Title 17 - Copyrights'},
//     {value: 18, label: 'Title 18 - Crimes And Criminal Procedure'},
//     {value: 19, label: 'Title 19 - Customs Duties'},
//     {value: 20, label: 'Title 20 - Education'},
//     {value: 21, label: 'Title 21 - Food And Drugs'},
//     {value: 22, label: 'Title 22 - Foreign Relations And Intercourse'},
//     {value: 23, label: 'Title 23 - Highways'},
//     {value: 24, label: 'Title 24 - Hospitals And Asylums'},
//     {value: 25, label: 'Title 25 - Indians'},
//     {value: 26, label: 'Title 26 - Internal Revenue Code'},
//     {value: 27, label: 'Title 27 - Intoxicating Liquors'},
//     {value: 28, label: 'Title 28 - Judiciary And Judicial Procedure; and Appendix'},
//     {value: 29, label: 'Title 29 - Labor'},
//     {value: 30, label: 'Title 30 - Mineral Lands And Mining'},
//     {value: 31, label: 'Title 31 - Money And Finance'},
//     {value: 32, label: 'Title 32 - National Guard'},
//     {value: 33, label: 'Title 33 - Navigation And Navigable Waters'},
//     {value: 34, label: 'Title 34 - Crime Control And Law Enforcement'},
//     {value: 35, label: 'Title 35 - Patents'},
//     {value: 36, label: 'Title 36 - Patriotic And National Observances, Ceremonies, And Organizations'},
//     {value: 37, label: 'Title 37 - Pay And Allowances Of The Uniformed Services'},
//     {value: 38, label: 'Title 38 - Veteran Benefits'},
//     {value: 39, label: 'Title 39 - Postal Service'},
//     {value: 40, label: 'Title 40 - Public Buildings, Property, And Works'},
//     {value: 41, label: 'Title 41 - Public Contracts'},
//     {value: 42, label: 'Title 42 - The Public Health And Welfare'},
//     {value: 43, label: 'Title 43 - Public Lands'},
//     {value: 44, label: 'Title 44 - Public Printing And Documents'},
//     {value: 45, label: 'Title 45 - Railroads'},
//     {value: 46, label: 'Title 46 - Shipping'},
//     {value: 47, label: 'Title 47 - Telecommunications'},
//     {value: 48, label: 'Title 48 - Territories And Insular Possessions'},
//     {value: 49, label: 'Title 49 - Transportation'},
//     {value: 50, label: 'Title 50 - War And National Defense'},
//     {value: 51, label: 'Title 51 - National And Commercial Space Programs'},
//     {value: 52, label: 'Title 52 - Voting And Elections'},
//     {value: 53, label: 'Title 53 - [Reserved]'},
//     {value: 54, label: 'Title 54 - National Park Service And Related Programs'}
// ];

export const usCodeService = {
    getUsCode(title, year, section) {
        const requestOptions = {method: 'GET', headers: authHeader()};

        return fetch(`${process.env.REACT_APP_API_URL}/us-codes?title=${title}&year=${year}&section=${section}`, requestOptions).then(handleResponse);
    },

    downloadUsCode(title, year, section) {
        const requestOptions = {method: 'GET', headers: authHeader()};

        return fetch(`${process.env.REACT_APP_API_URL}/us-codes/download?title=${title}&year=${year}&section=${section}`, requestOptions)
    },

    getYears() {
        return YEARS;
    },

    getTitles() {
        return TITLES;
    }
};
