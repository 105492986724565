import React, {Component} from 'react';
import queryString from 'query-string';
import Modal from 'react-responsive-modal';
import Pagination from 'rc-pagination';

import './USCodeViewPage.css';
import {DownloadService} from '../_services/download-file.service';
import EmptyResults from '../_components/EmptyResults/EmptyResults';
import Header from '../_components/Header/Header';
import Footer from '../_components/Footer/Footer';
import {usCodeService} from '../_services/us-code.service';
import {CFRService} from '../_services/cfr.service';
import PDFViewer from '../_components/PdfViewer/PdfViewer';
import PlaceholderShimmer from '../_components/PlaceholderShimmer/PlaceholderShimmer';
import DownloadFile from '../_components/DownloadFile/DownloadFile';
import DocumentViewer from '../_components/DocumentViewer/DocumentViewer';
import {mappingsService} from '../_services/mappings.service';
import DocumentList from '../SearchPage/DocumentsList';
import {documentService} from "../_services/document.service";
import { convertResponseToValueLabelForTitle} from '../_helpers/handleConfigResponse';
import {cfgService} from '../_services/config.service';
var Constant = require('../_constants');
class USCodeViewPage extends Component {

    titles = [];

    constructor(props) {
        super(props);
        this.state = {
            isRunningInsideMicrosoftTeams: false,
            // this property is used to manage modal window for bill search
            modalOpen: false,
            // properties for View mode (just to display a document)
            title: null,
            section: null,
            titleListUsCode : [],
            titleListCFR : [],
            year: null,
            usCodeData: null,
            isFetching: false,
            // flags to determine which mode is used
            isConnect: false,
            isView: false,
            // array that holds documents data for connect mode
            documents: [
                {
                    id: 1,
                    year: null,
                    documentLink: null,
                    section: null,
                    part: null,
                    title: null,
                    name: 'Document A - U.S. Code',
                    mode: 'view-document',
                    bill: null,
                    isLoading: false
                },
                {
                    id: 2,
                    year: null,
                    documentLink: null,
                    section: null,
                    part: null,
                    title: null,
                    name: 'Document B - CFR',
                    mode: 'view-document',
                    bill: null,
                    isLoading: false
                },
                {
                    id: 3,
                    year: null,
                    documentLink: null,
                    section: null,
                    part: null,
                    title: null,
                    name: 'Document C',
                    mode: 'search-cfr',
                    bill: null,
                    isLoading: false
                }
            ],
            // these properties hold data for selects when us code has mapped cfrs
            connectedCfrTitles: [],
            connectedCfrParts: [],
            // these props are used for connect us code
            usCodeYear: null,
            usCodeTitle: null,
            usCodeSection: null,
            // these props are used for connect cfr
            connectedCfrTitleNumber: null,
            connectedCfrPartNumber: null,
            connectedCfrYear: null,
            // props for bill search
            bills: [],
            currentPage: 0,
            billsCount: 0,
            value: '',
            areBillsFetching: false,
            // this fields is used to hold the info which viewer invoked bill search modal
            documentId: null
        };
    }

    async componentDidMount() {
        const isRunningInsideMicrosoftTeamsFromLocalStorage = localStorage.getItem('isRunningInsideMicrosoftTeams');
        const isRunningInsideMicrosoftTeams = isRunningInsideMicrosoftTeamsFromLocalStorage === 'true';
        var CFRTitles = await cfgService.getTitles(Constant.CodeType_CFR_Title_String);
        this.setState({titleListCFR:(convertResponseToValueLabelForTitle(CFRTitles))})  

        var USCodeTitles = await cfgService.getTitles(Constant.CodeType_USCode_Title_String);
        this.setState({titleListUsCode:(convertResponseToValueLabelForTitle(USCodeTitles))})  

        this.setState({
            isRunningInsideMicrosoftTeams
        });

        const values = queryString.parse(this.props.location.search);
        const isView = values.isView === 'true';
        const isConnect = values.isConnect === 'true';

        try {
            if (isView) {
                await this.setState({
                    title: +values.title,
                    section: +values.section,
                    year: +values.year,
                    isView
                });

                const {title, section, year} = this.state;

                this.setState({
                    isFetching: true
                });

                const response = await usCodeService.getUsCode(title, year, section);

                this.setState({
                    isFetching: false,
                    usCodeData: response
                });
            }

            if (isConnect) {
                let updatedDocuments;
                const {documents} = this.state;

                // get params from route when we go from view page to connect
                await this.setState({
                    // us code data
                    usCodeYear: +values.usCodeYear,
                    usCodeTitle: +values.usCodeTitle,
                    usCodeSection: values.usCodeSection,
                    // connected cfr data
                    connectedCfrTitleNumber: +values.connectedCfrTitleNumber,
                    connectedCfrPartNumber: values.connectedCfrPartNumber,
                    connectedCfrYear: +values.connectedCfrYear,
                    isConnect
                });

                const {usCodeYear, usCodeTitle, usCodeSection, connectedCfrTitleNumber, connectedCfrPartNumber, connectedCfrYear} = this.state;

                // set isLoading flag true for first 2 documents
                updatedDocuments = documents.map(document => (document.id === 1 || document.id === 2) ? Object.assign({...document}, {isLoading: true}) : document);
                this.setState({documents: updatedDocuments});

                // get data for us code (document link)
                const usCodeResponse = await usCodeService.getUsCode(usCodeTitle, usCodeYear, usCodeSection);
                // get data for connected cfr
                const connectedCfrResponse = await CFRService.getCFR(connectedCfrTitleNumber, connectedCfrYear, null, connectedCfrPartNumber);
                // get mappings for given cfr
                const mappingsResponse = await mappingsService.getCFRMappings(usCodeTitle, usCodeSection);
                // prepare titles for selects
                const connectedCfrTitles = this.getCftTitles(mappingsResponse.cfrTitles);
                const connectedCfrParts = this.getCfrParts(mappingsResponse.groupedCfrParts);

                let usCodeData, connectedCfrData;

                if(usCodeResponse.isPresent) {
                    usCodeData = {
                        year: usCodeYear,
                        documentLink: usCodeResponse.link,
                        section: usCodeSection,
                        part: null,
                        title: usCodeTitle,
                        isLoading: false
                    };
                } else {
                    usCodeData = {
                        year: null,
                        documentLink: null,
                        section: null,
                        part: null,
                        title: null,
                        isLoading: false
                    }
                }

                if(connectedCfrResponse.isPresent) {
                    connectedCfrData = {
                        year: connectedCfrYear,
                        documentLink: connectedCfrResponse.link,
                        section: null,
                        part: connectedCfrPartNumber,
                        title: connectedCfrTitleNumber,
                        isLoading: false
                    };
                } else {
                    connectedCfrData = {
                        year: null,
                        documentLink: null,
                        section: null,
                        part: null,
                        title: null,
                        isLoading: false
                    };
                }

                const usCodeDocument = Object.assign({...documents[0]}, usCodeData);
                const connectedCfrDocument = Object.assign({...documents[1]}, connectedCfrData);

                // first two documents should be updated and the 3rd one should be left as it is
                updatedDocuments = [usCodeDocument, connectedCfrDocument, documents[2]];

                this.setState({
                    documents: updatedDocuments,
                    connectedCfrTitles,
                    connectedCfrParts
                });
            }

        } catch (err) {
            console.error(err);

            if(isView) {
                this.setState({
                    isFetching: false
                });
            } else {
                const {documents} = this.state;
                const updatedDocuments = documents.map(document => (document.id === 1 || document.id === 2) ? Object.assign({...document}, {isLoading: false}) : document);
                this.setState({
                    documents: updatedDocuments
                });
            }
        }
    }

    // this is gonna be used for hide/show purposes
    isCfrMappingPresent = () => {
        const {connectedCfrParts, connectedCfrTitles} = this.state;

        return connectedCfrParts.length && connectedCfrTitles.length;
    };

    getCfrParts = (cfrParts = []) => {
        return mappingsService.getCfrParts(cfrParts);
    };

    getCftTitles = (cfrTitles = []) => {
        return mappingsService.getCftTitles(cfrTitles, this.state.titleListCFR);
    };

    goBack = () => {
        const {isView} = this.state;

        let backUrl = null;

        if(isView) {
            const {title, section, year} = this.state;

            backUrl = `/us-code-search?usCodeYear=${year}&usCodeTitle=${title}&usCodeSection=${section}`;
        } else {
            const {documents} = this.state;
            const usCode = documents[0];

            backUrl = `/us-code-search?usCodeYear=${usCode.year}&usCodeTitle=${usCode.title}&usCodeSection=${usCode.section}`;
        }

        this.props.history.push(backUrl);
    };

    goView = async () => {
        const {usCodeYear, usCodeTitle, usCodeSection, documents} = this.state;

        // if we clicked view from connect then we should display first document that should always be us code
        if (usCodeYear && usCodeTitle && usCodeSection) {
            await this.setState({
                title: usCodeTitle,
                section: usCodeSection,
                year: usCodeYear,
                usCodeData: {
                    isPresent: true,
                    link: documents[0].documentLink
                }
            });
        }

        // change view
        this.setState({
            isConnect: false,
            isView: true
        });
    };

    goConnect = async () => {
        const {title, section, year, usCodeData, documents} = this.state;

        // if we clicked go connect from view we should take document from view and display it as first document in documents array
        if (title && section && year && usCodeData) {
            const documentData = {
                year,
                title,
                section,
                documentLink: usCodeData.link
            };

            // get cfr mappings for given us code (the one that was on view screen)
            const mappingsResponse = await mappingsService.getCFRMappings(title, section);
            // prepare titles & parts for selects
            const connectedCfrTitles = this.getCftTitles(mappingsResponse.cfrTitles);
            const connectedCfrParts = this.getCfrParts(mappingsResponse.groupedCfrParts);

            // we need to set it here, 'cause the next function (isCfrMappingPresent) depends on this props from state
            await this.setState({
                connectedCfrParts,
                connectedCfrTitles
            });

            // set connected-cfr mode if there are mappings for a given us code otherwise set mode to search-cfr
            const mode = this.isCfrMappingPresent() ? 'connected-cfr' : 'search-cfr';

            const updatedDocuments = documents.map(doc => {
                let document = null;

                if (doc.id === 1) {
                    document = Object.assign({...doc}, documentData);
                } else if (doc.id === 2) {
                    document = Object.assign({...doc}, {mode})
                } else {
                    document = doc;
                }

                return document;
            });

            await this.setState({
                documents: updatedDocuments
            });
        }

        this.setState({
            isConnect: true,
            isView: false
        });
    };

    getSelectedTitleLabel = (titleValue) => {
        const target = this.state.titleListUsCode.find(title => title.value === titleValue);

        return target ? target.label : 'Default label';
    };

    onDownload = async (type, data) => {
        const response = await DownloadService.getFileRequest(type, data);

        var responseFileURL = (await response.json()).fileUrl
        const downloadLink = document.createElement("a");
        downloadLink.href = responseFileURL;
        downloadLink.download = "test";
        downloadLink.click()
    };

    getDocumentNameByIdAndType = (id, type) => {
        let documentName;

        if(id === 3) {
            documentName = `Document C - ${type === 'cfr' ? 'CFR' : 'Bill'}`;
        } else if(id === 2) {
            documentName = `Document B - ${type === 'cfr' ? 'CFR' : 'Bill'}`;
        } else {
            documentName = 'Document A - US Code';
        }

        return documentName;
    };

    onCfrSearch = async (id, searchCfrYear, searchCfrTitle, searchCfrPart, searchCfrSection) => {
        try {
            let updatedDocuments;
            let documentData;
            const {documents} = this.state;

            // set isLoading flag to true for given document (id)
            updatedDocuments = documents.map(document => document.id === id ? Object.assign({...document}, {
                isLoading: true,
                mode: 'view-document'
            }) : document);
            this.setState({documents: updatedDocuments});

            // fetch cfr by given data
            const response = await CFRService.getCFR(searchCfrTitle, searchCfrYear, searchCfrSection, searchCfrPart);

            if (response.isPresent) {
                documentData = {
                    year: searchCfrYear,
                    documentLink: response.link,
                    section: searchCfrSection,
                    part: searchCfrPart,
                    title: searchCfrTitle,
                    // I need to do it, 'cause otherwise we can run into the situation when previous mode was view bill
                    // and If I don't do this then the if statement in componentWillReceiveProps (DocumentViewer) hook will be fired and view bill mode will be set
                    bill: null,
                    isLoading: false,
                    name: this.getDocumentNameByIdAndType(id, 'cfr'),
                    mode: 'view-document'
                };
            } else {
                // nullify document's data to display document not found thing
                documentData = {
                    year: null,
                    documentLink: null,
                    section: null,
                    part: null,
                    bill: null,
                    isLoading: false,
                    mode: 'view-document'
                };
            }

            updatedDocuments = documents.map(document => document.id === id ? Object.assign({...document}, documentData) : document);

            this.setState({
                documents: updatedDocuments
            });
        } catch (err) {
            console.error(err);
            // in case of error set isLoading flag to false for given document
            const {documents} = this.state;
            let updatedDocuments;

            updatedDocuments = documents.map(document => document.id === id ? Object.assign({...document}, {isLoading: false}) : document);

            this.setState({
                documents: updatedDocuments
            });
        }
    };

    onCfrConnect = async (id, connectCfrTitle, connectCfrPart) => {
        try {
            // now we use only 2018 mappings
            const year = 2018;
            let updatedDocuments;
            let connectedCfrData;
            const {documents} = this.state;

            // set isLoading flag to true for given document (id)
            updatedDocuments = documents.map(document => document.id === id ? Object.assign({...document}, {
                isLoading: true,
                mode: 'view-document'
            }) : document);
            this.setState({documents: updatedDocuments});

            // now we use only 2018 mappings
            const response = await CFRService.getCFR(connectCfrTitle, year, null, connectCfrPart);

            if (response.isPresent) {
                connectedCfrData = {
                    year,
                    documentLink: response.link,
                    title: connectCfrTitle,
                    part: connectCfrPart,
                    // I need to do it, 'cause otherwise we can run into the situation when previous mode was view bill
                    // and If I don't do this then the if statement in componentWillReceiveProps hook will be fired and view bill mode will be set
                    mode: 'view-document',
                    name: this.getDocumentNameByIdAndType(id, 'cfr'),
                    isLoading: false,
                    bill: null
                };
            } else {
                connectedCfrData = {
                    year: null,
                    documentLink: null,
                    title: null,
                    part: null,
                    mode: 'view-document',
                    isLoading: false,
                    bill: null
                };
            }

            updatedDocuments = documents.map(document => document.id === id ? Object.assign({...document}, connectedCfrData) : document);

            this.setState({
                documents: updatedDocuments
            });
        } catch (err) {
            console.error(err);
            // in case of error set isLoading flag to false for given document
            const {documents} = this.state;
            let updatedDocuments;

            updatedDocuments = documents.map(document => document.id === id ? Object.assign({...document}, {isLoading: false}) : document);

            this.setState({
                documents: updatedDocuments
            });
        }
    };

    // document id is the viewer this request came from
    onBillSearch = async (documentId) => {
        this.onOpenModal(documentId);
    };

    getBills = async () => {
        if (this.state.value) {
            this.setState({areBillsFetching: true});

            const result = await documentService.getDocuments(this.state.value, this.state.currentPage);

            this.setState({areBillsFetching: false});
            this.setState({
                // I had to rename it, 'cause otherwise I will have naming collisions
                bills: result.documents || [],
                billsCount: result.documentsCount || 0
            })
        }
    };

    handleChangeSearch = (event) => {
        this.setState({value: event.target.value});
    };

    handleSearchKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (this.state.value) {
                this.getBills();
            }
        }
    };

    searchClick = async () => {
        await this.setState({currentPage: 1});
        this.getBills();
    };

    onPageChange = async (page) => {
        await this.setState({currentPage: page});
        this.getBills();
    };

    onOpenModal = async (documentId) => {
        // clear the data that was entered before opening the modal
        await this.clearBillsData();

        this.setState({
            modalOpen: true,
            documentId
        });
    };

    clearBillsData = () => {
        this.setState({
            currentPage: 0,
            billsCount: 0,
            value: '',
            areBillsFetching: false,
            documentId: null,
            bills: []
        });
    };

    onCloseModal = () => {
        this.setState({
            modalOpen: false
        });
    };

    onlBillClicked = async (packageId) => {
        const {documentId, documents} = this.state;

        const response = await documentService.getDocument(packageId);

        const updatedDocuments = documents.map(document =>
            document.id === documentId
                ? Object.assign({...document}, {
                    mode: 'view-bill',
                    name: this.getDocumentNameByIdAndType(documentId, 'bill'),
                    bill: {...response.document, documentLink: response.link}
                })
                : document
        );

        this.setState({
            documents: updatedDocuments
        });

        this.onCloseModal();
    };

    onChangeMode = (documentId, mode) => {
        const {documents} = this.state;
        const updatedDocuments = documents.map(document => document.id === documentId ? Object.assign({...document}, {mode}) : document);
        this.setState({documents: updatedDocuments});
    };

    render() {
        const {
            isFetching,
            usCodeData,
            title,
            year,
            section,
            isConnect,
            isView,
            connectedCfrTitles,
            connectedCfrParts,
            documents,
            isRunningInsideMicrosoftTeams
        } = this.state;

        const currentRoute = this.props.location.pathname;
        // const cfrTitles = CFRService.getTitles();
        var cfrTitles = []
        if (cfrTitles.length == 0){
            cfrTitles = this.state.titleListCFR
        }
        return (
            <div>
                {!isRunningInsideMicrosoftTeams && <Header currentRoute={currentRoute} shouldLogoBeLight={false}/>}
                <div className="blue-gradient navigation-section us-code-viewer-page">
                    {/* TODO:: move this part to separate common component */}
                    <div className="back-button" onClick={this.goBack}>
                        <span className="material-icons">keyboard_backspace</span>
                        <span className="back-text">Back</span>
                    </div>
                    <div className="viewer-section-buttons">
                        <button
                            className={`viewer-section-button left-rounded ${isView ? 'active' : ''}`}
                            onClick={this.goView}
                        >View
                        </button>
                        <button
                            className={`viewer-section-button right-rounded ${isConnect ? 'active' : ''}`}
                            onClick={this.goConnect}
                        >Connect
                        </button>
                    </div>
                </div>
                {
                    isView && (
                        <div>
                            {
                                isFetching && (
                                    <div>
                                        <PlaceholderShimmer/>
                                        <div className="lds-dual-ring-container">
                                            <div className="lds-dual-ring"></div>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                (!isFetching && usCodeData && usCodeData.isPresent) &&
                                <>
                                    <div className="us-code-container view-page">
                                        <div className="description-container">
                                            <div>
                                        <span
                                            className="description">United States Code, {this.getSelectedTitleLabel(title)}</span>
                                            </div>
                                            <div>
                                                <span className="chips">{year} Edition</span>
                                                <span className="chips">Section {section}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <DownloadFile
                                                icon="default"
                                                type={'uscode'}
                                                title={title}
                                                year={year}
                                                section={section}
                                            />
                                        </div>
                                    </div>
                                    <PDFViewer file={usCodeData.link}/>
                                </>
                            }
                            {
                                !isFetching && usCodeData && !usCodeData.isPresent &&
                                <EmptyResults text={'Check your data, because us code is not found.'}/>
                            }
                        </div>
                    )
                }
                {
                    isConnect && (
                        <div className="connected-docs-container">
                            <DocumentViewer
                                document={documents[0]}
                                cfrTitles={cfrTitles}
                                onDownload={this.onDownload}
                            />
                            <DocumentViewer
                                document={documents[1]}
                                cfrTitles={cfrTitles}
                                onCfrSearch={this.onCfrSearch}
                                onCfrConnect={this.onCfrConnect}
                                onDownload={this.onDownload}
                                onBillSearch={this.onBillSearch}
                                onChangeMode={this.onChangeMode}
                                connectedCfrParts={connectedCfrParts}
                                connectedCfrTitles={connectedCfrTitles}
                            />
                            <DocumentViewer
                                document={documents[2]}
                                cfrTitles={cfrTitles}
                                onCfrSearch={this.onCfrSearch}
                                onCfrConnect={this.onCfrConnect}
                                onDownload={this.onDownload}
                                onBillSearch={this.onBillSearch}
                                onChangeMode={this.onChangeMode}
                                connectedCfrParts={connectedCfrParts}
                                connectedCfrTitles={connectedCfrTitles}
                            />
                        </div>
                    )
                }
                <Modal
                    open={this.state.modalOpen}
                    onClose={this.onCloseModal}
                    center
                    showCloseIcon={false}
                    classNames={{
                        overlay: 'custom-modal-overlay',
                        modal: 'custom-modal bills-search',
                    }}>
                    <div>
                        <div className="bill-search-title-container">
                            <span>Search for Bills</span>
                            <img
                                alt="close icon"
                                src="/static/media/cross.svg"
                                onClick={this.onCloseModal}
                            />
                        </div>
                        <div className="search-container">
                            <div className="search-section blue-gradient">
                                <div className="search-input">
                                    <input
                                        type="text"
                                        placeholder="Search for U.S. Bills and Resolutions"
                                        autoComplete="off"
                                        name="value"
                                        className="text-field text-field-input"
                                        onChange={this.handleChangeSearch}
                                        onKeyDown={this.handleSearchKeyDown}
                                        value={this.state.value}
                                    />
                                </div>
                                <div className="search-button">
                                    <button className="button" onClick={this.searchClick}><span
                                        className="material-icons">search</span>Search
                                    </button>
                                </div>
                            </div>
                            {this.state.billsCount > 0 && !this.state.areBillsFetching &&
                            <div className='documents-count-title'>{this.state.billsCount} Documents Found</div>
                            }
                            {this.state.areBillsFetching &&
                            <>
                                <PlaceholderShimmer/>
                                <PlaceholderShimmer/>
                                <PlaceholderShimmer/>
                                <PlaceholderShimmer/>
                                <PlaceholderShimmer/>
                            </>
                            }

                            {!this.state.areBillsFetching && this.state.bills.length === 0 &&
                            <EmptyResults text={'Enter a bill number, version, or keywords of its description.'}/>
                            }
                            {this.state.bills && this.state.bills.length > 0 && !this.state.areBillsFetching &&
                            <div>
                                <DocumentList
                                    onlBillClicked={this.onlBillClicked}
                                    isUsedForCFRAndUsCodes={true}
                                    documents={this.state.bills}
                                    searchQuery={this.state.value}
                                    isRunningInsideMicrosoftTeams={this.state.isRunningInsideMicrosoftTeams}
                                />
                                <div className="pagination-section">
                                    <Pagination
                                        className="rc-pagination"
                                        current={this.state.currentPage}
                                        total={this.state.billsCount}
                                        locale="en_EN"
                                        onChange={(page) => this.onPageChange(page)}/>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </Modal>
                {!isRunningInsideMicrosoftTeams && <Footer/>}
            </div>
        )
    }
}

export default USCodeViewPage;
