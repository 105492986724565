import {authHeader, handleResponse} from '../_helpers';

// // TODO: this data will be probably moved to the BE
const TITLES =[];
const YEARS = [];
// const YEARS = [
//     {
//         value: 2020,
//         label: '2020'
//     },
//     {
//         value: 2019,
//         label: '2019'
//     },
//     {
//         value: 2018,
//         label: '2018'
//     },
//     {
//         value: 2017,
//         label: '2017'
//     },
//     {
//         value: 2016,
//         label: '2016'
//     },
//     {
//         value: 2015,
//         label: '2015'
//     },
//     {
//         value: 2014,
//         label: '2014'
//     },
//     {
//         value: 2013,
//         label: '2013'
//     },
//     {
//         value: 2012,
//         label: '2012'
//     },
//     {
//         value: 2011,
//         label: '2011'
//     },
//     {
//         value: 2010,
//         label: '2010'
//     },
//     {
//         value: 2009,
//         label: '2009'
//     },
//     {
//         value: 2008,
//         label: '2008'
//     },
//     {
//         value: 2007,
//         label: '2007'
//     },
//     {
//         value: 2006,
//         label: '2006'
//     },
//     {
//         value: 2005,
//         label: '2005'
//     },
//     {
//         value: 2004,
//         label: '2004'
//     },
//     {
//         value: 2003,
//         label: '2003'
//     },
//     {
//         value: 2002,
//         label: '2002'
//     },
//     {
//         value: 2001,
//         label: '2001'
//     },
//     {
//         value: 2000,
//         label: '2000'
//     },
//     {
//         value: 1999,
//         label: '1999'
//     },
//     {
//         value: 1998,
//         label: '1998'
//     },
//     {
//         value: 1997,
//         label: '1997'
//     },
//     {
//         value: 1996,
//         label: '1996'
//     },
//     {
//         value: 1995,
//         label: '1995'
//     },
//     {
//         value: 1994,
//         label: '1994'
//     }
// ];

// const TITLES = [
//     {value: 1, label: 'Title 1 - General Provisions'},
//     {value: 2, label: 'Title 2 - Grants and Agreements'},
//     {value: 3, label: 'Title 3 - The President'},
//     {value: 4, label: 'Title 4 - Accounts'},
//     {value: 5, label: 'Title 5 - Administrative Personnel'},
//     {value: 6, label: 'Title 6 - Domestic Security'},
//     {value: 7, label: 'Title 7 - Agriculture'},
//     {value: 8, label: 'Title 8 - Aliens And Nationality'},
//     {value: 9, label: 'Title 9 - Animals and Animal Products'},
//     {value: 10, label: 'Title 10 - Energy'},
//     {value: 11, label: 'Title 11 - Federal Elections'},
//     {value: 12, label: 'Title 12 - Banks and Banking'},
//     {value: 13, label: 'Title 13 - Business Credit and Assistance'},
//     {value: 14, label: 'Title 14 - Aeronautics and Space'},
//     {value: 15, label: 'Title 15 - Commerce and Foreign Trade'},
//     {value: 16, label: 'Title 16 - Commercial Practices'},
//     {value: 17, label: 'Title 17 - Commodity and Securities Exchanges'},
//     {value: 18, label: 'Title 18 - Conservation of Power and Water Resources'},
//     {value: 19, label: 'Title 19 - Customs Duties'},
//     {value: 20, label: 'Title 20 - Employees\' Benefits'},
//     {value: 21, label: 'Title 21 - Food And Drugs'},
//     {value: 22, label: 'Title 22 - Foreign Relations'},
//     {value: 23, label: 'Title 23 - Highways'},
//     {value: 24, label: 'Title 24 - Housing and Urban Development'},
//     {value: 25, label: 'Title 25 - Indians'},
//     {value: 26, label: 'Title 26 - Internal Revenue'},
//     {value: 27, label: 'Title 27 - Alcohol, Tobacco Products and Firearms'},
//     {value: 28, label: 'Title 28 - Judicial Administration'},
//     {value: 29, label: 'Title 29 - Labor'},
//     {value: 30, label: 'Title 30 - Mineral Resources'},
//     {value: 31, label: 'Title 31 - Money and Finance: Treasury'},
//     {value: 32, label: 'Title 32 - National Defense'},
//     {value: 33, label: 'Title 33 - Navigation and Navigable Waters'},
//     {value: 34, label: 'Title 34 - Education'},
//     {value: 35, label: 'Title 35 - Reserved'},
//     {value: 36, label: 'Title 36 - Parks, Forests, and Public Property'},
//     {value: 37, label: 'Title 37 - Patents, Trademarks, and Copyrights'},
//     {value: 38, label: 'Title 38 - Pensions, Bonuses, and Veterans\' Relief'},
//     {value: 39, label: 'Title 39 - Postal Service'},
//     {value: 40, label: 'Title 40 - Protection of Environment'},
//     {value: 41, label: 'Title 41 - Public Contracts and Property Management'},
//     {value: 42, label: 'Title 42 - Public Health'},
//     {value: 43, label: 'Title 43 - Public Lands: Interior'},
//     {value: 44, label: 'Title 44 - Emergency Management and Assistance'},
//     {value: 45, label: 'Title 45 - Public Welfare'},
//     {value: 46, label: 'Title 46 - Shipping'},
//     {value: 47, label: 'Title 47 - Telecommunications'},
//     {value: 48, label: 'Title 48 - Federal Acquisition Regulations System'},
//     {value: 49, label: 'Title 49 - Transportation'},
//     {value: 50, label: 'Title 50 - Wildlife and Fisheries'}
// ];

export const CFRService = {
    getCFR(title, year, section, part) {
        const requestOptions = {method: 'GET', headers: authHeader()};

        let url = null;

        if(section) {
            url = `${process.env.REACT_APP_API_URL}/cfr?title=${title}&year=${year}&section=${section}&part=${part}`
        } else {
            url = `${process.env.REACT_APP_API_URL}/cfr?title=${title}&year=${year}&part=${part}`
        }

        return fetch(url, requestOptions).then(handleResponse);
    },

    downloadCFR(title, year, section, part) {
        const requestOptions = {method: 'GET', headers: authHeader()};

        let url = null;

        if(section) {
            url = `${process.env.REACT_APP_API_URL}/cfr/download?title=${title}&year=${year}&section=${section}&part=${part}`
        } else {
            url = `${process.env.REACT_APP_API_URL}/cfr/download?title=${title}&year=${year}&part=${part}`
        }

        return fetch(url, requestOptions)
    },

    getYears() {
        return YEARS;
    },

    getTitles() {
        return TITLES;
    }
};
